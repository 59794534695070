
import { FC } from 'react';

import Loader from 'components/shared/loader/Loader';
import { bemCn } from 'utils/bem-cn';
import { useGetBasketQuery } from 'store/basket/basketApi';
import { selectIsAuthenticated } from 'store/auth/selectors';
import { useAppSelector } from 'hooks';

import BetsGroup from '../components/EventCardBetsGroup/EventCardBetsGroup';
import Board from '../components/EventCardBoard/EventCardBoardSimple';
import Stats from '../components/EventCardStats/EventCardStats';
import Info from '../components/EventCardInfo/EventCardInfo';
import Tabs from '../components/EvetnCardTabs/EvetnCardTabs';
import ScoreTable from '../components/EventCardScoreTable/EventCardScoreTable';

import type { Bet, FullEventItem, Group, SubGame } from 'types/line-data';
import './EventCardFullDesktop.scss';

type Props = {
  tabs: SubGame[];
  event: FullEventItem;
  betsGroups: Group[];
  activeTab: number;
  isBetsLoading: boolean;
  isBetsClosed: boolean;
  onTabChange: (id: number) => void;
  onToggleBetsClose: () => void;
  onBetClick: (bet: Bet, betGroup: string) => void;
  isLive: boolean;
}


const EventCardFullDesktop = (props: Props) => {
  const {
    tabs,
    event,
    betsGroups,
    activeTab,
    isBetsLoading,
    isBetsClosed,
    onTabChange,
    onToggleBetsClose,
    onBetClick,
    isLive
  } = props;
  const isAuth = useAppSelector(selectIsAuthenticated);
  const betsGroupCount = betsGroups.length;
  const betsGroupsLeft = betsGroups.slice(0, (betsGroupCount / 2) + 1);
  const betsGroupsRight = betsGroups.slice((betsGroupCount / 2) + 1);
  const { basketItem } = useGetBasketQuery(undefined, {
    skip: !isAuth,
    selectFromResult: ({ data }) => ({
      basketItem: data?.itemsByGameId[event.id]?.basketId
    })
  });

  const b = bemCn('event-card-full-desktop');

  return (
    <div className={b()}>
      <div className={b('wrapper')}>
        <div className={b('head')}>
          <Info className={b('info')}
            event={event}
          />
          <Board className={b('board')}
            event={event}
            isLive={isLive}
          />
          {isLive && (
            <ScoreTable
              scorePeriods={event.scorePeriod}
              team1={event.team1}
              team2={event.team2}
              scoreFull={event.scoreFull}
              periodName={event.periodName.split(' ')[1]}
            />
          )}
          <Stats className={b('stats')}
            stats={event.statList}
          />
        </div>
        <div className={b('bets')}>
          <Tabs className={b('tabs')}
            tabs={tabs}
            activeTab={activeTab}
            onTabChange={onTabChange}
            onToggleClose={onToggleBetsClose}
          />
          <div className={b('bets-list')}>
            <div className={b('bets-column')}>
              {isBetsLoading ? <Loader /> : betsGroupsLeft.map((group) => (
                <BetsGroup
                  key={`${group.name}-${group.bets.length}`}
                  className={b('bets-group')}
                  isAllClosed={isBetsClosed}
                  group={group}
                  basketItemBetId={basketItem}
                  onBetClick={onBetClick}
                />
              ))}
            </div>
            <div className={b('bets-column')}>
              {isBetsLoading ? <Loader /> : betsGroupsRight.map((group) => (
                <BetsGroup
                  key={`${group.name}-${group.bets.length}`}
                  className={b('bets-group')}
                  isAllClosed={isBetsClosed}
                  group={group}
                  basketItemBetId={basketItem}
                  onBetClick={onBetClick}
                />
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EventCardFullDesktop;
