import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import { useAppDispatch, useAppSelector } from 'hooks';
import { selectBasketActiveModal, selectBasketErrorMessage } from 'store/basket/selectors';
import { changeActiveBasketModal } from 'store/basket/basketSlice';
import ResultModal from 'components/shared/ResultModal/ResultModal';
import BasketModal from 'components/basket/BasketModal/BasketModal';
import Button from 'components/shared/buttons/Button/Button';
import TelegramModal from 'components/shared/TelegramModal/TelegramModal';
import { selectAppModal } from 'store/app/selectors';
import { changeActiveAppModal } from 'store/app/appSlice';
import { saveTelegramShown } from 'utils/localStorage';

const AppModals = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const activeBasketModal = useAppSelector(selectBasketActiveModal);
  const errorMeassage = useAppSelector(selectBasketErrorMessage);
  const activeAppModal = useAppSelector(selectAppModal);

  return (
    <>
      <BasketModal isOpen={activeBasketModal === 'basket'} onClose={() => dispatch(changeActiveBasketModal(null))} />
      <ResultModal
        isOpen={activeBasketModal === 'success'}
        onClose={() => dispatch(changeActiveBasketModal(null))}
        status="success"
        title={t('result-modal.basket.success.title', 'Ставка успешно поставлена')}
      >
        <Link to="bet-history">
          <Button onClick={() => dispatch(changeActiveBasketModal(null))}>
            {t('cart.bet-history', 'История ставок')}
          </Button>
        </Link>
      </ResultModal>
      <ResultModal
        isOpen={activeBasketModal === 'error' || activeBasketModal === 'place-error'}
        onClose={() => dispatch(changeActiveBasketModal(null))}
        status="error"
        // title={t('result-modal.basket.error.title', 'Ошибка постановки ставки')}
        title={errorMeassage ?? t('result-modal.basket.error.title', 'Ошибка постановки ставки')}
      />

      <TelegramModal
        onClose={() => {
          dispatch(changeActiveAppModal(null));
          saveTelegramShown(true);
        }}
        isOpen={activeAppModal === 'telegram-subscribe'}
      />
    </>
  );
};

export default AppModals;
