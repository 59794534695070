import { useEffect, useState } from 'react';

import { bemCn } from 'utils/bem-cn';

import type { Bet } from 'types/line-data';

import './EventCardBetsItem.scss';


type Props = {
  bet: Bet;
  onClick: () => void;
  isActive: boolean;
  className?: string;
  isBlocked?: boolean;
}

const EventCardBetsItem = ({ bet, isActive, onClick, className, isBlocked }: Props) => {
  const [rate, setRate] = useState(bet.rate);
  const [diff, setDiff] = useState('');

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      if (diff !== '') {
        setDiff('');
      }
    }, 1500);

    return () => clearTimeout(timeoutId);
  }, [diff]);

  useEffect(() => {
    setRate((prevRate) => {
      if (bet.rate > prevRate) {
        setDiff('increase');
      } else if (bet.rate < prevRate) {
        setDiff('decrease');
      }

      return bet.rate;
    });
  }, [bet.rate]);


  const b = bemCn('event-card-bets-item');
  const betItemClassName = b(
    {
      active: isActive,
      [`${diff}`]: Boolean(diff),
      'blocked': isBlocked,
    },
    className
  );

  return (
    <button className={betItemClassName}
      onClick={onClick}
      type='button'
      disabled={isBlocked || isActive}
    >
      <span className={b('caption')}>
        {bet.caption}
      </span>
      <span className={b('rate')}>
        {rate}
      </span>
    </button>
  );
};

export default EventCardBetsItem;
