
export type Blacklist = {
  sports: string[];
  tournaments: string[];
  outcomes: string[];
  subgames: string[];
  bySport: {
    [sportId: number]: {
      sportId: number;
      sportName: string;
      subgames: string[];
      tournaments?: string[];
    };
  };
}

export const blacklist: Blacklist = {
  sports: [
    // 'Darts',
  ],
  tournaments: [
    // 'Alternative matches',
    // 'Альтернативные матчи',
    // 'ACL Indoor',
    // 'Россия. MNHL',
    // 'RHL',
    // 'ATP World Cyber Tour',
    // 'Table Basketball League',
    // '2K23. NBA',
    // 'Сетка Кап',
    // 'Ace Belarus Cup',
    // 'NBA 2K22. Cyber Euro League',
    // 'NBA 2K23. Cyber League',
    // '2K24. NBA Cyber Championship',
  ],
  outcomes: [
    'Азиатский',
    'Азиатская',
    'Азійський',
    'Азіатська',
    'Азіатський',
    'Asian',
    'Тотал на раунд',
    'Тотал фрагов',
    'Тотал ударов',
    'Тотал смертей',
    'Most Kills',
    'Most Deaths',
  ],
  subgames: [
    // 'Альтернативные исходы',
    // 'Экспресс исходы',
    // 'Что раньше',
    // 'Видеопросмотры',
    // 'Відеоперегляд',
    // 'VAR Checks',
    // 'Medical Team',
    // 'Мед. бригада',
  ],
  bySport: {
    // 1: {
    //   sportId: 1,
    //   sportName: 'Футбол',
    //   subgames: [
    //     // 'Карточки',
    //     // 'Картки',
    //     // 'Cards',
    //     // 'Vərəqələr',
    //     // 'Kartochkala',

    //     // 'Удары в створ ворот',
    //     // 'Удари у площину воріт',
    //     // 'Shots On Target',
    //     // 'Qapı çərçivəsinə edilən zərbələr',
    //     // 'Darvoza tomon aniq zarbalar',

    //     // 'Удары в створ ворот,1-й Тайм',
    //     // 'Удари у площину воріт,1-й Тайм',
    //     // 'Shots On Target,1 Half',
    //     // 'Qapı çərçivəsinə edilən zərbələr,1-ci Hissə ',
    //     // 'Qapı çərçivəsinə edilən zərbələr,1-ci Hissə',
    //     // 'Darvoza tomon aniq zarbalar,1 Taymi',

    //     // 'Удары в створ ворот,2-й Тайм',
    //     // 'Удари у площину воріт,2-й Тайм',
    //     // 'Shots On Target,2 Half',
    //     // 'Qapı çərçivəsinə edilən zərbələr,2-ci Hissə ',
    //     // 'Qapı çərçivəsinə edilən zərbələr,2-ci Hissə',
    //     // 'Darvoza tomon aniq zarbalar,2 Taymi',

    //     // 'Офсайды',
    //     // 'Офсайди',
    //     // 'Offsides',
    //     // 'Ofsaytlar',
    //     // 'Ofsaydlar',

    //     // 'Офсайды,1-й Тайм',
    //     // 'Офсайди,1-й Тайм',
    //     // 'Offsides,1 Half',
    //     // 'Ofsaytlar,1-ci Hissə ',
    //     // 'Ofsaytlar,1-ci Hissə',
    //     // 'Ofsaydlar,1 Taymi',

    //     // 'Офсайды,2-й Тайм',
    //     // 'Офсайди,2-й Тайм',
    //     // 'Offsides,2 Half',
    //     // 'Ofsaytlar,2-ci Hissə ',
    //     // 'Ofsaytlar,2-ci Hissə',
    //     // 'Ofsaydlar,2 Taymi',

    //     // 'Фолы',
    //     // 'Фоли',
    //     // 'Fouls',
    //     // 'Follar',
    //     // 'Follar',

    //     // 'Фолы,1-й Тайм',
    //     // 'Фоли,1-й Тайм',
    //     // 'Fouls,1 Half',
    //     // 'Follar,1-ci Hissə ',
    //     // 'Follar,1-ci Hissə',
    //     // 'Follar,1 Taymi',

    //     // 'Фолы,2-й Тайм',
    //     // 'Фоли,2-й Тайм',
    //     // 'Fouls,2 Half',
    //     // 'Follar,2-ci Hissə ',
    //     // 'Follar,2-ci Hissə',
    //     // 'Follar,2 Taymi',

    //     // 'Карточки. статистика',
    //     // 'Картки. статистика',
    //     // 'Cards. Stats',
    //     // 'Vərəqə. statistika',
    //     // 'Kartochkalar. Statistika',

    //     // 'Карточки. статистика,1-й Тайм',
    //     // 'Картки. статистика,1-й Тайм',
    //     // 'Cards. Stats,1 Half',
    //     // 'Vərəqə. statistika,1-ci Hissə ',
    //     // 'Vərəqə. statistika,1-ci Hissə',
    //     // 'Kartochkalar. Statistika,1 Taymi',

    //     // 'Карточки. статистика,2-й Тайм',
    //     // 'Картки. статистика,2-й Тайм',
    //     // 'Cards. Stats,2 Half',
    //     // 'Vərəqə. statistika,2-ci Hissə ',
    //     // 'Vərəqə. statistika,2-ci Hissə',
    //     // 'Kartochkalar. Statistika,2 Taymi',

    //     // 'Отборы',
    //     // 'Відбори',
    //     // 'Tackles',
    //     // 'Отборы',
    //     // 'To\'pni muvaffaqiyatli olib qo\'yish',

    //     // 'Видеопросмотры,1-й Тайм',
    //     // 'Відеоперегляд,1-й Тайм',
    //     // 'VAR Checks,1 Half',
    //     // 'Video baxışları,1-ci Hissə ',
    //     // 'Video baxışları,1-ci Hissə',
    //     // 'Video ko\'rib chiqish,1 Taymi',

    //     // 'Видеопросмотры,2-й Тайм',
    //     // 'Відеоперегляд,2-й Тайм',
    //     // 'VAR Checks,2 Half',
    //     // 'Video baxışları,2-ci Hissə ',
    //     // 'Video baxışları,2-ci Hissə',
    //     // 'Video ko\'rib chiqish,2 Taymi',

    //     // 'Удары по воротам',
    //     // 'Удари по воротам',
    //     // 'Shots Towards The Goal',
    //     // 'Qapıya zərbə',
    //     // 'Darvozaga zarbalar',

    //     // 'Удары по воротам,1-й Тайм',
    //     // 'Удари по воротам,1-й Тайм',
    //     // 'Shots Towards The Goal,1 Half',
    //     // 'Qapıya zərbə,1-ci Hissə ',
    //     // 'Qapıya zərbə,1-ci Hissə',
    //     // 'Darvozaga zarbalar,1 Taymi',

    //     // 'Удары по воротам,2-й Тайм',
    //     // 'Удари по воротам,2-й Тайм',
    //     // 'Shots Towards The Goal,2 Half',
    //     // 'Qapıya zərbə,2-ci Hissə ',
    //     // 'Qapıya zərbə,2-ci Hissə',
    //     // 'Darvozaga zarbalar,2 Taymi',

    //     // 'Удары от ворот',
    //     // 'Удари від воріт',
    //     // 'Goal kicks',
    //     // 'Qapıdan zərbələr',
    //     // 'Darvozadan zarbalar',

    //     // 'Удары от ворот,1-й Тайм',
    //     // 'Удари від воріт,1-й Тайм',
    //     // 'Goal kicks,1 Half',
    //     // 'Qapıdan zərbələr,1-ci Hissə ',
    //     // 'Darvozadan zarbalar,1 Taymi',

    //     // 'Удары от ворот,2-й Тайм',
    //     // 'Удари від воріт,2-й Тайм',
    //     // 'Goal kicks,2 Half',
    //     // 'Qapıdan zərbələr,2-ci Hissə ',
    //     // 'Qapıdan zərbələr,2-ci Hissə',
    //     // 'Darvozadan zarbalar,2 Taymi',

    //     // '1-минутные события',
    //     // '1-хвилинні події',
    //     // '1-minute events',
    //     // '1 dəqiqəlik hadisələr',
    //     // '1 daqiqali voqealar',

    //     // 'Сейвы',
    //     // 'Сейви',
    //     // 'Saves',
    //     // 'Seyvlər',
    //     // 'Seyvlar',

    //     // 'Вброс аутов',
    //     // 'Вкидання аутів',
    //     // 'Throw-ins',
    //     // 'Aut atışı',
    //     // 'Autlardan to\'p tashlash',

    //     // 'Вброс аутов,1-й Тайм',
    //     // 'Вкидання аутів,1-й Тайм',
    //     // 'Throw-ins,1 Half',
    //     // 'Aut atışı,1-ci Hissə ',
    //     // 'Aut atışı,1-ci Hissə',
    //     // 'Autlardan to\'p tashlash,1 Taymi',

    //     // 'Вброс аутов,2-й Тайм',
    //     // 'Вкидання аутів,2-й Тайм',
    //     // 'Throw-ins,2 Half',
    //     // 'Aut atışı,2-ci Hissə ',
    //     // 'Aut atışı,2-ci Hissə',
    //     // 'Autlardan to\'p tashlash,2 Taymi',

    //     // 'Статистика игроков',
    //     // 'Статистика гравців',
    //     // 'Players\' stats',

    //     // 'Статистика игроков,1-й Тайм',
    //     // 'Статистика гравців,1-й Тайм',
    //     // 'Players\' stats,1 Half',

    //     // 'Статистика игроков,2-й Тайм',
    //     // 'Статистика гравців,2-й Тайм',
    //     // 'Players\' stats,2 Half',

    //     // 'Что раньше',
    //     // 'Що раніше',
    //     // 'What happened before',

    //   ],
    //   tournaments: []
    // },
    // 2: {
    //   sportId: 2,
    //   sportName: 'Хоккей',
    //   subgames: []
    // },
    // 3: {
    //   sportId: 3,
    //   sportName: 'Баскетбол',
    //   subgames: []
    // },
  }
};
