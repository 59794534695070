import React from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import Button from 'components/shared/buttons/Button/Button';
import Coefficient from 'components/line/components/Coefficient/Coefficient';
import FavoriteBtn from 'components/shared/FavoriteBtn/FavoriteBtn';
import { monthList, toLocaleDateString, toLocaleTimeString } from 'utils/time';
import SvgIcon from 'components/shared/SvgIcon/SvgIcon';
import { getCoefTitle } from 'utils/line';
import { bemCn } from 'utils/bem-cn';
import { useAddToBasketMutation } from 'store/basket/basketApi';
import { selectIsAuthenticated } from 'store/auth/selectors';
import { changeActiveModal } from 'store/auth/authSlice';
import { useAppDispatch, useAppSelector } from 'hooks';
import { useToggleFavoriteEvent } from 'hooks/useToggleFavoriteEvent';
import { AddFavoriteMatchBody as EventInfo } from 'types/user-data';

import type { Bet, EventItem, LineType } from 'types/line-data';
import './DesktopWidgetItem.scss';

const EmptyCoeffs = () => (
  <>
    <Coefficient title="-" value="-" onClick={() => undefined} />
    <Coefficient title="-" value="-" onClick={() => undefined} />
    <Coefficient title="-"
      value="-" onClick={() => undefined}
    />
  </>
);

type Props = {
  event: EventItem;
  lineType: LineType;
  basketItem?: string | null;
  className?: string;
  isFavorite: boolean;
  favoriteId: string;
};

const b = bemCn('desktop-widget-item');
const DesktopWidgetItem = ({ event, lineType, basketItem, className, isFavorite, favoriteId }: Props) => {
  const { i18n } = useTranslation();
  const date = toLocaleDateString(new Date(event.eventDate));
  const time = toLocaleTimeString(new Date(event.eventDate));
  const [addToBasket] = useAddToBasketMutation();
  const isAuth = useAppSelector(selectIsAuthenticated);
  const { toggleFavoriteMatch, isLoading } = useToggleFavoriteEvent();
  const dispatch = useAppDispatch();

  const dateFormatText = `${date.split('.')[0]} ${monthList[Number(date.split('.')[1]) - 1]}`;
  const dateFormatDigit = `${date.split('.')[0]}.${date.split('.')[1]}`;
  const timeWithoutSeconds = `${time.split(':')[0]}:${time.split(':')[1]}`;
  const scorePeriodFormatSeparator = event.scorePeriod.split(';').join('-');

  const eventInfo: EventInfo = {
    'matchId': event.id,
    'team1': event.team1,
    'team2': event.team2,
    'sportName': event.sportName,
    'sportId': event.sportId,
    'tournamentName': event.tournament,
    'tournamentId': event.tournamentId,
    'countryId': event.countryId,
    'countryName': event.countryName,
    'dataType': event.dataType,
  };

  const handleFavoriteClick = () => {
    if (!isLoading) {
      toggleFavoriteMatch({ eventInfo, isFavorite, favoriteId });
    }
  };

  const handleAddBetToBasket = (bet: Bet) => {
    if (isAuth) {
      addToBasket({
        dataType: lineType,
        basketId: bet.betId,
        lang: i18n.resolvedLanguage,
      });
    } else {
      dispatch(changeActiveModal('sign-in'));
    }
  };

  const buttonWithCoef = event.group.name === '1x2'
    ? event.group.bets.map((bet) => (
      <Coefficient
        key={bet.betId}
        title={getCoefTitle(bet.caption, event.team1, event.team2)}
        value={bet.rate}
        onClick={() => handleAddBetToBasket(bet)}
        isActive={bet.betId === basketItem}
        isBlocked={bet.blocked}
      />
    ))
    : <EmptyCoeffs />;

  const allBetsCount = event.allGroups.reduce((summ, item) => summ + item.bets.length, 0);

  return (
    <div className={b(null, className)}>
      <FavoriteBtn
        className={b('favorite-btn')}
        onClick={handleFavoriteClick}
        active={isFavorite}
      />
      <Link to={`/fullevent/${event.id}/${lineType}/${event.countryName}/${event.countryId}`} className={b('link')}>
        <p className={b('date')}>
          {/* <span>{dateFormatText}</span> */}
		  <span>{dateFormatDigit}</span>
          <span className={b('date-time')}>{timeWithoutSeconds}</span>
        </p>
        <div className={b('info')}>
          <p className={b('teams')}>
            <span className={b('team-name')}>{event.team1}</span>
            <span className={b('team-name')}>{event.team2}</span>
          </p>
          <div className={b('stats')}>
            <div className={b('score')}>
              <p className={b('score-total')}>{event.scoreFull}</p>
              <p>{scorePeriodFormatSeparator}</p>
            </div>
            <p className={b('timer')}>
              <SvgIcon className={b('timer-icon')}
                name='time-machine' width={13} height={13}
              />
              {Math.floor(event.timer / 60)}′
            </p>
          </div>
        </div>
      </Link>
      <div className={b('bets')}>
        <Link to={`/fullevent/${event.id}/${lineType}`}>
          <Button variant="secondary">
            {allBetsCount} +
          </Button>
        </Link>
        {buttonWithCoef}
      </div>
    </div>
  );
};

export default DesktopWidgetItem;
