import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import Tabs, { TabType } from 'components/shared/Tabs/Tabs';
import { bemCn } from 'utils/bem-cn';
import { useGetCurrenciesQuery } from 'store/app/appApi';
import { useGetUserCountryQuery } from 'store/user/userApi';
import i18n from 'i18n/i18n';
import AppModal from 'components/shared/AppModal/AppModal';

import EmailTab from './components/EmailTab/EmailTab';
import TelegramTab from './components/TelegramTab/TelegramTab';
import PhoneTab from './components/PhoneTab/PhoneTab';
import './SignUp.scss';

type Props = {
  isOpen: boolean;
  onClose: () => void;
};

const TabsIds = {
  email: 1,
  telegram: 2,
  oneClick: 3,
  phone: 4,
} as const;

const tabs: TabType[] = [
  {
    title: 'E-mail',
    id: TabsIds.email,
    disabled: false,
  },
  {
    title: 'Telegram',
    id: TabsIds.telegram,
    disabled: true,
  },
  // {
  //   title: 'В 1 клик',
  //   id: TabsIds.oneClick,
  //   disabled: true,
  // },
  {
    title: i18n.t('auth.modal.phone', 'Телефон'),
    id: TabsIds.phone,
    disabled: true,
  },
];

const SignUp = (props: Props) => {
  const { isOpen, onClose } = props;
  const { t } = useTranslation();
  const [activeTabId, setActiveTabId] = useState<number>(1);
  const {
    data: availableCurrencies = [],
    isLoading: isCurrenciesLoading
  } = useGetCurrenciesQuery();

  const { data: currencyData } = useGetUserCountryQuery();
  const userCurrency = currencyData?.currency && availableCurrencies.includes(currencyData?.currency)
    ? currencyData?.currency
    : null;

  const b = bemCn('registration-modal');
  return (
    <AppModal className={b()} isOpen={isOpen} onClose={onClose}>
      <div className={b('header')}>
        <h2 className={b('header-title')}>
          {t('reg.modal.title', 'Регистрация')}
        </h2>
        <p className={b('header-description')}>
          {t('reg.modal.description', 'Выберите удобный способ регистрации')}
        </p>
      </div>
      <Tabs tabs={tabs} activeTabId={activeTabId} setActiveTabId={setActiveTabId}>
        <EmailTab
          id={TabsIds.email}
          userCurrency={userCurrency}
          availableCurrencies={availableCurrencies}
          isCurrenciesLoading={isCurrenciesLoading}
        />
        <TelegramTab
          id={TabsIds.telegram}
          userCurrency={userCurrency}
          availableCurrencies={availableCurrencies}
          isCurrenciesLoading={isCurrenciesLoading}
        />
        <PhoneTab
          id={TabsIds.phone}
          userCurrency={userCurrency}
          availableCurrencies={availableCurrencies}
          isCurrenciesLoading={isCurrenciesLoading}
        />
      </Tabs>
    </AppModal>
  );
};

export default SignUp;
