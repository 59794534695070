import React, { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import Loader from 'components/shared/loader/Loader';
import { useAppDispatch, useAppSelector } from 'hooks';
import { openChangePasswordModal } from 'store/auth/authSlice';
import { selectIsAuthenticated } from 'store/auth/selectors';
import './ResetPassword.scss';

const ResetPassword = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const isAuthenticated = useAppSelector(selectIsAuthenticated);

  useEffect(() => {
    if (!id || isAuthenticated) {
      navigate('/home');
    }
    dispatch(openChangePasswordModal(id as string));
    navigate('/home');
  }, []);

  return (
    <div className="verify-page">
      <Loader />
    </div>
  );
};

export default ResetPassword;
