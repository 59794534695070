import { HTMLAttributes } from 'react';
import { useTranslation } from 'react-i18next';

import { telegramSuppotLink } from 'utils/constants';

const DelayMessageUk = (props: HTMLAttributes<HTMLElement>) => (
  <small {...props}>
    Вивід займає = 60 хвилин (середній час)<br />
    <br />
    Вивід робиться вручну окремим персоналом, тому оператор не може Вам підказати, коли Вам надійдуть кошти.<br />
    Зазвичай заявки на вивід з 00:00 до 10:00 не розглядаються. (Після 10:00 починають одобрюватись)<br />
    Також, на вихідних можуть бути затримки по виводам, вони можуть розглядатись на протязі дня.<br />
    Перевіряйте статус вашої заявки в особистому кабінеті:<br />
    - «Створений» - Ваш вивід ще не розглядався і ви його можете відмінити.<br />
    - «Одобрений» - Ваш вивід одобрений і точно буде у Вас, але після одобрення заявки не завжди він відразу поступає.<br />
    - «Відхилено» - Ваш вивід відхилено по якійсь із причин. Потрібно уточнювати у оператора.<br />
    <br />
    По регламенту платіжної системи вивід може затримуватись до 72 годин.<br />
    Якщо за 72 години Вам не приходять кошти, то потрібно написати оператору він допоможе вирішити Вашу проблему.<br />
    В іншому випадку, оператор не допоможе Вам, так як він має тільки загальну інформацію про вивід коштів.<br />
    <br />
    Ліміт на вивід в добу: 15 000/грн.<br />
    Для VIP-клієнтів ліміт на вивід 30 000грн/доба. (Для отримання VIP-статусу пишіть оператору <a href={telegramSuppotLink} target="_blank" rel="noreferrer">@rysebet_support</a>)<br />
    <br />
    *Важливо!<br />
    - Для оперативного виводу коштів, робимо 1 заявку на вивід в добу. (Якщо це більше 1 заявки, то кожна з них може роглядатись довше)<br />
    - Крім того, якщо Ви робите переказ з одного аккаунта на інший по ID, то вивід може розглядатись до 7 робочих днів. (Важливо це враховувати)<br />
  </small>
);

const DelayMessageRU = (props: HTMLAttributes<HTMLElement>) => (
  <small {...props}>
    Вывод занимает = 60 минут (среднее время)<br />
    <br />
    Вывод делается вручную отдельным персоналом, поэтому оператор не может вам подсказать, когда вам поступят средства.<br />
    Обычно заявки на вывод с 00:00 до 10:00 не рассматриваются. (После 10:00 начинают одобряться)<br />
    Также на выходных могут быть задержки по выводам, они могут рассматриваться в течение дня.<br />
    Проверяйте статус вашей заявки в личном кабинете:<br />
    – «Создан» – Ваш вывод еще не рассматривался и вы его можете отменить.<br />
    – «Одобрен» – Ваш вывод одобрен и точно будет у Вас, но после одобрения заявки не всегда он сразу поступает.<br />
    – «Отклонено» – Ваш вывод отклонен по какой-то из причин. Необходимо уточнять у оператора.<br />
    <br />
    По регламенту платежной системы вывод может задерживаться до 72 часов.<br />
    Если за 72 часа Вам не приходят средства, то нужно написать оператору, он поможет решить Вашу проблему.<br />
    В противном случае оператор не поможет Вам, так как он имеет только общую информацию о выводе средств.<br />
    <br />
    Лимит на вывод в сутки: 30 000/руб.<br />
    Для VIP-клиентов лимит на вывод 200к руб/сутки. (Для получения VIP-статуса пишите оператору <a href={telegramSuppotLink} target="_blank" rel="noreferrer">@rysebet_support</a>)<br />
    <br />
    *Важно!<br />
    – Для оперативного вывода средств, делаем 1 заявку на вывод в сутки. (Если это больше 1 заявки, то каждая из них может рассматриваться дольше)<br />
    - Кроме того, если Вы переводите с одного аккаунта на другой по ID, то вывод может рассматриваться до 7 рабочих дней. (Важно это учитывать)<br />
  </small>
);

const DelayMessage = (props: HTMLAttributes<HTMLElement>) => {
  const { i18n } = useTranslation();

  if (i18n.resolvedLanguage === 'ru') {
    return <DelayMessageRU {...props} />;
  }

  return <DelayMessageUk {...props} />;
};

export default DelayMessage;
