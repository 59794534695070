import React from 'react';
import cn from 'classnames';

import { telegramSuppotLink } from 'utils/constants';

import './SupportIcon.scss';

type Props = {
  className?: string;
};

const SupportIcon = ({ className }: Props) => (
  <a href={telegramSuppotLink} target='_blank' rel="noreferrer">
    <div className={cn('support-icon', className)} />
  </a>
);

export default SupportIcon;
