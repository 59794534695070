import React, { useState } from 'react';

import Loader from 'components/shared/loader/Loader';

import DesktopHeadWidget from '../DesktopHeadWidget/DesktopHeadWidget';
import DesktopListWidget from '../DesktopListWidget/DesktopListWidget';

import type { SiteSection } from 'types/siteSections';
import type { LineType } from 'types/line-data';

type Props = {
  id: SiteSection;
  title: string;
  link: string;
  lineType: LineType;
  isAvailable?: boolean;
  notAvailableMessage?: string;
};

const DesktopTypeWidget = (props: Props) => {
  const { id, title, link, lineType, isAvailable, notAvailableMessage } = props;
  const [sportId, setSportId] = useState<string>();

  return (
    <div className="desktop-type-widget">
      <DesktopHeadWidget
        title={title}
        link={link}
        lineType={lineType}
        changeSport={setSportId}
        activeSport={sportId ?? '0'}
      />
      {isAvailable !== undefined && !isAvailable
        ? <p>{notAvailableMessage}</p> : (
          <div>
            {sportId
              ? <DesktopListWidget activeSport={sportId} type={lineType} /> : <Loader />}
          </div>
        )}
    </div>
  );
};

export default DesktopTypeWidget;
