
import React, { MouseEventHandler } from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import SvgIcon from 'components/shared/SvgIcon/SvgIcon';
import FavoriteBtn from 'components/shared/FavoriteBtn/FavoriteBtn';
import { getDateTimeSlashFormat } from 'utils/time';
import { getCoefTitle } from 'utils/line';
import { routes } from 'utils/routes';
import { bemCn } from 'utils/bem-cn';
import { useToggleFavoriteEvent } from 'hooks/useToggleFavoriteEvent';
import { useAddToBasketMutation } from 'store/basket/basketApi';
import { selectIsAuthenticated } from 'store/auth/selectors';
import { useAppDispatch, useAppSelector } from 'hooks';
import { changeActiveModal } from 'store/auth/authSlice';
import { AddFavoriteMatchBody as EventInfo } from 'types/user-data';

import CardBetItem from './EventCardBetItem';

import './EventCardMobile.scss';

import type { Bet, EventItem } from 'types/line-data';

type Props = {
  basketItem?: string | null;
  event: EventItem;
  simple?: boolean;
  className?: string;
  isFavorite: boolean;
  favoriteId: string;
}


const EventCardMobile = (props: Props) => {
  const {
    event,
    simple,
    className,
    basketItem,
    isFavorite,
    favoriteId
  } = props;

  const { toggleFavoriteMatch, isLoading } = useToggleFavoriteEvent();
  const { t, i18n } = useTranslation();
  const [addToBasket] = useAddToBasketMutation();
  const isAuth = useAppSelector(selectIsAuthenticated);
  const dispatch = useAppDispatch();

  const isLive = event.dataType === 'live';
  const timeMinutes = event.timer ? `${Math.floor(event.timer / 60)} ${t('event.time-minute', 'мин.')}` : '';
  const isTimeShow = isLive && (event.periodName || timeMinutes);

  const eventInfo: EventInfo = {
    'matchId': event.id,
    'team1': event.team1,
    'team2': event.team2,
    'sportName': event.sportName,
    'sportId': event.sportId,
    'tournamentName': event.tournament,
    'tournamentId': event.tournamentId,
    'countryId': event.countryId,
    'countryName': event.countryName,
    'dataType': event.dataType,
  };

  const handleFavoriteClick:MouseEventHandler = (e) => {
    e.preventDefault();
    if (!isLoading) {
      toggleFavoriteMatch({ eventInfo, isFavorite, favoriteId });
    }
  };

  const handleBetClick = (bet: Bet) => {
    if (isAuth) {
      addToBasket({
        dataType: event.dataType,
        basketId: bet.betId,
        lang: i18n.resolvedLanguage,
      });
    } else {
      dispatch(changeActiveModal('sign-in'));
    }
  };

  const b = bemCn('event-card-mobile');
  return (
    <div className={b({ simple }, className)}>
      <Link className={b('link')}
        to={`${routes.fullevent.path}/${event.id}/${event.dataType}/${event.countryName}/${event.countryId}`}
      >
        <div className={b('meta')}>
          <p className={b('date')}>
            {getDateTimeSlashFormat(event.eventDate)}
          </p>
          {isTimeShow && (
            <p className={b('time')}>
              <SvgIcon className={b('time-icon')}
                name='clock'
                width={11}
                height={11}
              />
              <span className={b('time-period')}>
                {timeMinutes} {event.periodName}
              </span>
            </p>
          )}
          <FavoriteBtn
            className={b('favorite')}
            onClick={handleFavoriteClick}
            active={isFavorite}
          />
        </div>
        <div className={b('teams')}>
          <p className={b('team')}>
            {event.team1}
            {event.pitch === event.team1Id && (
              <SvgIcon
                className={b('pitch-icon')}
                name='football'
                width={8}
                height={8}
              />
            )}
          </p>
          <p className={b('team')}>
            {event.team2}
            {event.pitch === event.team2Id && (
              <SvgIcon
                className={b('pitch-icon')}
                name='football'
                width={8}
                height={8}
              />
            )}
          </p>
        </div>
        {isLive && (
          <div className={b('score')}>
            <p className={b('score-total')}>{event.scoreFull}</p>
            <p className={b('score-period')}>
              ( {event.scorePeriod.split(';').join(' | ')} )
            </p>
          </div>
        )}
        <div className={b('meta-sport')}>
          <p className={b('sport')}>{event.sportName}</p>
          <p className={b('tournament')}>{event.tournament}</p>
        </div>
        <div className={b('bets')}>
          {event.group.name === 'stopped'
            ? (
              <>
                <CardBetItem title='-' onClick={() => undefined} />
                <CardBetItem title='-' onClick={() => undefined} />
                <CardBetItem title='-' onClick={() => undefined} />
              </>
            )
            : event.group.bets.slice(0, 3).map((bet) => (
              <CardBetItem
                key={bet.betId}
                rate={bet.rate}
                title={getCoefTitle(bet.caption, event.team1, event.team2)}
                isActive={basketItem === bet.betId}
                onClick={() => handleBetClick(bet)}
                isBlocked={bet.blocked}
              />
            ))}
        </div>
      </Link>
    </div>
  );
};

export default React.memo(EventCardMobile);
