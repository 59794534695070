import React from 'react';
import { useParams, Navigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import Breadcrumbs from 'components/shared/Breadcrumbs/Breadcrumbs';
import { bemCn } from 'utils/bem-cn';
import { useGetCouponQuery } from 'store/user/userApi';
import Loader from 'components/shared/loader/Loader';

import CouponCardBig from '../../../components/profile/BetHistory/CouponCard/CouponCardBig/CouponCardBig';
import HistoryDetails from '../../../components/profile/BetHistory/CouponCard/CouponDetailed/CouponDetailed';
import './BetHistoryDetails.scss';

const BetHistoryDetails = () => {
  const { t, i18n } = useTranslation();
  const { id } = useParams();
  const { data: coupon, isError, isLoading } = useGetCouponQuery(
    { couponId: id, lang: i18n.resolvedLanguage },
    { skip: !id }
  );

  if (isError) {
    return <Navigate to="/bet-history" />;
  }

  const b = bemCn('bet-history-details');
  return (
    <div className={b()}>
      <Breadcrumbs link="/bet-history" title={t('bet-history.title', 'История ставок')} />
      <h1 className={b('title')}>
        {t('bet-history.title', 'История ставок')}
      </h1>
      <div className={b('content')}>
        {!isLoading && coupon
          ? <HistoryDetails coupon={coupon} />
          : <Loader />}
      </div>
    </div>
  );
};

export default BetHistoryDetails;
