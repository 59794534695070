import GamesCardsWidgetMobile from 'components/shared/GamesCardsWidget/GamesCardsWidgetMobile/GamesCardsWidgetMobile';
import { useAppSelector } from 'hooks';
import { selectIsVegasActive } from 'store/app/selectors';
import { useGetVegasGamesQuery } from 'store/slots/slotsApi';
import Adaptive from 'components/Layout/Adaptive/Adaptive';
import GamesCardsWidgetDesktop from 'components/shared/GamesCardsWidget/GamesCardsWidgetDesktop/GamesCardsWidgetDesktop';


const VegasWidget = () => {
  const isVegasActive = useAppSelector(selectIsVegasActive);
  const {
    data: vegasGames,
    isLoading: isVegasLoading,
  } = useGetVegasGamesQuery(undefined, { skip: !isVegasActive });

  if (!isVegasActive) {
    return null;
  }

  const link = '/games?filter=Vegas+Club';
  const gamesList = vegasGames?.items?.slice(0, 20) ?? [];

  return (
    <Adaptive
      mobile={(
        <GamesCardsWidgetMobile
          title="Vegas Club"
          iconColor="pink"
          iconName='coin-flat'
          itemsCounter={vegasGames?.items?.length}
          items={gamesList}
          linkToAll={link}
          isLoading={isVegasLoading}
          isAvailable={isVegasActive}
        />
      )}
      desktop={(
        <GamesCardsWidgetDesktop
          title="Vegas Club"
          items={gamesList}
          linkToAll={link}
          isAvailable={isVegasActive}
        />
      )}
    />
  );
};

export default VegasWidget;
