import React from 'react';

import { telegramLink } from 'utils/constants';
import { bemCn } from 'utils/bem-cn';
import Button from 'components/shared/buttons/Button/Button';
import Container from 'components/Layout/Container/Container';
import MainNav from 'pages/Home/components/MainNav/MainNav';
import SiteSettings from 'components/Layout/PageFooter/components/SiteSettings/SiteSettings';
import Logotype from 'components/shared/Logotype/Logotype';
import SvgIcon from 'components/shared/SvgIcon/SvgIcon';

import UserblockDesktop from '../UserblockDesktop/UserblockDesktop';

import './DesktopHeader.scss';

type Props = {
  isAuthenticated: boolean;
};

const DesktopHeader = ({ isAuthenticated }: Props) => {
  const b = bemCn('main-header-desktop');
  return (
    <header className={b()}>
      <Container className={b('container')}>
        <div className={b('top')}>
          <div className={b('left')}>
            <Logotype className={b('logo')} />
            <MainNav />
          </div>
          <div className={b('buttons-group')}>
            <Button className={b('support')} variant="shaded" >
              <a href={telegramLink} target='_blank' rel="noreferrer">
                <SvgIcon name='support-tg' width={24} height={24} />
              </a>
            </Button>
            <UserblockDesktop className={b('user-block')}
              isAuthenticated={isAuthenticated}
            />
            <SiteSettings below />
          </div>
        </div>
      </Container>
    </header>
  );
};

export default DesktopHeader;
