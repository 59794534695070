import React from 'react';

import Breadcrumbs from 'components/shared/Breadcrumbs/Breadcrumbs';
import TextContent from 'components/shared/TextContent/TextContent';
import { bemCn } from 'utils/bem-cn';
import './PrivacyPage.scss';

const Privacy = [
  {
    title: 'Введение',
    text: (
      <>
        <p className='static-pages__text'>
          Политика конфиденциальности относится ко всем Веб-сайтам, которыми владеет и управляет
          Компания, и ко всем услугам предоставляемым ею, включая онлайн и мобильные услуги и
          продукты. В тексте этой Политики они будут именоваться «Веб-сайты» и «Услуги».
        </p>
        <p className='static-pages__text'>
          Под термином «Персональные данные» понимается информация, позволяющая установить Вашу
          личность, такая как имя, адрес электронной почты, адрес, номер телефона, данные платежного
          профиля, платежная информация, запросы в службу поддержки, история ставок, комментарии в
          чатах и т.д. В случае если Ваша личность не может быть установлена (например, Персональные
          данные обобщены и анонимизированы), данная Политика не применяется.
        </p>
        <p className='static-pages__text'>
          Данная Политика может быть изменена время от времени. В случае значительных изменений мы
          поставим вас в известность – как правило опубликовав уведомление на Веб-сайте или послав
          электронное письмо.
        </p>
      </>
    ),
    id: '1',
  },
  {
    title: 'Какие данные мы собираем и каким образом',
    text: (
      <>
        <p className='static-pages__text'>
          Когда вы посещаете наш Веб-сайт или пользуетесь нашими Услугами мы собираем и обрабатываем
          Ваши Персональные данные.
        </p>
        <p className='static-pages__text'>
          Способы сбора данных можно разделить на следующие категории:
        </p>
        <p className='static-pages__text'>
          Информация, которую вы предоставляете нам непосредственно Когда вы посещаете наш Веб-сайт
          или пользуетесь какой-либо его частью и/или нашими Услугами, мы можем попросить вас
          предоставить нам Персональные данные. Например, мы просим вас предоставить контактные
          данные или определенные документы в процессе регистрации, верификации и процедур,
          направленных на предотвращение отмывания денег, или проверки в целях борьбы с
          мошенничеством, или при вашем обращении к нам с вопросами или запросом в службу поддержки.
        </p>
        <p className='static-pages__text'>Информация собирается автоматически</p>
        <p className='static-pages__text'>
          Мы автоматически собираем некоторую информацию о вас при вашем посещении нашего Веб-сайта,
          такую как Ваш IP-адрес, операционная система, тип и настройки устройства, тип и настройки
          браузера, отчеты о неполадках, системной активности, а также дату, время и URL-адрес
          страницы с которой вы перешли на наш Веб-сайт.
        </p>
        <p className='static-pages__text'>
          Эта информация полезна для нас, поскольку помогает лучше понимать как вы пользуетесь нашим
          Веб-сайтом и Услугами, что позволяет нам обеспечивать наилучший уровень сервиса.
        </p>
        <p className='static-pages__text'>
          Например, пользуясь информацией из вашего браузера, мы можем определить на каком языке вы
          разговариваете.
        </p>
        <p className='static-pages__text'>
          Часть этой информации собирается при помощи файлов «cookie», аналогичных технологий
          отслеживания и сторонних инструментов, таких как Google Analytics.
        </p>
        <p className='static-pages__text'>
          Мы также собираем данные о транзакциях, которые вы производили с нами, и об активности
          Вашей учетной записи.
        </p>
        <p className='static-pages__text'>Информация, получаемая от третьих лиц</p>
        <p className='static-pages__text'>
          Иногда мы можем собирать Ваши Персональные данные из других источников, таких как
          общедоступные материалы или доверенные третьи лица, например наши провайдеры платежных
          услуг. Мы используем эту информацию для уточнения ваших Персональных данных с целью
          лучшего информирования, персонализации и совершенствования наших Услуг, а также для
          проверки предоставленных Вами Персональных данных.
        </p>
      </>
    ),
    id: 2,
  },
  {
    title: 'Правовые основания для обработки Персональных данных ',
    text: (
      <>
        <p className='static-pages__text'>
          Собирая Персональные данные, мы обрабатываем их только при наличии правовых оснований для
          такой обработки указанных в соответствующих законах о защите данных.
        </p>
        <h3 className='static-pages__text'>Такими правовыми основаниями являются:</h3>
        <p className='static-pages__text'>Выполнение договора</p>
        <p className='static-pages__text'>
          Мы можем обрабатывать Ваши Персональные данные если это необходимо для выполнения
          договора. Например, когда вы регистрируетесь на Веб-сайте, вы вступаете в соглашение с
          нами или когда мы предпринимаем действия, имеющие отношение к договору, в частности,
          проводим транзакции на Веб-сайтах.
        </p>
        <p className='static-pages__text'>Юридическое обязательство</p>
        <p className='static-pages__text'>
          Различные законы и правила накладывают на нас определенные обязательства. Нам необходимо
          обрабатывать Ваши Персональные данные для выполнения этих обязательств, например, для
          исполнения законодательства по предотвращению отмывания денег, требований ответственной
          игры и условий нашей лицензии на азартные игры.
        </p>
        <p className='static-pages__text'>Законные интересы</p>
        <p className='static-pages__text'>
          Ваши Персональные данные могут быть обработаны если мы, другие компании нашей группы
          компаний или третьи стороны имеют деловые или коммерческие основания для обработки ваших
          Персональных данных.
        </p>
        <p className='static-pages__text'>Ваше согласие</p>
        <p className='static-pages__text'>
          В определенных ограниченных случаях мы обрабатываем Ваши Персональные данные на основе
          вашего согласия, например, когда это необходимо для целей прямого маркетинга.
        </p>
      </>
    ),
    id: 3,
  },
  {
    title: 'Как мы используем Персональные данные ',
    text: (
      <>
        <h3>Использование ваших Персональных данных (Правовые основания):</h3>
        <ul>
          <li>
            <p>
              Для управления нашими Веб-сайтами, обеспечения надлежащего функционирования наших
              Веб-сайтов и Услуг и предоставления заказанных Вами Услуг (Выполнение договора,
              Законные интересы)
            </p>
          </li>
          <li>
            <p>
              Для проверки можете ли вы пользоваться определенными Услугами, включая проверку вашего
              возраста, географического положения, личности или статуса самоисключения, а также для
              создания и функционирования Вашей учетной записи (Выполнение договора, Законные
              интересы)
            </p>
          </li>
          <li>
            <p>
              Для исполнения правовых обязательств, ответственности и обязанностей, для соответствия
              законам и требованиям, имеющим отношение к нам, и условиям нашей лицензии на азартные
              игры, а также для предотвращения незаконных действий, включая отмывание денег и
              договорные матчи (Юридическое обязательство)
            </p>
          </li>
          <li>
            <p>
              Для поддержки клиента, включая помощь в разрешении технических и платежных проблем или
              других вопросов, касающихся Веб-сайтов или Услуг (Выполнение договора)
            </p>
          </li>
          <li>
            <p>
              Для расширения наших Веб-сайтов и Услуг, тестирования и разработки новых функций и
              проведения технического анализа наших Веб-сайтов и Услуг оптимизации вашего
              взаимодействия с нашими Веб-сайтами, а также предоставления Вам более эффективных
              инструментов (Законные интересы)
            </p>
          </li>
          <li>
            <p>
              Для предотвращения, выявления и информирования о преступлении, защиты вас, других
              пользователей и нас, например, для обеспечения сетевой и информационной безопасности,
              снижения рисков, выявления и предотвращения любых мошеннических или вредоносных
              действий, а также обеспечение честного использования наших Веб-сайтов и Услуг и
              соблюдения Соглашения (Юридическое обязательство, Законные интересы, Выполнение
              договора)
            </p>
          </li>
          <li>
            <p>
              Для анализа и обобщения данных, для подготовки статистики, в частности, для создания
              обобщенной и анонимизированной аналитики и отчетов для внутреннего использования,
              публичного распространения или для третьих лиц (Законные интересы)
            </p>
          </li>
          <li>
            <p>
              Для проведения, управления и подтверждения финансовых транзакций (Выполнение договора,
              Законные интересы)
            </p>
          </li>
          <li>
            <p>
              Для анализа риска мошенничества с Вашей стороны и подтверждения ваших данных с помощью
              третьей стороны, включая финансовые учреждения, агентства, проверяющие личность и бюро
              кредитной информации (Юридическое обязательство, Выполнение договора, Законные
              интересы)
            </p>
          </li>
          <li>
            <p>
              Для оценки ваших игровых действий в целях обеспечения ответственного отношения к игре
              (Юридическое обязательство, Законные интересы, Выполнение договора)
            </p>
          </li>
          <li>
            <p>
              Для отслеживания игровой активности и управления нашими рисками и шансами (Законные
              интересы, Выполнение договора)
            </p>
          </li>
          <li>
            <p>
              Для реализации наших прав оговоренных в Соглашении или других соглашениях с Вами
              (Выполнение договора)
            </p>
          </li>
          <li>
            <p>
              Для раскрытия информации компаниям нашей Группы после реструктуризации или для
              внутренних административных целей (Законные интересы)
            </p>
          </li>
          <li>
            <p>
              Для урегулирования наших отношений и общения с Вами. Это может включать в себя:
              оперативные сообщения, такие как сообщения об изменениях на наших Веб-сайтах или в
              Услугах, информация о новых функциях, бонусах и акциях, обновлениях системы
              безопасности или помощь по использованию наших Веб-сайтов и Услуг, маркетинговые
              сообщения и предоставление запрошенной Вами информации или информации, которую мы
              обязаны Вам сообщить (Выполнение договора, Законные интересы, Ваш согласие)
            </p>
          </li>
        </ul>
      </>
    ),
    id: 4,
  },
  {
    title: 'Как мы можем передавать Ваши Персональные данные ',
    text: (
      <>
        <p className='static-pages__text'>
          Нам может потребоваться передать Ваши Персональные данные третьей стороне.
        </p>
        <p className='static-pages__text'>Мы можем раскрыть Ваши Персональные данные:</p>
        <ul>
          <li>
            <p>Другим компаниям из нашей группы компаний;</p>
          </li>
          <li>
            <p>
              Сторонним поставщикам услуг и партнерам, которые помогают нам в предоставлении
              запрошенных Вами Веб-сайтов и Услуг, например, тем, кто обеспечивает доступ или
              функционирование Веб-сайтов или Услуг, или рекламирует наши Веб-сайты и Услуги;
            </p>
          </li>
          <li>
            <p>
              Регулирующим, правоохранительным, государственным органам, судам, агентствам по
              предотвращению мошенничества, лицензирующим органам, органам самоуправления
              киберспорта или другим третьим сторонам, если, по нашему мнению, это необходимо для
              выполнения требований соответствующих законов и требований или для реализации,
              установления и защиты наших законных прав (мы будем оповещать вас о таком раскрытии
              информации, когда это возможно и уместно);
            </p>
          </li>
          <li>
            <p>Партнеры или другие лица, представившие вас нам;</p>
          </li>
          <li>
            <p>Другие лица, при наличии вашего согласия.</p>
          </li>
        </ul>
      </>
    ),
    id: 5,
  },
  {
    title: 'Международная передача данных',
    text: (
      <>
        <p className='static-pages__text'>
          При обработке и передаче информации, она может быть передана и обработана в странах помимо
          той, в которой вы проживаете. Законы этих стран могут отличаться от привычных Вам. При
          обработке Персональных данных в другой стране мы принимаем меры предосторожности для
          защиты ваших Персональных данных.
        </p>
        <p className='static-pages__text'>
          Для лиц из Европейской Экономической Зоны (ЕЭЗ) это означает, что Ваши данные могут быть
          переданы за пределы ЕЭЗ. В случае если Ваши данные передаются за пределы ЕЭЗ, они
          передаются в страны где у нас есть соответствующие механизмы передачи для защиты ваших
          Персональных данных, в частности, применение Стандартных договорных условий Европейской
          комиссии к договорам с организациями, которым передается информация.
        </p>
      </>
    ),
    id: 6,
  },
  {
    title: 'Безопасность',
    text: (
      <>
        <p>
          Мы обязуемся защищать Ваши Персональные данные и предпринимать соответствующие технические
          и организационные меры, в том числе:
        </p>
        <ul>
          <li>
            <p>
              Шифрование данных Для защиты ваших Персональных и финансовых данных мы шифруем все
              данные, которыми обмениваемся с Вами, используя отраслевой стандарт TLS (Transport
              Layer Security) (Протокол защиты транспортного уровня). Также ваши данные хранятся на
              наших серверах и передаются с целью резервного копирования и репликации между центрами
              обработки данных в зашифрованном виде.
            </p>
          </li>
          <li>
            <p>
              Ограничение доступа Доступ к Персональным данным имеют только наши работники,
              подрядчики и агенты, которым эта информация необходима для обработки.
            </p>
          </li>
          <li>
            <p>
              Сетевая защита. Доступ в наше сетевое пространство как извне, так и изнутри защищен
              многоуровневой системой безопасности, включающей в себя сетевые экраны, системы защиты
              от вторжения и сегментацией сети. Наши системы безопасности настраиваются, управляются
              и обслуживаются в соответствии с передовыми практическими методами. Мы сотрудничаем с
              ведущими поставщиками систем безопасности, используя их опыт и собранную ими
              информацию об угрозах, для защиты наших систем.
            </p>
          </li>
          <li>
            <p>
              Защищенные центры обработки данных. Наши серверы расположены в хостинг-объектах
              промышленного уровня, использующих надежные системы безопасности для предотвращения
              физического доступа к охраняемым серверам. Эти системы безопасности включают
              круглосуточный непрерывный контроль и наблюдение, присутствие на объекте сотрудников
              службы безопасности и регулярно проводимые проверки безопасности. Мы используем
              географически обособленные копии данных для минимизации риска потери данных или сбоев
              в работе.
            </p>
          </li>
          <li>
            <p>
              Мониторинг безопасности Наша служба безопасности непрерывно контролирует систему
              безопасности, журналы событий, уведомления и предупреждения от всех систем для
              выявления и устранения угроз.
            </p>
          </li>
        </ul>
      </>
    ),
    id: 7,
  },
  {
    title: 'Хранение данных',
    text: (
      <>
        <p className='static-pages__text'>
          Часть данных вы можете удалить в любое время, часть удаляется автоматически, а часть
          данных мы, при необходимости, сохраняем в течение продолжительного времени. При стирании
          данных, мы обеспечиваем безопасное и полное удаление ваших данных с наших серверов или
          сохраняем их исключительно в анонимной форме. Информация хранится до тех пор, пока вы ее
          не удалите
        </p>
        <p className='static-pages__text'>
          Мы предлагаем ряд услуг, которые позволяют исправлять или удалять данные, связанные с в
          вашей учетной записью или хранящиеся в ней. Например, вы можете:
        </p>
        <ul>
          <li>
            <p>Изменить информацию о себе,</p>
          </li>
          <li>
            <p>Удалить реплику в чате, или</p>
          </li>
          <li>
            <p>Полностью удалить учетную запись.</p>
          </li>
        </ul>
        <p className='static-pages__text'>
          В соответствии с регуляторными требованиями и юридическими обязательствами и во избежание
          каких-либо исков против нас, мы будем хранить данные вашей учетной записи до тех пор, пока
          она не будет закрыта и далее в течение пяти лет после закрытия вашей учетной записи (в
          соответствующих случаях) или после вашего последнего контакта с нами.
        </p>
        <p className='static-pages__text'>
          Информация сохраняется в течение длительных периодов времени в ограниченных целях
        </p>
        <p className='static-pages__text'>
          В некоторых случаях бизнес- и юридические требования могут обязать нас хранить
          определенную информацию, для конкретных целей, в течение длительного времени. Например,
          если лицо самоисключилось из наших Услуг, мы будем хранить его Персональные данные дольше,
          согласно требований ответственной игры.
        </p>
        <p className='static-pages__text'>
          Причины, по которым мы можем сохранять некоторые данные дольше, включают в себя:
        </p>
        <ul>
          <li>
            <p>
              защиту вас, других лиц и нас от мошенничества, злоупотреблений, преступной
              деятельности и несанкционированного доступа, например, при подозрении кого-либо в
              совершении мошенничества, участии в договорных матчах или отмывании денег.
            </p>
          </li>
          <li>
            <p>
              соблюдение налогового законодательства, мер по предотвращению отмывания денег и других
              финансовых требований, осуществление бухгалтерского учета или содействие решению
              спорных вопросов, например, при участии в финансовых транзакциях, включая получение
              вашего вклада и выплату ваших денег.
            </p>
          </li>
          <li>
            <p>
              выполнение применимого закона, нормативного акта, судебного процесса или
              исполнительного правительственного запроса или необходимость приведения в исполнение
              соглашений, включая расследование потенциальных нарушений.
            </p>
          </li>
          <li>
            <p>обеспечение непрерывности наших Услуг для вас и других пользователей.</p>
          </li>
          <li>
            <p>
              в случае если вы непосредственно связались с нами, например, через канал службы
              поддержки или направив отзыв или отчет об ошибке.
            </p>
          </li>
        </ul>
        <p className='static-pages__text'>
          Мы обязуемся защищать конфиденциальность ваших персональных данных, собирать, использовать
          и/или хранить их в соответствии с применимыми законами и требованиями о защите информации
          и, в частности, положениями законов (страны) о защите данных и другими применимыми
          Международными правилами защиты данных, такими как Постановление ЕС 2016/679 «Общий
          регламент по защите данных» (GDPR) (далее «Законы о защите данных»).
        </p>
      </>
    ),
    id: 8,
  },
  {
    title: 'Ваши права',
    text: (
      <>
        <p>Вы имеете определенные права в отношении ваших Персональных данных:</p>
        <ul>
          <li>
            <p>знать какие ваши Персональные данные мы храним.</p>
          </li>
          <li>
            <p>
              корректировать или просить нас корректировать неточные Персональные данные касательно
              вас.
            </p>
          </li>
          <li>
            <p>
              иметь доступ к вашим Персональным данным и запрашивать их копию в машиночитаемом виде,
              например, если вы хотите иметь их резервную копию.
            </p>
          </li>
          <li>
            <p>
              возражать против обработки ваших Персональных данных, в случаях когда мы
              руководствуемся нашими законными интересами.
            </p>
          </li>
        </ul>
        <p className='static-pages__text'>
          Обратите внимание, что мы можем продолжить обрабатывать ваши Персональные данные в случае
          наличия иных соответствующих законных оснований или в случае наличия веских оснований для
          продолжения обработки данных в наших интересах, над которыми не превалируют ваши права,
          интересы и свободы.
        </p>
        <ul>
          <li>
            <p>
              просить нас стереть ваши Персональные данные, включая удаление вашей учетной записи,
              только в том случае, если (1) у нас нет более необходимости в их обработке, (2) вы
              отозвали свое согласие на обработку и ваше согласие было правовым основанием для
              обработки ваших данных, (3) вы использовали свое право на возражение и нет
              превалирующих законных оснований для продолжения обработки данных, (4) ваши
              Персональные данные обрабатывались незаконно, (5) или удаление ваших Персональных
              данных требуется для соответствия юридическому обязательству. Обратите внимание, что
              мы не будем стирать и продолжим хранить данные, в частности, если хранение
              Персональных данных требуется для соблюдения наших законных интересов, которые
              превалируют над вашим требованием, для соблюдения юридических обязательств или если
              это необходимо для формирования, осуществления судебных исков или защиты от таковых.
            </p>
          </li>
          <li>
            <p>
              просить нас ограничить обработку Персональных данных в определенных обстоятельствах.
            </p>
          </li>
          <li>
            <p>
              отзывать ранее данное согласие, если ваше согласие было необходимо для обработки
              Персональных данных. Обратите внимание, что мы можем продолжать обрабатывать ваши
              Персональные данные при наличии на то иных соответствующих правовых оснований.
            </p>
          </li>
          <li>
            <p>
              возражать против прямого маркетинга и составления профиля личности клиента (в той
              мере, в какой это относится исключительно к прямому маркетингу). Что касается
              маркетинговых сообщений, вы можете попросить нас прекратить рассылку в любой момент –
              просто следуйте инструкциям, содержащимся в маркетинговом сообщении или отправьте
              заявку на электронный адрес
              <a href="mailto:support@rysebet.com" className='static-pages__link'>
                support@rysebet.com
              </a>
              .
            </p>
          </li>
          <li>
            <p>пожаловаться в свой местный орган по защите персональных данных.</p>
          </li>
        </ul>
        <p className='static-pages__text'>
          Обратите внимание, что не все вышеизложенные права являются абсолютными.
        </p>
        <p className='static-pages__text'>
          Вы можете воспользоваться своими правами в любой момент путем изменения настроек своей
          учетной записи или отправив электронное письмо на адрес
          <a href="mailto:support@rysebet.com" className='static-pages__link'>
            support@rysebet.com
          </a>
          .
        </p>
        <p className='static-pages__text'>
          Для выполнения запрошенных вами действий вы должны будете подтвердить свою личность.
        </p>
        <p className='static-pages__text'>
          Если вы не удовлетворены тем как мы обрабатываем ваши Персональные данные, пожалуйста
          сообщите об этом на электронный адрес
          <a href="mailto:support@rysebet.com" className='static-pages__link'>
            support@rysebet.com
          </a>
          . Мы рассмотрим вашу претензию и постараемся дать вам ответ в разумный срок.
        </p>
      </>
    ),
    id: 9,
  },
  {
    title: 'Использование Google Analytics',
    text: (
      <>
        <p className='static-pages__text'>
          Мы используем Google Analytics для сбора информации об использовании этого Веб-сайта.
          Google Analytics собирает следующую информацию: вашу посещаемость Веб-сайта, какие
          страницы вы просматриваете при этом и какие другие сайты вы используете перед переходом на
          наш Веб-сайт.
        </p>
        <p className='static-pages__text'>
          Google Analytics собирает только IP-адрес, присвоенный вам в тот день, когда вы посещали
          Веб-сайт, но не ваше имя и другую идентификационную информацию. Мы не объединяем
          информацию, собранную при помощи Google Analytics, с вашими Персональным данными.
        </p>
        <p className='static-pages__text'>
          Хотя Google Analytics размещает постоянный файл cookie в вашем веб-браузере для
          идентификации вас в качестве уникального пользователя при следующем вашем посещении
          данного Веб-сайта, этим файлом cookie не может воспользоваться никто кроме Google.
        </p>
        <p className='static-pages__text'>
          Способность Google использовать и делиться информацией о ваших визитах на Веб-сайт,
          собранной инструментом Google Analytics, ограничена Условиями использования Google
          Analytics и Политикой конфиденциальности Google. Вы можете запретить Google Analytics
          распознавать вас при повторном посещении Веб-сайта отключив cookies в своем браузере.
        </p>
        <p className='static-pages__text'>
          При желании, вы можете отключить cookies в настройках своего браузера. Для получения
          дополнительной информации о Google Analytics посетите страницу
          <a
            href="http://www.google.com/analytics/"
            target="_blank"
            className='static-pages__link'
            rel="noreferrer"
          >
            http://www.google.com/analytics/
          </a>
          .
        </p>
        <p className='static-pages__text'>
          Чтобы узнать больше о том, как Google использует файлы cookies, посетите страницу
          <a
            href="https://policies.google.com/technologies/cookies"
            target="_blank"
            className='static-pages__link'
            rel="noreferrer"
          >
            https://policies.google.com/technologies/cookies
          </a>
        </p>
      </>
    ),
    id: 10,
  },
  {
    title: 'Шифрование SSL',
    text: (
      <p className='static-pages__text'>
        Вся критически важная переписка между пользователем и Веб-сайтом шифруется по протоколу
        Secure Socket Layer (SSL) с использованием 256-битного ключа.
      </p>
    ),
    id: 11,
  },
];

const b = bemCn('privacy-page');

const PrivacyPage = () => (
  <div className={b()}>
    <Breadcrumbs title="На главную" link="/" />
    <TextContent className={b('content')}>
      <h1>Политика конфиденциальности</h1>
      {Privacy.map((item) => (
        <div key={item.id}>
          <h2>
            {item.id}. {item.title}
          </h2>
          {item.text}
        </div>
      ))}
    </TextContent>
  </div>
);

export default PrivacyPage;
