import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import Loader from 'components/shared/loader/Loader';
import SearchFilter from 'components/shared/SearchFilter/SearchFilter';
import { useAppSelector } from 'hooks';
import { selectEventsFilter } from 'store/app/selectors';
import TournamentCardDesktop from 'components/line/TournamentCard/TournamentCardDesktop/TournamentCardDesktop';
import Button from 'components/shared/buttons/Button/Button';
import { filterTournamentEvents } from 'utils/common';
import { useFilteredEventsByTourney } from 'hooks/useFilteredEventsByTourney';

import DesktopLiveBanners from '../DesktopLiveBanners/DesktopLiveBanners';

import type { LineType } from 'types/line-data';

type Props = {
  lineType: LineType;
};

const DesktopLineList = ({ lineType }: Props) => {
  const [pageSize, setPageSize] = useState(100);
  const { i18n, t } = useTranslation();
  const [searchValue, setSearchValue] = useState<string>('');
  const [isShowBtn, setShowBtn] = useState(true);
  const [isRefetch, setIsRefetch] = useState(true);
  const { sport: activeSport, tournament: activeTournament } = useAppSelector(selectEventsFilter);
  const isLive = lineType === 'live';

  const {
    data: eventsByTournaments = [],
    isLoading: isEventsLoading,
    isFetching: isEventsFetching,
  } = useFilteredEventsByTourney(
    {
      sportId: activeSport === 'favorites' ? '0' : activeSport,
      tournamentId: activeTournament,
      dataType: lineType,
      dataLang: i18n.resolvedLanguage,
      pageLength: pageSize,
    },
    { pollingInterval: isLive ? 4000 : 0, }
  );

  const eventsCount = eventsByTournaments.reduce((acc, tournament) => acc + tournament.events.length, 0);

  const filteredTournaments = filterTournamentEvents(eventsByTournaments, searchValue);


  useEffect(() => {
    if (isLive || !isEventsFetching) {
      setIsRefetch(false);
    } else {
      setIsRefetch(isEventsFetching);
    }
  }, [isEventsFetching]);

  useEffect(() => {
    if (isLive) {
      setIsRefetch(true);
    }
  }, [activeSport, activeTournament]);

  useEffect(() => {
    setPageSize(100);
  }, [activeSport, activeTournament]);

  useEffect(() => {
    setShowBtn(!(eventsCount < pageSize));
  }, [eventsCount, pageSize]);

  const handleSearchChange = (newSearchValue: string) => {
    setSearchValue(newSearchValue);
  };

  const isTournamentsEmpty = filteredTournaments.length <= 0;

  return (
    <>
      <DesktopLiveBanners />
      <div className="desktop-live__search">
        <SearchFilter
          openSearch
          onChange={handleSearchChange}
        />
      </div>
      {
        (isTournamentsEmpty && !isRefetch) ? (
          <div style={{ display: 'flex', justifyContent: 'space-evenly', marginTop: '8px' }}>
            <span>{t('event.warning.no-events', 'На данный момент нет матчей')}</span>
          </div>
        ) : (
          <div className="desktop-live__list">
            {(!eventsByTournaments.length || isRefetch)
              ? <Loader />
              : (
                <div>
                  {filteredTournaments.map((tournament) => (
                    <TournamentCardDesktop
                      key={tournament.id}
                      tournament={tournament}
                    />
                  ))}
                </div>
              )}
            <Button className="desktop-live__show-more"
              variant='shaded'
              onClick={() => setPageSize((prev) => prev + 100)}
              isLoading={isEventsFetching}
            >
              Загрузить ещё
            </Button>
          </div>
        )
      }
    </>
  );
};

export default DesktopLineList;
