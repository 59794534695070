import { ClassNameFormatter } from '@bem-react/classname';
import { Link } from 'react-router-dom';

export type FooterListItem = {
  name: string;
  link?: string;
  href?: string;
};

type Props = {
  item: FooterListItem;
  b: ClassNameFormatter;
}

const FooterNavItem = ({ item, b }: Props) => (
  <li className={b('item')}>
    {item.link && (
      <Link to={item.link} className={b('link')}>{item.name}</Link>
    )}
    {item.href && (
      <a className={b('link')}
        href={item.href}
        target='_blank'
        rel="noreferrer"
      >
        {item.name}
      </a>
    )}
  </li>
);

export default FooterNavItem;
