import { SwiperSlide } from 'swiper/react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import GradientIcon from 'components/shared/icons/GradientIcon/GradientIcon';
import SvgIcon from 'components/shared/SvgIcon/SvgIcon';
import EventCardMobile from 'components/line/EventCard/EventCardMobile/EventCardMobile';
import { filterByEntries } from 'utils/common';
import { topTournamentsID } from 'utils/constants';
import { bemCn } from 'utils/bem-cn';
import { useGetBasketQuery } from 'store/basket/basketApi';
import { selectIsAuthenticated } from 'store/auth/selectors';
import { useAppSelector } from 'hooks';
import { useGetFavoriteMatchesQuery } from 'store/user/userApi';

import WidgetSlider from './components/TopEventsWidgetSlider';

import type { EventItem } from 'types/line-data';

import './TopEventsWidgetMobile.scss';

type Props = {
  title: string;
  iconName?: string;
  iconColor?: string;
  allLink: string;
  isLoading?: boolean;
  events: EventItem[];
  isAvailable?: boolean;
  notAvailableMessage?: string;
}

const b = bemCn('top-events-widget-mobile');
const TopEventsWidgetMobile = (props: Props) => {
  const { t } = useTranslation();
  const {
    title,
    iconName,
    iconColor,
    allLink,
    isLoading,
    events,
    isAvailable,
    notAvailableMessage = t('maintenance.notAvailableMessage', 'Временно недоступно')
  } = props;

  const isAuth = useAppSelector(selectIsAuthenticated);
  const { data: favoriteEvents = [] } = useGetFavoriteMatchesQuery(undefined, { skip: !isAuth });
  const { basketByGameId } = useGetBasketQuery(undefined, {
    skip: !isAuth,
    selectFromResult: ({ data }) => ({
      basketByGameId: data?.itemsByGameId
    })
  });
  const filteredEvents = filterByEntries(
    events || [],
    topTournamentsID,
    (e) => e.tournamentId
  );
  const shownEvents = filteredEvents.length > 10 ? filteredEvents : events;
  const eventsCount = shownEvents ? shownEvents.length : 0;

  return (
    <div className={b()}>
      <div className={b('head')}>
        <GradientIcon className={b('icon')}
          color={iconColor}
          iconName={iconName}
        />
        <p className={b('title')}>{title}</p>
        <div className={b('meta')}>
          <Link className={b('all')} to={allLink} >
            {t('top-events-widget.all', 'Все')}
            <SvgIcon className={b('all-icon')}
              name="arrow-right"
              width={9} height={9}
            />
          </Link>
          <p className={b('count')}>
            {eventsCount} {t('top-events-widget.events', 'события')}
          </p>
        </div>
      </div>
      <div className={b('events')}>
        {isAvailable !== undefined && !isAvailable
          ? <p>{notAvailableMessage}</p>
          : (
            <WidgetSlider isLoading={isLoading}>
              {events.slice(0, 20).map((event) => {
                const favoriteId = favoriteEvents.find(({ matchId }) => matchId === event.id)?.id;
                return (
                  <SwiperSlide key={event.id} className={b('events-slide')}>
                    <EventCardMobile className={b('event-item')}
                      basketItem={basketByGameId?.[event.id]?.basketId}
                      event={event}
                      isFavorite={favoriteId !== undefined}
                      favoriteId={favoriteId ?? 'notFavoried'}
                    />
                  </SwiperSlide>
                );
              })}
            </WidgetSlider>
          )}
      </div>
    </div>
  );
};

export default TopEventsWidgetMobile;
