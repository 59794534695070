import 'swiper/css';
import 'swiper/css/pagination';

import Breadcrumbs from 'components/shared/Breadcrumbs/Breadcrumbs';
import { routes } from 'utils/routes';

import EventCardFull from '../EventCard/EventCardFull/EventCardFull';

import type { LineType } from 'types/line-data';

import './FullEvent.scss';

interface Props {
  lineType: LineType;
  eventId: string;
}

const FullEvent = ({ eventId, lineType }: Props) => (
  <div className="tournament">
    <Breadcrumbs link={routes[lineType].path} title={routes[lineType].title} />
    <div className="tournament__content">
      <EventCardFull
        lineType={lineType}
        eventId={eventId}
      />
    </div>
  </div>
);

export default FullEvent;
