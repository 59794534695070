import React, { useState, useEffect, useCallback } from 'react';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import {
  resetChangeUserPasswordStatus,
  resetchangeUserPasswordErrors
} from 'store/user/userSlice';
import {
  selectChangeUserPasswordErrors,
  selectChangeUserPasswordStatus,
} from 'store/user/selectors';
import { useAppDispatch, useAppSelector } from 'hooks';
import Button from 'components/shared/buttons/Button/Button';
import ErrorBlock from 'components/shared/inputs/ErrorBlock/ErrorBlock';
import { useChangeUserPasswordMutation } from 'store/user/userApi';
import ResultModal from 'components/shared/ResultModal/ResultModal';
import FormInputWrapper from 'components/shared/form/FormInput';
import { bemCn } from 'utils/bem-cn';
import i18n from 'i18n/i18n';

import type { Entries } from 'types/common';
import type { ChangeUserPasswordErrors } from 'types/user-data';

import './PasswordChange.scss';

type FormFields = {
  oldPassword: string;
  newPassword: string;
};

const schema = yup.object({
  oldPassword: yup.string()
    .trim()
    .min(3, `${i18n.t('reset-pass.errors.short-pass', 'Слишком короткий пароль')}`)
    .required(`${i18n.t('reset-pass.errors.empty-pass', 'Введите пароль')}`),
  newPassword: yup.string()
    .trim()
    .min(3, `${i18n.t('reset-pass.errors.short-pass', 'Слишком короткий пароль')}`)
    .required(`${i18n.t('reset-pass.errors.empty-pass', 'Введите пароль')}`),
}).required();

const PasswordChange = () => {
  const dispatch = useAppDispatch();
  const changePassswordErrors = useAppSelector(selectChangeUserPasswordErrors);
  const changePasswordStatus = useAppSelector(selectChangeUserPasswordStatus);
  const [changeUserPassword, { isLoading }] = useChangeUserPasswordMutation();
  const [isSuccess, setIsSuccess] = useState(false);
  const { t } = useTranslation();

  const { handleSubmit, setError, control, reset } = useForm<FormFields>({
    defaultValues: { oldPassword: '', newPassword: '' },
    mode: 'all',
    resolver: yupResolver(schema),
  });

  useEffect(() => {
    if (changePasswordStatus === 'success') {
      setIsSuccess(true);
      reset({ oldPassword: '', newPassword: '' });
    } else {
      setIsSuccess(false);
    }
  }, [changePasswordStatus]);

  useEffect(() => () => {
    dispatch(resetChangeUserPasswordStatus());
    dispatch(resetchangeUserPasswordErrors());
  }, []);

  useEffect(() => {
    const fieldsErrors: Partial<ChangeUserPasswordErrors> = { ...changePassswordErrors };
    delete fieldsErrors.common;

    const formEntries = Object.entries(fieldsErrors) as Entries<FormFields>;
    formEntries.forEach(([key, value]) => {
      if (!value) { return; }

      setError(key, {
        type: 'server',
        message: value,
      });
    });
  }, [changePassswordErrors]);

  const handleDefaultFormSubmit = async (data: FormFields) => {
    await changeUserPassword(data);
  };

  const handleModalClose = useCallback(() => {
    dispatch(resetChangeUserPasswordStatus());
    dispatch(resetchangeUserPasswordErrors());
  }, []);

  const b = bemCn('password-change');

  return (
    <div className={b()}>
      <h3 className={b('title')}>
        {t('change-password.title', 'Изменение пароля')}
      </h3>
      <form className={b('form')} action="#" method="POST" onSubmit={handleSubmit(handleDefaultFormSubmit)}>
        <ErrorBlock
          isDisplayed={!!changePassswordErrors.common}
          message={changePassswordErrors.common}
          align="right"
        />
        <FormInputWrapper<FormFields>
          showError
          type="password"
          control={control}
          name="oldPassword"
          placeholder={`${t('change-password.current-pass', 'Введите текущий пароль')}`}
          autoComplete="current-password"
          disabled={isLoading}
          variant='inverted'
        />
        <FormInputWrapper<FormFields>
          showError
          type="password"
          control={control}
          name="newPassword"
          placeholder={`${t('change-password.new-pass', 'Введите текущий пароль')}`}
          disabled={isLoading}
          variant='inverted'
        />
        <div className={b('button-wrapper')}>
          <Button
            type="submit"
            variant="outline-primary-inverted"
            isLoading={isLoading}
          >
            {t('change-password.submit-btn', 'Изменить пароль')}
          </Button>
        </div>
      </form>
      <ResultModal
        title={t('result-modal.change-password.title', 'Пароль изменён')}
        status="success"
        contentLabel="Change password modal"
        isOpen={isSuccess}
        description={`${t('result-modal.change-password.description', 'Ваш пароль успешно изменён!')}`}
        onClose={handleModalClose}
      />
    </div>
  );
};

export default PasswordChange;
