import { useContext } from 'react';

import ThemeContext from 'contexts/ThemeContext';
import GradientIcon from 'components/shared/icons/GradientIcon/GradientIcon';
import { getSportIconName } from 'utils/common';

const iconColor: Record<string, string> = {
  'light-blue': 'light-blue',
  'dark-blue': 'dark-blue'
};

type Props = {
  className?: string;
  iconName?: string;
  sportId?: string;
}

const SportIcon = ({ className, sportId, iconName }: Props) => {
  const { theme } = useContext(ThemeContext);

  const color = iconColor[theme] ?? 'green';
  const sportIconName = iconName
    ? iconName
    : getSportIconName(sportId as string);

  return (
    <GradientIcon className={className} iconName={sportIconName} color={color} />
  );
};

export default SportIcon;
