import React from 'react';

import { useResponsive } from 'hooks/useResponsive';
import { bemCn } from 'utils/bem-cn';

import NavMenuItem from './NavMenuItem';

import './NavMenu.scss';

export type MenuItem = {
  link?: string;
  title: React.ReactNode;
  id: number;
  disabled?: boolean;
};

type Props = {
  alignment?: 'center' | 'left';
  className?: string;
  menuItems: MenuItem[];
  colorScheme?: '' | 'inverse';
  onClick?: (id: number) => void;
  activeItemId?: number;
  onClickLink?: (id: number) => void;
};

const NavMenu = (props: Props) => {
  const b = bemCn('nav-menu');
  const {
    alignment = 'center',
    className,
    menuItems,
    colorScheme,
    activeItemId,
    onClick,
    onClickLink
  } = props;
  const { Mobile } = useResponsive();


  return (
    <div className={b('wrapper')}>
      <nav className={b({ alignment }, className)}>
        {menuItems.map((menuItem) => (
          <NavMenuItem
            key={menuItem.id}
            item={menuItem}
            isActive={menuItem.id === activeItemId}
            isInverse={colorScheme === 'inverse'}
            onClick={() => onClick?.(menuItem.id)}
            onClickLink={() => onClickLink?.(menuItem.id)}
            b={b}
          />
        ))}
        <Mobile><div className={b('separator')} /></Mobile>
      </nav>
    </div>

  );
};

export default NavMenu;
