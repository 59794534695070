import { useTranslation } from 'react-i18next';
import { useForm, SubmitHandler } from 'react-hook-form';

import { bemCn } from 'utils/bem-cn';
import Loader from 'components/shared/loader/Loader';
import PPError from 'pages/PartnerProgram/components/PPError/PPError';

import { usePartnerProjects } from '../hooks/usePartnerProjects';
import { usePartnerLinks } from '../hooks/usePartnerLinks';

import '../PPPartner.scss';

type TFormData = {
  projectId: number;
};

type Props = {
  onModalClose: () => void;
};

const PPPartnerNewLink = (props: Props) => {
  const {
    onModalClose,
  } = props;

  const { t } = useTranslation(undefined, { keyPrefix: 'partner-program' });
  const b = bemCn('pp-partner');

  const {
    items: projects,
  } = usePartnerProjects();

  const {
    createPartnerLink,
  } = usePartnerLinks();

  const { register, handleSubmit } = useForm<TFormData>();

  const onSubmit: SubmitHandler<TFormData> = (data) => {
    createPartnerLink(data);
    onModalClose();
  };

  if (!projects) {
    return <Loader />;
  }

  if (projects.length < 1) {
    return (
      <PPError
        message={t('errors.no-projects', 'Опция не доступна, у вас нет ни одного проекта') ?? 'Error'}
      />
    );
  }

  return (
    <div className={b('add-modal')}>
      <h3>
        {t('select-project-for-link', 'Выберите проект, в котором генерируется новая ссылка')}
      </h3>
      <form
        className={b('add-modal-form')}
        onSubmit={handleSubmit(onSubmit)}
      >
        <p className={b('add-modal-item')}>
          <label htmlFor="projectId" className={b('add-modal-label')}>
            {t('project', 'Проект')}:
          </label>
          <select
            className={b('add-modal-select')}
            id="projectId"
            {...register('projectId')}
            required
          >
            {projects?.map((project) => (
              <option
                className={b('add-modal-option')}
                key={project.id}
                value={project.id}
              >
                {project.name}
              </option>
            ))}
          </select>
        </p>

        <button
          type="submit"
          className={b('add-modal-btn')}
        >
          {t('add', 'Добавить')}
        </button>
      </form>
    </div>
  );
};

export default PPPartnerNewLink;
