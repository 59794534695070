import GamesCardsWidgetMobile from 'components/shared/GamesCardsWidget/GamesCardsWidgetMobile/GamesCardsWidgetMobile';
import { useAppSelector } from 'hooks';
import { seletIsBetgameActive } from 'store/app/selectors';
import { batgamesList } from 'utils/constants';
import Adaptive from 'components/Layout/Adaptive/Adaptive';
import GamesCardsWidgetDesktop from 'components/shared/GamesCardsWidget/GamesCardsWidgetDesktop/GamesCardsWidgetDesktop';


const BetgamesWidget = () => {
  const isBetgameActive = useAppSelector(seletIsBetgameActive);

  if (!isBetgameActive) {
    return null;
  }

  const link = '/games?filter=BetGames+TV';

  return (
    <Adaptive
      mobile={(
        <GamesCardsWidgetMobile
          title="BetGames TV"
          iconColor="orange"
          iconName='coin'
          items={batgamesList}
          linkToAll={link}
          isAvailable={isBetgameActive}
        />
      )}
      desktop={(
        <GamesCardsWidgetDesktop
          title="BetGames TV"
          items={batgamesList}
          linkToAll={link}
          isAvailable={isBetgameActive}
        />
      )}
    />
  );
};

export default BetgamesWidget;
