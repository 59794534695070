import React from 'react';
import { useTranslation } from 'react-i18next';

import { bemCn } from 'utils/bem-cn';
import AppModal from 'components/shared/AppModal/AppModal';

import PasswordChangeDesktop from '../Settings/components/PasswordChange/PasswordChangeDesktop';
import PhoneChangeDesktop from '../Settings/components/PhoneChange/PhoneChangeDesktop';
import ModalsHead from '../ModalsHead/ModalsHead';
import ThemePicker from '../Settings/components/ThemePicker/ThemePicker';
import UserTimezone from '../Settings/components/UserTimezone/UserTimezone';
import SwapShowBalance from './component/SwapShowBalance/SwapShowBalance';

import './SettingsModal.scss';

type Props = {
  isOpen: boolean;
  onClose: () => void;
};

const SettingsModal = (props: Props) => {
  const { isOpen, onClose } = props;
  const { t } = useTranslation();

  const b = bemCn('settings-modal');

  return (
    <AppModal className={b()} isOpen={isOpen} onClose={onClose} >
      <ModalsHead title={t('settings.title', 'Настройки')} />
      <div className={b('wrapper')}>
        <div className={b('block', b('balance'))}>
          <div className={b('block-title')}>
            {t('settings.balance', 'Баланс')}
          </div>
          <div>
            <SwapShowBalance classTitle={b('block-text')} />
          </div>
        </div>
        <div className={b('block', b('balance'))}>
          <div className={b('block-title')}>Изменение пароля</div>
          <PasswordChangeDesktop />
        </div>
        {/* <div className={b('block', b('balance'))}>
          <PhoneChangeDesktop />
        </div> */}
        {/* <div className={b('block')}>
          <div className={b('block-title')}>
            {t('settings.site-theme', 'Цвет сайта')}
          </div>
          <div className={b('block-text')}>
            {t('settings.site-theme-description', 'Смена цветовой схемы сайта')}
          </div>
          <ThemePicker className={b('theme')} />
        </div> */}
        <div className={b('block')}>
          <div className={b('block-title')}>
            {t('settings.user-timezone')}
          </div>
          <div>
            <UserTimezone className={b('theme')} />
          </div>
        </div>
      </div>
    </AppModal>
  );
};

export default SettingsModal;
