import React from 'react';
import { useTranslation } from 'react-i18next';

import SupportIcon from 'components/shared/SupportIcon/SupportIcon';
import { useAppDispatch } from 'hooks';
import { changeActiveModal } from 'store/auth/authSlice';
import Button from 'components/shared/buttons/Button/Button';
import { bemCn } from 'utils/bem-cn';
import Logotype from 'components/shared/Logotype/Logotype';
import './HeaderNoAuthorized.scss';

const HeaderNoAuthorized = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const handleLoginClick = () => dispatch(changeActiveModal('sign-in'));
  const handleRegClick = () => dispatch(changeActiveModal('sign-up'));

  const b = bemCn('main-header-no-authorized');

  return (
    <header className={b()}>
      <div className={b('top')}>
        <Logotype />
        <div className={b('buttons-group')}>
          <Button className={b('login-btn')}
            onClick={handleLoginClick}
            variant="login"
          >
            {t('user-nav.login', 'Вход')}
          </Button>
          <Button className={b('reg-btn')}
            onClick={handleRegClick}
            animated
          >
            {t('user-nav.registration', 'Регистрация')}
          </Button>
          <SupportIcon />
        </div>
      </div>
    </header>
  );
};

export default HeaderNoAuthorized;
