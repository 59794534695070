import { useTranslation } from 'react-i18next';

import GamesCardsWidgetMobile from 'components/shared/GamesCardsWidget/GamesCardsWidgetMobile/GamesCardsWidgetMobile';
import { useAppSelector } from 'hooks';
import { handleAgtResponseError } from 'pages/GamesPage/AgtGame/AgtGame';
import { selectIsAgtActive } from 'store/app/selectors';
import { selectIsAuthenticated } from 'store/auth/selectors';
import { useGetAgtGamesQuery } from 'store/slots/slotsApi';
import Adaptive from 'components/Layout/Adaptive/Adaptive';
import GamesCardsWidgetDesktop from 'components/shared/GamesCardsWidget/GamesCardsWidgetDesktop/GamesCardsWidgetDesktop';


const AgtWidget = () => {
  const { t } = useTranslation();
  const isAuthenticated = useAppSelector(selectIsAuthenticated);
  const isAgtActive = useAppSelector(selectIsAgtActive);
  const {
    data: agtGames,
    isLoading: isAgtLoading,
    isSuccess: isAgtSuccess,
    isError: isAgtError,
    error: agtError,
  } = useGetAgtGamesQuery(undefined, { skip: !isAgtActive });

  if (!isAgtActive) {
    return null;
  }

  const link = '/games?filter=Agt';
  const gamesList = agtGames?.items?.slice(0, 20) ?? [];
  const gamesListMobile = agtGames?.items?.filter((item) => item.mobile === 1).slice(0, 20) ?? [];

  let agtErrorMessage = `${t('maintenance.gamesNotAvailable', 'Игры временно недоступны')}`;
  if (!isAuthenticated) {
    agtErrorMessage = `${t('games.error.not-auth', 'Раздел доступен только авторизованным пользователям')}`;
  } else if (isAgtError) {
    agtErrorMessage = handleAgtResponseError(agtError);
  }

  return (
    <Adaptive
      mobile={(
        <GamesCardsWidgetMobile
          title="Agt games"
          iconColor="dark-blue"
          iconName='coin-flat'
          itemsCounter={agtGames?.items?.length}
          items={gamesListMobile}
          linkToAll={link}
          isLoading={isAgtLoading || !isAgtSuccess}
          isAvailable={isAgtActive && isAuthenticated && !isAgtError}
          notAvailableMessage={agtErrorMessage}
        />
      )}
      desktop={(
        <GamesCardsWidgetDesktop
          title="Agt games"
          items={gamesList}
          linkToAll={link}
          isAvailable={isAgtActive && isAuthenticated && !isAgtError}
          notAvailableMessage={agtErrorMessage}
        />
      )}
    />
  );
};

export default AgtWidget;
