import React from 'react';
import { useTranslation } from 'react-i18next';

import Breadcrumbs from 'components/shared/Breadcrumbs/Breadcrumbs';
import { bemCn } from 'utils/bem-cn';

import ThemePicker from './components/ThemePicker/ThemePicker';
import PasswordChange from './components/PasswordChange/PasswordChange';
import PhoneChange from './components/PhoneChange/PhoneChange';
import UserTimezone from './components/UserTimezone/UserTimezone';

import './Settings.scss';

const Settings = () => {
  const { t } = useTranslation();
  const b = bemCn('profile-settings');

  return (
    <div className={b()}>
      <Breadcrumbs link="/profile" title={t('main.nav.Profile', 'Личный кабинет')} />
      <h2 className={b('title')}>
        {t('settings.edit-profile', 'Редактировать профиль')}
      </h2>
      <div className={b('section')}>
        <PasswordChange />
      </div>

      {/* <div className={b('section')}>
        <PhoneChange />
      </div> */}

      {/* <hr className={b('separator')} />

      <div className={b('section')}>
        <h3 className={b('section-title')}>
          {t('settings.site-theme', 'Цвет сайта')}
        </h3>
        <ThemePicker />
      </div> */}

      <div className={b('section')}>
        <h3 className={b('section-title')}>
          {t('settings.user-timezone', 'Часовой пояс')}
        </h3>
        <UserTimezone />
      </div>

    </div>
  );
};

export default Settings;
