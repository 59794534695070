import React from 'react';
import { useTranslation } from 'react-i18next';

import Loader from 'components/shared/loader/Loader';
import { useFilteredEventsList } from 'hooks/useFilteredEventsList';
import { useAppSelector } from 'hooks';
import { seletIsLineActive } from 'store/app/selectors';
import { bemCn } from 'utils/bem-cn';
import { sortByTopEntries } from 'utils/common';
import { useGetBasketQuery } from 'store/basket/basketApi';
import { useGetFavoriteMatchesQuery } from 'store/user/userApi';
import { selectIsAuthenticated } from 'store/auth/selectors';

import DesktopWidgetItem from '../DesktopWidgetItem/DesktopWidgetItem';

import './DesktopListWidget.scss';

import type { LineType } from 'types/line-data';

type Props = {
  type: LineType;
  activeSport: string;
  isLoading?: string;
  className?: string;
};

const b = bemCn('desktop-list-widget');
const DesktopListWidget = ({ activeSport, type, isLoading, className }: Props) => {
  const { t, i18n } = useTranslation();
  const isAuth = useAppSelector(selectIsAuthenticated);
  const { data: favoriteEvents = [] } = useGetFavoriteMatchesQuery(undefined, { skip: !isAuth });
  const favoriteEventsIds = favoriteEvents.map((event) => event.matchId);
  const { basketByGameId } = useGetBasketQuery(undefined, {
    skip: !isAuth,
    selectFromResult: ({ data }) => ({
      basketByGameId: data?.itemsByGameId
    })
  });
  const isLineActive = useAppSelector(seletIsLineActive);
  const { data: events = [], isFetching } = useFilteredEventsList(
    {
      sportId: activeSport,
      dataType: type,
      pageLength: 50,
      dataLang: i18n.resolvedLanguage
    },
    { skip: !isLineActive }
  );

  const sortedEvents = sortByTopEntries(events, favoriteEventsIds, ({ id }) => id);

  if (isLoading || isFetching || !events) {
    return <Loader />;
  }

  return (
    <div className={b(null, className)}>
      <div className={b('top')}>
        <p className={b('timer')}>
          {t('top-events-widget.date-time', 'Время')}
        </p>
        <p className={b('teams')}>
          {t('top-events-widget.teams', 'Команды')}
        </p>
        <div className={b('bets')}>
          <span className={b('bets-item')}>1</span>
          <span className={b('bets-item')}>X</span>
          <span className={b('bets-item')}>2</span>
        </div>
      </div>
      <div className={b('events')}>
        {sortedEvents?.slice(0, 7).map((event) => {
          const favoriteId = favoriteEvents.find(({ matchId }) => matchId === event.id)?.id;
          return (
            <DesktopWidgetItem
              className={b('event')}
              key={event.id}
              lineType={type}
              event={event}
              basketItem={basketByGameId?.[event.id]?.basketId}
              isFavorite={favoriteId !== undefined}
              favoriteId={favoriteId ?? 'notFavoried'}
            />
          );
        })}
      </div>
    </div>
  );
};

export default DesktopListWidget;
