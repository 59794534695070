import React, { useContext } from 'react';
import cn from 'classnames';

import ThemeContext from 'contexts/ThemeContext';

import SvgIcon from '../SvgIcon/SvgIcon';

import type { SiteSection } from 'types/siteSections';
import './SectionIcon.scss';

type Props = {
  id: SiteSection;
  isBackgroundShowing: boolean;
  variant?: 'rounded-shadow';
  className?: string;
};

const iconName: Record<SiteSection, string> = {
  home: 'home',
  live: 'live',
  basket: 'cart',
  line: 'desktop',
  'top-line': 'fire',
  casino: 'coin'
};

const defaultIcon = 'home';

const SectionIcon = ({ id, isBackgroundShowing, variant, className }: Props) => {
  const { theme } = useContext(ThemeContext);
  const bgVariant = theme === 'light-blue' ? 'blue' : id;

  const icon = iconName[id] || defaultIcon;

  return (
    <div className={cn(
      'section-icon__wrapper',
      variant && `section-icon__wrapper--${variant}`,
      isBackgroundShowing && `section-icon__wrapper--${bgVariant}`,
      className,
    )}
    >
      <SvgIcon className={cn('section-icon__icon', { 'section-icon__icon--active': isBackgroundShowing })}
        name={icon}
        width={15} height={15}
      />
    </div>
  );
};

export default SectionIcon;
