import React from 'react';
import { useTranslation } from 'react-i18next';

import CoefDisplay from 'components/line/components/CoefDisplay/CoefDisplay';
import { getCoefTitleFull } from 'utils/line';
import { ReactComponent as WinIcon } from 'assets/bets/win.svg';
import { ReactComponent as LossIcon } from 'assets/bets/defeat.svg';
import { ReactComponent as PlacedIcon } from 'assets/bets/inProcess.svg';
import { getDateTimeCommaFormat } from 'utils/time';
import { bemCn } from 'utils/bem-cn';

import type { BetInfo } from 'types/user-data';
import './CouponDetailedItem.scss';

const BetStatusIcon = ({ betStatus }: { betStatus: string }) => {
  switch (betStatus) {
    case 'win':
      return <WinIcon />;
    case 'loss':
      return <LossIcon />;
    case 'no_result':
    case 'placed':
      return <PlacedIcon />;
  }
  return null;
};

type Props = {
  bet: BetInfo;
  isOrdinar: boolean;
};

const CouponDetailedItem = ({ bet, isOrdinar }: Props) => {
  const { t } = useTranslation();

  const b = bemCn('coupon-detailed-item');

  return (
    <div className={b()}>
      <div className={b('content')}>
        <div className={b('row')}>
          <p className={b('tournament')}>
            {bet.sportName && `${bet.sportName} • `}
            {bet.tournament}
          </p>
          <p className={b('tournament')}>
            {getDateTimeCommaFormat(bet.gameDatetime)}
          </p>
        </div>

        <div className={b('row')}>
          <p className={b('teams')}>{bet.team1} - {bet.team2}</p>
        </div>
        <div className={b('row')}>
          <p className={b('teams', { 'not-bold': true })}>
            {t('bet-history.bet-score', 'Поставлено при счете')}
          </p>
          <p className={b('score')}>{bet.betScore || '0:0'}</p>
        </div>
        {bet.gameScore && (
          <div className={b('row')}>
            <p className={b('teams', { 'not-bold': true })}>
              {t('bet-history.final-score', 'Итоговый счёт')}
            </p>
            <p className={b('score')}>{bet.gameScore}</p>
          </div>
        )}
        {bet.subGameName && bet.subGameName.length > 0 && (
          <div className={b('row')}>
            <p>{bet.subGameName}:</p>
          </div>
        )}
        <div className={b('row')}>
          <p className={b('bet-title')}>
            {getCoefTitleFull(bet.outcomeName, bet.team1, bet.team2)}
          </p>
          {!isOrdinar && (
            <div className={b('row')} style={{ gap: 5 }}>
              <BetStatusIcon betStatus={bet.status} />
              <CoefDisplay value={bet.coef.toFixed(2)} />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default CouponDetailedItem;
