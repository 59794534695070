import React from 'react';
import { useTranslation } from 'react-i18next';


import { seletIsLineActive } from 'store/app/selectors';
import { useAppSelector } from 'hooks';

import DesktopTypeWidget from './components/DesktopTypeWidget/DesktopTypeWidget';
import './DesktopLineWidgets.scss';

const DesktopLineWidgets = () => {
  const { t } = useTranslation();
  const isLineActive = useAppSelector(seletIsLineActive);

  return (
    <div className="desktop-line-widgets">
      <DesktopTypeWidget
        id='live'
        title={t('top-events-widget.top-live', 'Топ live матч')}
        link='/live'
        lineType='live'
        isAvailable={isLineActive}
        notAvailableMessage={`${t('maintenance.eventsNotAvailable', 'Спортивные события временно недоступны')}`}
      />
      <DesktopTypeWidget
        id='top-line'
        title={t('top-events-widget.top-line', 'Топ линия матч')}
        link='/line'
        lineType='line'
        isAvailable={isLineActive}
        notAvailableMessage={`${t('maintenance.eventsNotAvailable', 'Спортивные события временно недоступны')}`}
      />
    </div>
  );
};

export default DesktopLineWidgets;
