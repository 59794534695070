import { snakeToCamelCase } from 'utils/transformCase';
import { roundAmount } from 'utils/common';

import type { FavoriteTournamentRes, FavoriteTournaments, FavoriteTournamentsRes, BetInfo, Coupon, ServerBetInfo, ServerCoupon, ServerCoupons, ServerWallet, ServerWithdrawal, Transaction, TransactionServer, TransactionShort, TransactionShortServer, TransactionsList, TransactionsListServer, TransactionsShortList, TransactionsShortListServer, TransactionsWidthrawal, UserSettings, UserSettingsServer, Wallet, Withdrawal, WithdrawalCollection, AddFavoriteTournamentBody, AddFavoriteTournamentReqBody, AddFavoriteMatchBody, AddFavoriteMatchReq, FavoriteMatchesRes, FavoriteMatches } from 'types/user-data';

export const transformWithdrawals = (response: ServerWithdrawal): WithdrawalCollection =>
  response.items.reduce<WithdrawalCollection>((acc, item) => ({
    ...acc,
    [item.transaction_id]: snakeToCamelCase(item),
  }), {});

export const transformWithdrawalResponse = (response: ServerWithdrawal): TransactionsWidthrawal => {
  const items = response.items.map((item) =>
    snakeToCamelCase({ ...item, transactionId: item.transaction_id })
  );

  const result = {
    count: response.count,
    items: items as Withdrawal[],
  };

  return result;
};

const transformCouponBet = (bet: ServerBetInfo): BetInfo => ({
  updated: bet.updated,
  barcode: bet.barcode,
  tournament: bet.metainfo.tournament_name,
  team1: bet.metainfo.team1,
  team2: bet.metainfo.team2,
  outcomeName: bet.metainfo.outcome_name,
  subGameName: bet.metainfo.sub_game_name,
  coef: bet.rate,
  status: bet.status,
  betScore: bet.metainfo.bet_score,
  gameScore: bet.metainfo.game_score,
  gameDatetime: bet.metainfo.game_date,
  sportName: bet.metainfo.sport_name,
});

export const transformWallets = (response: ServerWallet[]): Wallet[] =>
  response.map((wallet) => ({
    isPrimary: wallet.is_primary,
    currency: wallet.currency,
    amount: wallet.amount,
    bonusAmount: wallet.bonus_amount,
    cashbackLine: wallet.cashback_line ?? 0,
    cashbackCasino: wallet.cashback_casino ?? 0,
    maxBetAmount: wallet.max_bet_amount ?? 0,
    subGamesLimits: wallet.sub_games_limits,
  }));

export const transformCoupon = (res: ServerCoupon): Coupon => ({
  id: res.id,
  created: res.created,
  userId: res.user_id,
  type: res.type,
  currency: res.currency,
  status: res.status,
  betAmount: res.bet_amount,
  winAmount: res.win_amount,
  isLive: res.is_live,
  coef: res.rate,
  bets: res.bets.map(transformCouponBet) ?? [],
  expectedWinAmount: roundAmount(res.bet_amount * res.rate),
  balanceType: res.balance_type,
});

export const transfortCoupons = (response: ServerCoupons): { totalCount: number; items: Coupon[] } => ({
  totalCount: response.count,
  items: response.items.map(transformCoupon)
});

export const transfromTransaction = (res: TransactionServer): Transaction => ({
  id: res.id, // * было описано
  created: res.created, // * было описано
  userFrom: res.user_from, // * было описано
  userTo: res.user_to, // * было описано
  userFromLevel: res.user_from_level,
  userToLevel: res.user_to_level,
  userFromNote: res.user_from_note,
  userToNote: res.user_to_note,
  type: res.type, // * было описано
  amount: res.amount, // * было описано
  currency: res.currency, // * было описано
  balanceAfterFrom: res.balance_after_from, // * было описано
  balanceAfterTo: res.balance_after_to, // * было описано
});

export const transformTransactionsList = (res: TransactionsListServer): TransactionsList => ({
  count: res.count,
  items: res.items.map(transfromTransaction),
});

export const transformTransactionShort = (res: TransactionShortServer): TransactionShort => ({
  id: res.id,
  created: res.created,
  userId: res.user_id,
  userLevel: res.user_level,
  userNote: res.user_note,
  type: res.type,
  amount: res.amount,
  currency: res.currency,
  balanceAfter: res.balance_after,
});

export const transformTransactionsListShort = (res: TransactionsShortListServer): TransactionsShortList => ({
  count: res.count,
  items: res.items.map(transformTransactionShort),
});

export const UserAdapter = {
  settingsToClient: (settings: UserSettingsServer): UserSettings => ({
    colorScheme: settings.color_scheme,
    timezone: settings.timezone,
    lang: settings.lang,
    balanceType: settings.balance_type,
  }),
  settingsToServer: (settings: UserSettings): UserSettingsServer => ({
    'color_scheme': settings.colorScheme,
    'timezone': settings.timezone,
    'lang': settings.lang,
    'balance_type': settings.balanceType,
  })
};

export const transfromFavoriteTournaments = (res: FavoriteTournamentsRes): FavoriteTournaments => (
  res.map((favTournament: FavoriteTournamentRes) => ({
    'tournamentId': `${favTournament.tournament_id}`,
    'tournamentName': favTournament.tournament_name,
    'sportId': `${favTournament.sport_id}`,
    'sportName': favTournament.sport_name,
    'countryId': `${favTournament.country_id}`,
    'countryName': favTournament.country_name,
    'id': `${favTournament.id}`
  })
  )
);

export const transformAddFavoriteTournamentBody = (body: AddFavoriteTournamentBody): AddFavoriteTournamentReqBody => ({
  'tournament_id': body.tournamentId,
  'tournament_name': body.tournamentName,
  'sport_id': body.sportId,
  'sport_name': body.sportName,
  'country_id': body.countryId,
  'country_name': body.countryName
});

export const transformAddFavoriteMatchBody = (body: AddFavoriteMatchBody): AddFavoriteMatchReq => ({
  'match_id': Number(body.matchId),
  'team_1': body.team1,
  'team_2': body.team2,
  'sport_name': body.sportName,
  'sport_id': Number(body.sportId),
  'tournament_name': body.tournamentName,
  'tournament_id': Number(body.tournamentId),
  'country_id': Number(body.countryId),
  'country_name': body.countryName,
  'data_type': body.dataType
});

export const transformGetFavoriteMatchesRes = (res: FavoriteMatchesRes): FavoriteMatches => res.map((FavoriteMatch) => ({
  'matchId': String(FavoriteMatch.match_id),
  'team1': FavoriteMatch.team_1,
  'team2': FavoriteMatch.team_2,
  'sportName': FavoriteMatch.sport_name,
  'sportId': String(FavoriteMatch.sport_id),
  'tournamentName': FavoriteMatch.tournament_name,
  'tournamentId': String(FavoriteMatch.tournament_id),
  'countryId': String(FavoriteMatch.country_id),
  'countryName': FavoriteMatch.country_name,
  'dataType': FavoriteMatch.data_type,
  'id': String(FavoriteMatch.id)
}));
