import React from 'react';

import Breadcrumbs from 'components/shared/Breadcrumbs/Breadcrumbs';
import TextContent from 'components/shared/TextContent/TextContent';
import { bemCn } from 'utils/bem-cn';

import img from './assets/01.jpg';

import './PartnersPage.scss';

const b = bemCn('partners-page');

const PartnersPage = () => (
  <div className={b()}>
    <Breadcrumbs title="На главную" link="/" />
    <TextContent className={b('content')}>
      <h1>Партнерская программа RYSEBET.COM</h1>
      <ul>
        <li>Новый конкурентный игрок на рынке</li>
        <li>Быстро растущая компания</li>
        <li><b>40% Rev Share</b></li>
        <li>Большой выбор промо-материалов</li>
        <li>Хорошие условия для менеджеров</li>
        <li>Регулярные выплаты</li>
      </ul>
      <p>
        По вопросам сотрудничества пишите на почту: <b><a href="mailto:support@rysebet.com" className='static-pages__link' rel="noreferrer">support@rysebet.com</a></b>
      </p>
      <p>
        Или в телеграм <b><a href="https://t.me/rysebet_operator" target="_blank" className='static-pages__link' rel="noreferrer">https://t.me/rysebet_operator</a></b>
      </p>

      <img src={img} alt="" />
    </TextContent>
  </div>
);

export default PartnersPage;
