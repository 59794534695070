import i18n from 'i18n/i18n';

export const statsTitleList = {
  numClicks: i18n.t('stats.titles.numClicks', 'Переходы'),
  numRegistrations: i18n.t('stats.titles.numRegistrations', 'Регистрации'),
  deposits: i18n.t('stats.titles.deposits', 'Пополнения'),
  adminDeposits: i18n.t('stats.titles.adminDeposits', 'Пополнения администратором'),
  firstDeposits: i18n.t('stats.titles.firstDeposits', 'Первые депозиты'),
  betsPlaced: i18n.t('stats.titles.betsPlaced', 'Сумма ставок'),
  betsWinnings: i18n.t('stats.titles.betsWinnings', 'Выигрыш'),
  betsLosses: i18n.t('stats.titles.betsLosses', 'Проигрыш'),
  betsIngame: i18n.t('stats.titles.betsIngame', 'Не рассчитано'),
  betsIncome: i18n.t('stats.titles.betsIncome', 'Прибыль ставки'),

  inbetPlaced: i18n.t('stats.titles.inbetPlaced', 'Ставки Inbet'),
  inbetWinnings: i18n.t('stats.titles.inbetWinnings', 'Выигрыш Inbet'),
  inbetIncome: i18n.t('stats.titles.inbetIncome', 'Прибыль Inbet'),
  betgamesPlaced: i18n.t('stats.titles.betgamesPlaced', 'Ставки Betgames'),
  betgamesWinnings: i18n.t('stats.titles.betgamesWinnings', 'Выигрыш Betgames'),
  betgamesIncome: i18n.t('stats.titles.betgamesIncome', 'Прибыль Betgames'),
  vegasPlaced: i18n.t('stats.titles.vegasPlaced', 'Ставки Vegas'),
  vegasWinnings: i18n.t('stats.titles.vegasWinnings', 'Выигрыш Vegas'),
  vegasIncome: i18n.t('stats.titles.vegasIncome', 'Прибыль Vegas'),
  pragmaticPlaced: i18n.t('stats.titles.pragmaticPlaced', 'Ставки Pragmatic'),
  pragmaticWinnings: i18n.t('stats.titles.pragmaticWinnings', 'Выигрыш Pragmatic'),
  pragmaticIncome: i18n.t('stats.titles.pragmaticIncome', 'Прибыль Pragmatic'),
  tvbetPlaced: i18n.t('stats.titles.tvbetPlaced', 'Ставки TVbet'),
  tvbetWinnings: i18n.t('stats.titles.tvbetWinnings', 'Выигрыш TVbet'),
  tvbetIncome: i18n.t('stats.titles.tvbetIncome', 'Прибыль TVbet'),
  mascotPlaced: i18n.t('stats.titles.mascotPlaced', 'Ставки Mascot'),
  mascotWinnings: i18n.t('stats.titles.mascotWinnings', 'Выигрыш Mascot'),
  mascotIncome: i18n.t('stats.titles.mascotIncome', 'Прибыль Mascot'),
  agtPlaced: i18n.t('stats.titles.agtPlaced', 'Ставки AGT'),
  agtWinnings: i18n.t('stats.titles.agtWinnings', 'Выигрыш AGT'),
  agtIncome: i18n.t('stats.titles.agtIncome', 'Прибыль AGT'),

  income: i18n.t('stats.titles.income', 'Общий доход по балансам'),
  revshare: i18n.t('stats.titles.revshare', 'Личный доход'),
  currentIncome: i18n.t('stats.titles.currentIncome', 'Текущий партнёрский баланс'),

  playerId: i18n.t('stats.titles.playerId', 'ID реферала'),
  playerName: i18n.t('stats.titles.playerName', 'Имя реферала'),
};

export const defaultPartnerCurrency = 'RUB';
