import { ClassNameFormatter } from '@bem-react/classname';

const lagnLabel: Record<string, string> = {
  'uk': 'ua',
  'kk': 'kz'
};

type Props = {
  onClick: () => void;
  title: string;
  icon: string;
  className?: string;
  b: ClassNameFormatter;
}

const SiteSettingsLangBtn = (props: Props) => {
  const { title, icon, onClick, className, b } = props;
  const label = lagnLabel[title] ?? title;
  return (
    <button
      className={b('button', { lang: true }, className)}
      type="button"
      onClick={onClick}
    >
      {label.toLocaleUpperCase()}
      <img className={b('button-icon')} src={icon} alt='' width="15" height="15" />
    </button>
  );
};

export default SiteSettingsLangBtn;
