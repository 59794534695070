import GamesCardsWidgetMobile from 'components/shared/GamesCardsWidget/GamesCardsWidgetMobile/GamesCardsWidgetMobile';
import { useAppSelector } from 'hooks';
import { selectIsTvbetActive } from 'store/app/selectors';
import { tvbetList } from 'utils/constants';
import Adaptive from 'components/Layout/Adaptive/Adaptive';
import GamesCardsWidgetDesktop from 'components/shared/GamesCardsWidget/GamesCardsWidgetDesktop/GamesCardsWidgetDesktop';


const TVBetWidget = () => {
  const isTvbetActive = useAppSelector(selectIsTvbetActive);

  if (!isTvbetActive) {
    return null;
  }

  const link = '/games?filter=TVBet';
  const gamesList = tvbetList.slice(0, 20) ?? [];

  return (
    <Adaptive
      mobile={(
        <GamesCardsWidgetMobile
          title="TVBet"
          iconColor="purple"
          iconName='desktop'
          items={gamesList}
          linkToAll={link}
          isAvailable={isTvbetActive}
        />
      )}
      desktop={(
        <GamesCardsWidgetDesktop
          title="TVBet"
          items={gamesList}
          linkToAll={link}
          isAvailable={isTvbetActive}
        />
      )}
    />
  );
};

export default TVBetWidget;
