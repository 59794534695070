import GamesCardsWidgetMobile from 'components/shared/GamesCardsWidget/GamesCardsWidgetMobile/GamesCardsWidgetMobile';
import { useAppSelector } from 'hooks';
import { selectIsMascotActive } from 'store/app/selectors';
import { useGetMascotGamesQuery } from 'store/slots/slotsApi';
import Adaptive from 'components/Layout/Adaptive/Adaptive';
import GamesCardsWidgetDesktop from 'components/shared/GamesCardsWidget/GamesCardsWidgetDesktop/GamesCardsWidgetDesktop';


const MascotWidget = () => {
  const isMascotActive = useAppSelector(selectIsMascotActive);
  const {
    data: mascotGames,
    isLoading: isMascotLoading,
    isSuccess: isMascotSuccess,
  } = useGetMascotGamesQuery(undefined, { skip: !isMascotActive });

  if (!isMascotActive) {
    return null;
  }

  const link = '/games?filter=Mascot';
  const gamesList = mascotGames?.items?.slice(0, 20) ?? [];

  return (
    <Adaptive
      mobile={(
        <GamesCardsWidgetMobile
          title="Mascot"
          iconColor="purple"
          iconName='games'
          itemsCounter={mascotGames?.items?.length}
          items={gamesList}
          linkToAll={link}
          isLoading={isMascotLoading || !isMascotSuccess}
          isAvailable={isMascotActive}
        />
      )}
      desktop={(
        <GamesCardsWidgetDesktop
          title="Mascot"
          items={gamesList}
          linkToAll={link}
          isAvailable={isMascotActive}
        />
      )}
    />
  );
};

export default MascotWidget;
