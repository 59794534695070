import { ClassNameFormatter } from '@bem-react/classname';

import logoVisa from '../assets/payments/logo-visa-mono.svg';
import logoTether from '../assets/payments/logo-tether-mono.svg';
import logoMastercard from '../assets/payments/logo-mastercard-mono.svg';
import logoGooglePay from '../assets/payments/logo-google-pay-mono.svg';
import logoBitcoin from '../assets/payments/logo-bitcoin-mono.svg';
import logoApplepay from '../assets/payments/logo-apple-pay-mono.svg';

const footerPayments = [
  {
    image: logoVisa,
    name: 'VISA'
  },
  {
    image: logoMastercard,
    name: 'Mastercard'
  },
  {
    image: logoGooglePay,
    name: 'Google Pay'
  },
  {
    image: logoApplepay,
    name: 'Apple Pay'
  },
  {
    image: logoBitcoin,
    name: 'Bitcoin'
  },
  {
    image: logoTether,
    name: 'Tether'
  },
];

type Props = {
  b: ClassNameFormatter;
}

const PageFooterPayments = ({ b }: Props) => (
  <ul className={b('payments')}>
    {footerPayments.map((paymentsItem) => (
      <li key={`${paymentsItem.name}-${paymentsItem.image}`} className={b('payments-item')}>
        <img className={b('payments-logo')} src={paymentsItem.image} alt={paymentsItem.name} />
      </li>
    ))}
  </ul>
);

export default PageFooterPayments;
