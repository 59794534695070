import React, { useEffect } from 'react';
import { useToggle } from 'usehooks-ts';

import SvgIcon from 'components/shared/SvgIcon/SvgIcon';
import { bemCn } from 'utils/bem-cn';

import BetsItem from '../EventCardBetsItem/EventCardBetsItem';

import type { Bet, Group } from 'types/line-data';

import './EventCardBetsGroup.scss';

type Props = {
  group: Group;
  isAllClosed: boolean;
  className?: string;
  onBetClick: (bet: Bet, betGroup: string) => void;
  basketItemBetId: string | undefined;
}

const EventCardBetsGroup = (props: Props) => {
  const { group, isAllClosed, className, onBetClick, basketItemBetId } = props;
  const [isClosed, toggleClosed, setClosed] = useToggle(isAllClosed);
  useEffect(() => {
    if (isAllClosed !== isClosed) {
      setClosed(isAllClosed);
    }
  }, [isAllClosed]);

  const b = bemCn('event-card-bets-group');

  return (
    <div className={b({ closed: isClosed }, className)}>
      <button className={b('title')} type='button' onClick={toggleClosed}>
        {group.name}
        <SvgIcon className={b('toggle-icon')}
          name='chevron-down'
          width={10}
          height={10}
        />
      </button>
      <div className={b('list')}>
        {group.bets.map((betItem) => (
          <BetsItem className={b('item')}
            key={betItem.betId}
            bet={betItem}
            isActive={basketItemBetId === betItem.betId}
            onClick={() => onBetClick(betItem, group.name)}
            isBlocked={betItem.blocked}
          />
        ))}
      </div>
    </div >
  );
};


export default React.memo(EventCardBetsGroup);
