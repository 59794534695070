import React, { useEffect } from 'react';
import { Navigate, Route, Routes, useSearchParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import HomePage from 'pages/Home/Home';
import { AppRoute } from 'utils/routes';
import { useAppSelector } from 'hooks';
import { selectIsAuthenticated } from 'store/auth/selectors';
import { useGetUserAccountQuery, useGetUserCountryQuery } from 'store/user/userApi';
import NotFounded from 'pages/NotFound/NotFound';
import Profile from 'pages/Profile/Profile';
import VerifyEmail from 'pages/VerifyEmail/VerifyEmail';
import ResetPassword from 'pages/ResetPassword/ResetPassword';
import LivePage from 'pages/LivePage/LivePage';
import LinePage from 'pages/LinePage/LinePage';
import BonusPage from 'pages/StaticPages/BonusPage/BonusPage';
import RulesPage from 'pages/StaticPages/RulesPage/RulesPage';
// import SlotsPages from 'pages/Slots/SlotsPages';
import ProtectedRoute from 'components/shared/ProtectedRoute/ProtectedRoute';
import TermsPage from 'pages/StaticPages/TermsPage/TermsPage';
import Basket from 'components/basket/Basket/Basket';
import Layout from 'components/Layout/Layout';
import EventPage from 'pages/EventPage/EventPage';
import BetHistoryPage from 'pages/BetHistoryPage/BetHistoryPage';
import ReferralLogin from 'components/ReferralLogin/ReferralLogin';
import GamesPage from 'pages/GamesPage/GamesPage';
import { selectIsSystemActive, selectSystemStatus } from 'store/app/selectors';
import SiteMaintenance from 'pages/SiteMaintenance/SiteMaintenance';
import { useGetAppStatusQuery } from 'store/app/appApi';
import ClickLogin from 'components/ClickLogin/ClickLogin';
import { Status } from 'types/auth-data';
import { useClickLogin } from 'hooks/useClickLogin';
import PrivacyPage from 'pages/StaticPages/PrivacyPage/PrivacyPage';
import PartnersPage from 'pages/StaticPages/Partners/PartnersPage';
import './App.scss';
import PartnerProgram from 'pages/PartnerProgram/PartnerProgram';

const updateWindowDimensions = () => {
  const doc = document.documentElement;
  doc.style.setProperty('--app-height', `${window.innerHeight}px`);
};

const App = () => {
  const { isLoading: isStatusLoading } = useGetAppStatusQuery();
  const { i18n } = useTranslation();
  const appStatus = useAppSelector(selectSystemStatus);
  const isAppActive = useAppSelector(selectIsSystemActive);
  const isAuthenticated = useAppSelector(selectIsAuthenticated);

  const { isLoading: isAccountLoading } = useGetUserAccountQuery(undefined, { skip: !isAppActive });
  const { isLoading: isCountryLoading } = useGetUserCountryQuery();
  const [searchParams] = useSearchParams();
  useClickLogin(searchParams.get('click_id'), searchParams.get('web_id'));

  useEffect(() => undefined, [i18n.resolvedLanguage]);
  useEffect(() => {
    updateWindowDimensions();
    window.addEventListener('resize', updateWindowDimensions);
    return () => window.removeEventListener('resize', updateWindowDimensions);
  }, []);

  if (isStatusLoading) {
    return <div>Loading...</div>;
  }

  if (!isStatusLoading && appStatus !== Status.Active) {
    return <SiteMaintenance />;
  }

  return (
    <div className="app" id="app">
      <Layout
        isAuthenticated={isAuthenticated}
        isAccountLoading={isAccountLoading}
      >
        <Routes>
          <Route index element={<Navigate to={AppRoute.Home} replace />} />
          <Route path={AppRoute.Home} element={
            <HomePage
              isAuthenticated={isAuthenticated}
              isAccountLoading={isAccountLoading}
            />
          }
          />
          <Route path={AppRoute.LivePage}
            element={<LivePage />}
          />
          <Route path={AppRoute.LinePage}
            element={<LinePage />}
          />
          <Route path={AppRoute.FulleventPage}
            element={<EventPage />}
          />
          <Route path={AppRoute.BetHistory} element={<BetHistoryPage />} />
          <Route path={AppRoute.Basket} element={<Basket />} />
          {/* <Route path={AppRoute.SlotsPage} element={<SlotsPages />} /> */}
          <Route path={AppRoute.VerifyEmail} element={<VerifyEmail />} />
          <Route path={AppRoute.ResetPassword} element={<ResetPassword />} />
          <Route path={AppRoute.ProfilePage}
            element={
              <ProtectedRoute isAccountLoading={isAccountLoading} isAuthenticated={isAuthenticated}>
                <Profile />
              </ProtectedRoute>
            }
          />
          <Route path={AppRoute.Terms} element={<TermsPage />} />
          <Route path={AppRoute.Privacy} element={<PrivacyPage />} />
          <Route path={AppRoute.Partners} element={<PartnersPage />} />
          <Route path={AppRoute.BonusPage} element={<BonusPage />} />
          <Route path={AppRoute.RulesPage} element={<RulesPage />} />
          <Route path={AppRoute.Referal} element={<ReferralLogin isAuthenticated={isAuthenticated} />} />
          <Route path="/r_:shortCode" element={<ReferralLogin isAuthenticated={isAuthenticated} />} />
          <Route path="/click_id=:clickId&web_id=:webId"
            element={<ClickLogin />}
          />
          <Route path="/click_id=:clickId"
            element={<ClickLogin />}
          />
          <Route path="/games/*" element={<GamesPage />} />
          <Route path="/partner-program/*" element={<PartnerProgram />} />
          <Route path={AppRoute.NotFound} element={<NotFounded />} />
        </Routes>
      </Layout>
    </div>
  );
};

export default App;
