import { ClassNameFormatter } from '@bem-react/classname';

import logoUfc from '../assets/sport-organisations/logo-ufc.svg';
import logoUefa from '../assets/sport-organisations/logo-uefa.svg';
import logoAtp from '../assets/sport-organisations/logo-atp.svg';
import logoNba from '../assets/sport-organisations/logo-nba.svg';
import logoFifa from '../assets/sport-organisations/logo-fifa.svg';
import logoNhl from '../assets/sport-organisations/logo-nhl.svg';

const footerSportOrganisations = [
  {
    image: logoUfc,
    name: 'UFC',
  },
  {
    image: logoUefa,
    name: 'UEFA',
  },
  {
    image: logoAtp,
    name: 'ATP',
  },
  {
    image: logoNba,
    name: 'NBA',
  },
  {
    image: logoFifa,
    name: 'FIFA',
  },
  {
    image: logoNhl,
    name: 'NHL',
  },
];

type Props = {
  b: ClassNameFormatter;
}

const PageFooterSportOrganisations = ({ b }: Props) => (
  <ul className={b('sport-organisations')}>
    {footerSportOrganisations.map(({ name, image }) => (
      <li key={`${name}-${image}`} className={b('sport-organisations-item')}>
        <img src={image} alt={name} className={b('sport-organisations-img')} />
      </li>
    ))}
  </ul>
);

export default PageFooterSportOrganisations;
