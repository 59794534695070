import i18n from 'i18next';
import { initReactI18next, } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';

import ru from './ru.json';
import uk from './uk.json';
import kz from './kz.json';
// import en from './en.json';

const resources = {
  ru: {
    translation: ru
  },
  uk: {
    translation: uk
  },
  kk: {
    translation: kz
  },
};


i18n
  .use(LanguageDetector)
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    fallbackLng: 'ru',
    resources,
    debug: true,
    interpolation: {
      escapeValue: false
    }
  });

export default i18n;
