import React from 'react';

import SystemNotification from 'components/SystemNotification/SystemNotification';
import BasketPopupButton from 'components/basket/BusketPopupButton/BusketPopupButton';

import ScrollToTop from './ScrollToTop/ScrollToTop';
import Container from './Container/Container';
import Header from './Header/Header';
import PageFooter from './PageFooter/PageFooter';
import TabBar from './TabBar/TabBar';
import AppModals from './AppModals/AppModals';

type Props = {
  children: React.ReactNode;
  isAuthenticated: boolean;
  isAccountLoading: boolean;
};

export const Layout = (props: Props) => (
  <>
    <ScrollToTop />
    <Header
      isAccountLoading={props.isAccountLoading}
      isAuthenticated={props.isAuthenticated}
    />
    <SystemNotification />
    <Container>
      {props.children}
    </Container>
    <PageFooter />
    <TabBar />
    <BasketPopupButton />
    <AppModals />
  </>
);

export default React.memo(Layout);
