import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { bemCn } from 'utils/bem-cn';
import Button from 'components/shared/buttons/Button/Button';
import { useAppDispatch, useAppSelector } from 'hooks';
import { selectTGVereficationULR } from 'store/auth/selectors';
import { clearTGVereficationUrl } from 'store/auth/authSlice';
import './ConfirmTelegram.scss';
import AppModal from 'components/shared/AppModal/AppModal';

export type Status = 'confirmation' | 'success' | 'error';

type Props = {
  isOpen: boolean;
  onClose: () => void;
  status: Status;
}

const ConfirmTelegram = (props: Props) => {
  const { isOpen, onClose, status } = props;
  const { t } = useTranslation();
  const b = bemCn('confirmation-telegram-modal');
  const dispatch = useAppDispatch();
  const vereficationUrl = useAppSelector(selectTGVereficationULR);

  useEffect(() => () => {
    dispatch(clearTGVereficationUrl());
  }, []);

  const handleModalClose = () => {
    onClose();
    dispatch(clearTGVereficationUrl());
  };

  return (
    <AppModal className={b()} isOpen={isOpen} onClose={handleModalClose} >
      <div className={b('icon', { [`${status}`]: true })} />
      <div className={b('title')}>
        {t('result-modal.confirmation.telegram.title', 'Подтверждение')}
      </div>
      <p className={b('description')}>
        {t('result-modal.confirmation.telegram.description', 'Вам необходимо подтвердить аккаунт через наш телеграм бот.')}
      </p>
      <a className={b('link')} href={vereficationUrl ?? undefined} target="_blank" rel="noreferrer">
        <Button className={b('button')}
          type='button'
          disabled={!vereficationUrl}
        >
          {t('result-modal.confirmation.telegram.btn-confirm', 'Подтвердить')}
        </Button>
      </a>
    </AppModal>
  );
};

export default ConfirmTelegram;
