import React from 'react';
import { ClassNameFormatter } from '@bem-react/classname';

import { bemCn } from 'utils/bem-cn';
import './EventCardScoreTable.scss';

type ScorePeriodProps = {
  periodScore: string;
  periodNumber: number;
  b: ClassNameFormatter;
}

const ScorePeriodItem = ({ periodScore, periodNumber, b }: ScorePeriodProps) => {
  const [periodTeam1, periodTeam2] = periodScore.split(':');
  return (
    <div className={b('column')}>
      <div className={b('cell')}>{periodNumber}</div>
      <div className={b('cell')}>{periodTeam1}</div>
      <div className={b('cell')}>{periodTeam2}</div>
    </div>
  );
};


type Props = {
  scorePeriods: string;
  team1: string;
  team2: string;
  scoreFull: string;
  periodName: string;
  className?: string;
};

const EventCardScoreTable = (props: Props) => {
  const [totalTeam1, totalTeam2] = props.scoreFull.split(':');
  const scorsByPeriods = props.scorePeriods.split(';');

  const b = bemCn('event-card-score-table');

  return (
    <div className={b(null, props.className)}>
      <div className={b('column', { head: true })}>
        <div className={b('cell')}>
          {props.periodName}
        </div>
        <div className={b('cell', { team: true })}>{props.team1}</div>
        <div className={b('cell', { team: true })}>{props.team2}</div>
      </div>
      <div className={b('column', { total: true })}>
        <div className={b('cell')}>0</div>
        <div className={b('cell')}>{totalTeam1}</div>
        <div className={b('cell')}>{totalTeam2}</div>
      </div>
      {scorsByPeriods.map((periodScore, index) => {
        const keyVal = `${periodScore}-${index}`;
        return (
          <ScorePeriodItem
            key={keyVal}
            periodScore={periodScore}
            periodNumber={index + 1}
            b={b}
          />
        );
      })}
    </div>
  );
};


export default React.memo(EventCardScoreTable);
