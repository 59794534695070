import React from 'react';

import { ReactComponent as OperatorIcon } from 'assets/paymentSystems/operator.svg';
import { ReactComponent as VisaIcon } from 'assets/paymentSystems/visa.svg';
import { ReactComponent as MastercardIcon } from 'assets/paymentSystems/mastercard-colored.svg';
import { ReactComponent as QiwiIcon } from 'assets/paymentSystems/qiwi.svg';
import { ReactComponent as WebmoneyIcon } from 'assets/paymentSystems/webmoney.svg';
import { ReactComponent as UmoneyIcon } from 'assets/paymentSystems/umoney.svg';
import { ReactComponent as SbpIcon } from 'assets/paymentSystems/sbp.svg';
import { ReactComponent as PrivatBankIcon } from 'assets/paymentSystems/privatbank.svg';
import simImg from 'assets/paymentSystems/sim12.png';
import vsibankiImg from 'assets/paymentSystems/vsi-banki.png';
import { ReactComponent as BybitImg } from 'assets/paymentSystems/bybit-logo.svg';
import payeerImg from 'assets/paymentSystems/logo-payeer.png';
import { ReactComponent as BinanceImg } from 'assets/paymentSystems/binance-logo.svg';
import paypalImg from 'assets/paymentSystems/logo-paypal.png';
import mirImg from 'assets/paymentSystems/logo-mir.png';
import { ReactComponent as Skrill } from 'assets/paymentSystems/logo-skrill.svg';
import payseraImg from 'assets/paymentSystems/1_Paysera logo for light background.png';
import westernunion from 'assets/paymentSystems/logo-western-union.png';
import advcashImg from 'assets/paymentSystems/advcash-logo.svg';
import { ReactComponent as TetherLogo } from 'assets/paymentSystems/tether-logo.svg';
import { ReactComponent as HumoLogo } from 'assets/paymentSystems/humo-logo.svg';
import { ReactComponent as SepaLogo } from 'assets/paymentSystems/sepa-logo.com.svg';
import { ReactComponent as M10Logo } from 'assets/paymentSystems/m10-logo.svg';
import { ReactComponent as BetatransferLogo } from 'assets/paymentSystems/betatransfer-logo.svg';
import uzcardImg from 'assets/paymentSystems/uzcard-logo.png';
import eripImg from 'assets/paymentSystems/erip-logo.png';
import sberPay from 'assets/paymentSystems/SberPay.png';

const iconsComponents: Record<string, (key: string) => JSX.Element> = {
  visa: (key) => <VisaIcon key={key} className="payment-method__icon payment-method__icon--visa" />,
  mastercard: (key) => <MastercardIcon key={key} className="payment-method__icon" />,
  qiwi: (key) => <QiwiIcon key={key} className="payment-method__icon" />,
  mobile: (key) => <img alt="mobile" key={key} src={simImg} className="payment-method__icon" />,
  webmoney: (key) => <WebmoneyIcon key={key} className="payment-method__icon" />,
  umoney: (key) => <UmoneyIcon key={key} className="payment-method__icon" />,
  operator: (key) => <OperatorIcon key={key} className="payment-method__icon" />,
  sbp: (key) => <SbpIcon key={key} className="payment-method__icon" />,
  privat: (key) => <PrivatBankIcon key={key} className="payment-method__icon" />,
  vsibanki: (key) => <img alt="vsibanki" key={key} src={vsibankiImg} className="payment-method__icon" />,
  bybit: (key) => <BybitImg key={key} className="payment-method__icon" />,
  payeer: (key) => <img alt="payeer" key={key} src={payeerImg} className="payment-method__icon payment-method__icon--payeer" />,
  binance: (key) => <BinanceImg key={key} className="payment-method__icon" />,
  paypal: (key) => <img alt="PayPal" key={key} src={paypalImg} className="payment-method__icon" />,
  cardmir: (key) => <img alt="Мир" key={key} src={mirImg} className="payment-method__icon payment-method__icon--mir" />,
  skrill: (key) => <Skrill key={key} className="payment-method__icon" />,
  paysera: (key) => <img alt="paysera" key={key} src={payseraImg} className="payment-method__icon" />,
  westernunion: (key) => <img alt="westernunion" key={key} src={westernunion} className="payment-method__icon" />,
  advcash: (key) => <img alt="advcash" key={key} src={advcashImg} className="payment-method__icon" />,
  tether: (key) => <TetherLogo key={key} className="payment-method__icon" />,
  humo: (key) => <HumoLogo key={key} className="payment-method__icon" />,
  sepa: (key) => <SepaLogo key={key} className="payment-method__icon" />,
  m10: (key) => <M10Logo key={key} className="payment-method__icon payment-method__icon--m10" fill="#02205F" />,
  betatransfer: (key) => <BetatransferLogo key={key} className="payment-method__icon" />,
  uzcard: (key) => <img alt="uzcard" key={key} src={uzcardImg} className="payment-method__icon" />,
  erip: (key) => <img alt="erip" key={key} src={eripImg} className="payment-method__icon" />,
  sberPay: (key) => <img alt="sberPay" key={key} src={sberPay} className="payment-method__icon" />,
};

const methodIcons: Record<string, string[]> = {
  'card': ['visa', 'mastercard'],
  'card_byn': ['visa', 'mastercard'],
  'card_azn': ['visa', 'mastercard'],
  'card_kgs': ['visa', 'mastercard'],
  'card_uah_200': ['visa', 'mastercard'],
  'card_aifory': ['visa', 'mastercard'],
  'card_aifory_sng': ['visa', 'mastercard'],
  'uzcard': ['visa', 'mastercard'],
  'card_uzs': ['uzcard', 'humo'],
  'qiwi': ['qiwi'],
  'mobile': ['mobile'],
  'webmoney': ['webmoney'],
  'umoney': ['umoney'],
  'operator': ['operator'],
  'card_kzt': ['visa', 'mastercard'],
  'card_uah': ['visa', 'mastercard'],
  'card_paycos': ['visa', 'mastercard'],
  'sbp': ['sbp'],
  'card_aifory_sbp': ['sbp'],
  'fps_key': ['sbp'],
  'p2psbp': ['sbp'],
  'card_uah_1000': ['privat', 'vsibanki'],
  'bybit': ['bybit'],
  'payeer': ['payeer'],
  'binance': ['binance'],
  'paypal': ['paypal'],
  'cardmir': ['cardmir'],
  'mir': ['cardmir'],
  'skrill': ['skrill'],
  'paysera': ['paysera'],
  'westernunion': ['westernunion'],
  'advcash': ['advcash'],
  'usdt': ['tether'],
  'usdtbep20': ['tether'],
  'usdt_withdrawal': ['tether'],
  'aifory_USDTBEP': ['tether'],
  'aifory_USDTBSC': ['tether'],
  'aifory_USDTTRC': ['tether'],
  'aifory_USDTERC': ['tether'],
  'betatransfer': ['betatransfer'],
  'sepa': ['sepa'],
  'm10': ['m10'],
  'humo': ['humo'],
  'erip': ['erip'],
  'p2pcard': ['visa', 'mastercard', 'cardmir'],
  'sber_pay': ['sberPay'],
  'card_aifory_sberpay': ['sberPay']
};

export const getMethodIcons = (method?: string | null): JSX.Element[] | null => {
  method = method?.toLocaleLowerCase();
  if (method && methodIcons[method]) {
    return methodIcons[method].map((icon) => iconsComponents[icon](icon));
  }

  return null;
};
