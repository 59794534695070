import { useTranslation } from 'react-i18next';

import GamesCardsWidgetMobile from 'components/shared/GamesCardsWidget/GamesCardsWidgetMobile/GamesCardsWidgetMobile';
import { useAppSelector } from 'hooks';
import { selectIsPragmaticActive } from 'store/app/selectors';
import { useGetPragmaticLobbyGamesQuery } from 'store/slots/slotsApi';
import Adaptive from 'components/Layout/Adaptive/Adaptive';
import GamesCardsWidgetDesktop from 'components/shared/GamesCardsWidget/GamesCardsWidgetDesktop/GamesCardsWidgetDesktop';


const NewGamesWidget = () => {
  const { t } = useTranslation();
  const isPragmaticActive = useAppSelector(selectIsPragmaticActive);
  const {
    data: pragmaticGamesNew = [],
    isLoading,
  } = useGetPragmaticLobbyGamesQuery({
    category: 'new',
  }, { skip: !isPragmaticActive });

  if (!isPragmaticActive) {
    return null;
  }

  const link = '/games?filter=Pragmatic+Play';
  const gamesList = pragmaticGamesNew.slice(0, 20);

  return (
    <Adaptive
      mobile={(
        <GamesCardsWidgetMobile
          title={t('games.new', 'New')}
          iconColor="light-blue"
          iconName='new-star'
          itemsCounter={pragmaticGamesNew.length}
          items={gamesList}
          linkToAll={link}
          isLoading={isLoading}
          isAvailable={isPragmaticActive}
        />
      )}
      desktop={(
        <GamesCardsWidgetDesktop
          title={t('games.new', 'New')}
          items={gamesList}
          linkToAll={link}
          isAvailable={isPragmaticActive}
        />
      )}
    />
  );
};

export default NewGamesWidget;
