import GamesCardsWidgetMobile from 'components/shared/GamesCardsWidget/GamesCardsWidgetMobile/GamesCardsWidgetMobile';
import { useAppSelector } from 'hooks';
import { selectIsPragmaticActive } from 'store/app/selectors';
import { useGetPragmaticJackpotsQuery, useGetPragmaticLobbyGamesQuery } from 'store/slots/slotsApi';
import pragmaticLogo from 'assets/pragmatic-logo.svg';
import Adaptive from 'components/Layout/Adaptive/Adaptive';
import GamesCardsWidgetDesktop from 'components/shared/GamesCardsWidget/GamesCardsWidgetDesktop/GamesCardsWidgetDesktop';

const PragmaticPlayWidget = () => {
  const isPragmaticActive = useAppSelector(selectIsPragmaticActive);
  const {
    data: pragmaticGames = [],
    isLoading: isPragmaticLoading,
  } = useGetPragmaticLobbyGamesQuery({
    category: 'all',
  }, { skip: !isPragmaticActive });
  const { data: jackpots = [] } = useGetPragmaticJackpotsQuery(undefined, { skip: !isPragmaticActive });
  const jackpot = jackpots?.[0]?.tiers ?? [];

  if (!isPragmaticActive) {
    return null;
  }

  const link = '/games?filter=Pragmatic+Play';
  const gamesList = pragmaticGames.slice(0, 20);


  return (
    <Adaptive
      mobile={(
        <GamesCardsWidgetMobile
          title="Pragmatic Play"
          icon={(
            <img src={pragmaticLogo} alt="Pragmatic Play" />
          )}
          iconColor="blue"
          iconName='games'
          itemsCounter={pragmaticGames.length}
          items={gamesList}
          linkToAll={link}
          isLoading={isPragmaticLoading}
          isAvailable={isPragmaticActive}
          jackpots={jackpot}
        />
      )}
      desktop={(
        <GamesCardsWidgetDesktop
          title="Pragmatic Play"
          items={gamesList}
          linkToAll={link}
          isAvailable={isPragmaticActive}
          jackpots={jackpot}
        />
      )}
    />
  );
};

export default PragmaticPlayWidget;
