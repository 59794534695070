import { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import GamesCard, { GameCardItemType } from 'components/shared/GamesCardsWidget/GamesCard/GamesCard';
import { batgamesList, crashGames, twainSportList, tvbetList, fastsportList } from 'utils/constants';
import Breadcrumbs from 'components/shared/Breadcrumbs/Breadcrumbs';
import {
  useGetInbetGamesQuery,
  useGetPragmaticLobbyGamesQuery,
  useGetVegasGamesQuery,
  useGetMascotGamesQuery,
  useGetAgtGamesQuery,
  useGetSmartsoftGamesQuery,
} from 'store/slots/slotsApi';
import ButtonToTop from 'components/shared/ButtonToTop/ButtonToTop';
import { useAppSelector } from 'hooks';
import {
  selectIsInbetActive,
  selectIsPragmaticActive,
  selectIsVegasActive,
  seletIsBetgameActive,
  selectIsMascotActive,
  selectIsAgtActive,
  selectIsSmartsoftActive,
  selectIsTvbetActive,
} from 'store/app/selectors';
import { selectIsAuthenticated } from 'store/auth/selectors';
import SiteMaintenance from 'pages/SiteMaintenance/SiteMaintenance';
import { Maintenance } from 'utils/constants/maintenanceMessage';
import Pagination from 'components/shared/Pagination/Pagination';
import { scrollToTop } from 'utils/scrollToTop';
import { handleAgtResponseError } from 'pages/GamesPage/AgtGame/AgtGame';

import GamesFilter from '../GamesFilter/GamesFilter';
import GameListSelector from './GameListSelector/GameListSelector';
import './GamesList.scss';

const gamesFilterList = [
  // 'Все',
  'Pragmatic Play',
  'TVBet',
  'Fastsport',
  'BetGames TV',
  'Twain Sport',
  'Crash Games',
  'Inbet Games Slots',
  'Inbet Games Egame',
  'Inbet Games Betting',
  'Vegas Club',
  'Mascot',
  'Agt',
  'Smartsoft'
];

const GamesList = () => {
  const { t } = useTranslation();
  const [searchParams, setSearchParams] = useSearchParams();

  const [filter, setFilter] = useState(searchParams.get('filter') ?? gamesFilterList[0]);
  const [gamesList, setGamesList] = useState<GameCardItemType[]>([]);
  const [pageNumber, setPageNumber] = useState(1);
  const [selectedSection, setSelectedSection] = useState<string | null>(null);
  const [sections, setSections] = useState<string[]>([]);

  const isAuthenticated = useAppSelector(selectIsAuthenticated);

  const isInbetActive = useAppSelector(selectIsInbetActive);
  const isVegasActive = useAppSelector(selectIsVegasActive);
  const isBetgameActive = useAppSelector(seletIsBetgameActive);
  const isPragmaticActive = useAppSelector(selectIsPragmaticActive);
  const isMascotActive = useAppSelector(selectIsMascotActive);
  const isAgtActive = useAppSelector(selectIsAgtActive);
  const isSmartsoftActive = useAppSelector(selectIsSmartsoftActive);
  const isTvbetActive = useAppSelector(selectIsTvbetActive);

  const inbetSlotsList = useGetInbetGamesQuery({ gameType: 'slot' }, { skip: !isInbetActive });
  const inbetEgamesList = useGetInbetGamesQuery({ gameType: 'egame' }, { skip: !isInbetActive });
  const inbetBettingList = useGetInbetGamesQuery({ gameType: 'betting' }, { skip: !isInbetActive });

  const { data: vegasGames } = useGetVegasGamesQuery(undefined, { skip: !isVegasActive });
  const { data: mascotGames } = useGetMascotGamesQuery(undefined, { skip: !isMascotActive });
  const { data: agtGames, isError: isAgtError, error: agtError } = useGetAgtGamesQuery(undefined, { skip: !isAgtActive });

  const { data: pragmaticGames = [] } = useGetPragmaticLobbyGamesQuery({
    category: 'all',
  }, { skip: !isPragmaticActive });
  const { data: smartsoftGames = [] } = useGetSmartsoftGamesQuery(
    undefined,
    { skip: !isSmartsoftActive }
  );

  const getTabs = () => gamesFilterList.filter((tab) => {
    switch (tab) {
      case 'Pragmatic Play':
        return isPragmaticActive;
      case 'TVBet':
      case 'Fastsport':
        return isTvbetActive;
      case 'BetGames TV':
      case 'Twain Sport':
      case 'Crash Games':
        return isBetgameActive;
      case 'Inbet Games Slots':
      case 'Inbet Games Egame':
      case 'Inbet Games Betting':
        return isInbetActive;
      case 'Vegas Club':
        return isVegasActive;
      case 'Mascot':
        return isMascotActive;
      case 'Agt':
        return isAgtActive;
      case 'Smartsoft':
        return isSmartsoftActive;
    }
    return false;
  });

  useEffect(() => {
    setFilter(searchParams.get('filter') ?? getTabs()[0] ?? '');
  }, []);


  useEffect(() => {
    switch (filter) {
      case 'Все':
        setGamesList(
          batgamesList.concat(
            twainSportList,
            inbetSlotsList.data ?? [],
            inbetEgamesList.data ?? [],
            inbetBettingList.data ?? []
          ));
        break;
      case 'Pragmatic Play':
        setGamesList(pragmaticGames);
        setSelectedSection(null);
        break;
      case 'TVBet':
        if (isBetgameActive) {
          setGamesList(tvbetList);
          setSelectedSection(null);
        } else {
          setGamesList([]);
          setSelectedSection(null);
        }
        break;
      case 'Fastsport':
        if (isBetgameActive) {
          setGamesList(fastsportList);
          setSelectedSection(null);
        } else {
          setGamesList([]);
          setSelectedSection(null);
        }
        break;
      case 'BetGames TV':
        if (isBetgameActive) {
          setGamesList(batgamesList);
          setSelectedSection(null);
        } else {
          setGamesList([]);
          setSelectedSection(null);
        }
        break;
      case 'Twain Sport':
        if (isBetgameActive) {
          setGamesList(twainSportList);
          setSelectedSection(null);
        } else {
          setGamesList([]);
          setSelectedSection(null);
        }
        break;
      case 'Crash Games':
        if (isBetgameActive) {
          setGamesList(crashGames);
          setSelectedSection(null);
        } else {
          setGamesList([]);
          setSelectedSection(null);
        }
        break;
      case 'Inbet Games Slots':
        if (isInbetActive) {
          setGamesList(inbetSlotsList.data ?? []);
          setSelectedSection(null);
        } else {
          setGamesList([]);
          setSelectedSection(null);
        }
        break;
      case 'Inbet Games Egame':
        if (isInbetActive) {
          setGamesList(inbetEgamesList.data ?? []);
          setSelectedSection(null);
        } else {
          setGamesList([]);
          setSelectedSection(null);
        }
        break;
      case 'Inbet Games Betting':
        if (isInbetActive) {
          setGamesList(inbetBettingList.data ?? []);
          setSelectedSection(null);
        } else {
          setGamesList([]);
          setSelectedSection(null);
        }
        break;
      case 'Vegas Club':
        if (isVegasActive) {
          setSections(['all', ...(vegasGames?.sections ?? [])]);
          setGamesList((vegasGames?.items ?? []).filter((item) => {
            if (!selectedSection || selectedSection === 'all') {
              return true;
            }
            return item.sectionId === selectedSection;
          }));
          if (!selectedSection) {
            setSelectedSection('all');
          }
        } else {
          setGamesList([]);
          setSelectedSection(null);
        }
        break;
      case 'Mascot':
        if (isMascotActive) {
          setGamesList(mascotGames?.items ?? []);
          setSelectedSection(null);
        } else {
          setGamesList([]);
          setSelectedSection(null);
        }
        break;
      case 'Agt':
        if (isAgtActive && isAuthenticated) {
          setGamesList(agtGames?.items ?? []);
          setSelectedSection(null);
        } else {
          setGamesList([]);
          setSelectedSection(null);
        }
        break;
      case 'Smartsoft':
        if (isSmartsoftActive) {
          setGamesList(smartsoftGames ?? []);
          setSelectedSection(null);
        } else {
          setGamesList([]);
          setSelectedSection(null);
        }
        break;
      default:
        setGamesList([]);
        setSelectedSection(null);
    }

    setPageNumber(1);
  }, [filter, selectedSection, inbetSlotsList.data, inbetEgamesList.data, inbetBettingList.data, vegasGames, mascotGames, smartsoftGames, agtGames, pragmaticGames.length]);

  useEffect(() => {
    scrollToTop(true);
  }, [pageNumber]);

  const handleFilterChange = (newFilter: string) => {
    setFilter(newFilter);
    searchParams.set('filter', newFilter);
    setSearchParams(searchParams);
  };

  const agtErrorMessage = !isAuthenticated ? `${t('games.error.not-auth', 'Раздел доступен только авторизованным пользователям')}` : handleAgtResponseError(agtError);

  if (!isInbetActive && !isBetgameActive && !isVegasActive && !isPragmaticActive && !isMascotActive && !isAgtActive && !isSmartsoftActive) {
    return <SiteMaintenance message={Maintenance.games} />;
  }

  return (
    <div className="games-list">
      <Breadcrumbs
        link="/home"
        title={t('main.nav.Home', 'Главная')}
      />
      <h1 className="games-list__title">
        {t('games.list', 'Список игр')}
      </h1>
      <GamesFilter
        className='games-list__filter'
        items={getTabs()}
        activeItem={filter}
        onChange={handleFilterChange}
      />

      {isAgtError && filter === 'Agt' && (
        <div className='games-list__error'>{agtErrorMessage}</div>
      )}

      {selectedSection ? (
        <GameListSelector className='games-list__section-selector'
          items={sections}
          initSelected={selectedSection}
          onSelect={(newSection) => setSelectedSection(newSection)}
        />
      ) : null}

      <div className="games-list__items">
        {gamesList.slice(pageNumber * 20 - 20, pageNumber * 20).map((game) => (
          <GamesCard
            key={`game-${game.id ?? '123'}-${game.name}`}
            item={game}
          />
        ))}
      </div>
      <Pagination
        selectPage={pageNumber}
        countPages={Math.ceil(gamesList.length / 20)}
        setSelectPage={setPageNumber}
      />
      <ButtonToTop />
    </div>
  );
};

export default GamesList;
