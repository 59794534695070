import React from 'react';

import Adaptive from 'components/Layout/Adaptive/Adaptive';
import { useTelegramModal } from 'hooks/useTelegramModal';

import HomeMobile from './HomeMobile/HomeMobile';
import HomeDesktop from './HomeDesktop/HomeDesktop';

type Props = {
  isAuthenticated: boolean;
  isAccountLoading: boolean;
};

const Home = (props: Props) => {
  // useTelegramModal(props.isAuthenticated, props.isAccountLoading);
  return (
    <Adaptive
      desktop={<HomeDesktop />}
      mobile={<HomeMobile />}
    />
  );
};

export default Home;
