import React, { useEffect } from 'react';
import cn from 'classnames';
import { Link } from 'react-router-dom';
import { Swiper, SwiperSlide } from 'swiper/react';
import { FreeMode, Mousewheel } from 'swiper';
import { useTranslation } from 'react-i18next';
import 'swiper/css';
import 'swiper/css/mousewheel';

import SvgIcon from 'components/shared/SvgIcon/SvgIcon';
// import Button from 'components/shared/buttons/Button/Button';
import SportsFilterItem from 'components/line/SportsFilter/SportsFilterItem/SportsFilterItem';
import { useFilteredSports } from 'hooks/useFilteredSports';
import { useAppSelector } from 'hooks';
import { seletIsLineActive } from 'store/app/selectors';

import type { LineType } from 'types/line-data';
import './DesktopHeadWidget.scss';

type Props = {
  title: string;
  link: string;
  lineType: LineType;
  changeSport: React.Dispatch<React.SetStateAction<string | undefined>>;
  activeSport: string;
};

const DesktopHeadWidget = (props: Props) => {
  const { title, link, lineType, changeSport, activeSport } = props;
  const { t, i18n } = useTranslation();
  const isLineActive = useAppSelector(seletIsLineActive);
  const { data: sportsList = [] } = useFilteredSports(
    {
      dataType: lineType,
      dataLang: i18n.resolvedLanguage
    },
    { skip: !isLineActive }
  );

  useEffect(() => {
    if (sportsList) {
      changeSport(sportsList[0]?.id);
    }
  }, [sportsList]);

  return (
    <div className='desktop-head-widget'>
      <div className="desktop-head-widget__top">
        <h2 className='desktop-head-widget__title'>{title}</h2>
        <div className="widget-header__link">
          <Link to={link} className="widget-header__show-more-link">
            {t('top-events-widget.all', 'Все')}
            <SvgIcon className='widget-header__arrow-icon' name="arrow-right" width={9} height={9} />
          </Link>
        </div>
        <div className="desktop-head-widget__sports">
          <Swiper
            slidesPerView={8}
            spaceBetween={5}
            slidesPerGroup={1}
            freeMode
            mousewheel
            modules={[FreeMode, Mousewheel]}
            className="mySwiper"
          >
            {sportsList?.map((item) => (
              <SwiperSlide key={item.id} className={cn('desktop-head-widget__item', item.id === String(activeSport) ? 'active' : 'disable')}>
                <SportsFilterItem
                  key={item.id}
                  sport={item}
                  onClick={() => changeSport(item.id)}
                  isActive={item.id === activeSport}
                />
              </SwiperSlide>
            ))}
          </Swiper>
        </div>
      </div>
    </div>
  );
};

export default DesktopHeadWidget;
