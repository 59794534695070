import React from 'react';
import { Route, Routes } from 'react-router-dom';

import Profile from 'components/profile/Profile/Profile';
import Settings from 'components/profile/Settings/Settings';
import Promocodes from 'components/profile/Promocodes/Promocodes';
import Topups from 'components/profile/Topups/Topups';
import Withdrawals from 'components/profile/Withdrawals/Withdrawals';
import Transactions from 'components/profile/Transactions/Transactions';
import WalletManage from 'components/profile/WalletManage/WalletManage';
import SupportPage from 'pages/StaticPages/SupportPage/SupportPage';
import { ProfileRoute } from 'utils/routes';
import { selectIsWithdrawalsActive } from 'store/app/selectors';
import NotFounded from 'pages/NotFound/NotFound';
import { useAppSelector } from 'hooks';
import SiteMaintenance from 'pages/SiteMaintenance/SiteMaintenance';
import { Maintenance } from 'utils/constants/maintenanceMessage';
import FavoriteList from 'components/profile/FavoriteList/FavoriteList';

const ProfilePage = () => {
  const isWithdrawalsActive = useAppSelector(selectIsWithdrawalsActive);
  return (
    <Routes>
      <Route index element={<Profile />} />
      <Route path={ProfileRoute.Settings} element={<Settings />} />
      <Route path={ProfileRoute.Promocodes} element={<Promocodes />} />
      <Route path={ProfileRoute.Topups} element={<Topups />} />
      <Route path={ProfileRoute.Withdrawals}
        element={isWithdrawalsActive
          ? <Withdrawals />
          : <SiteMaintenance message={Maintenance.withdrawals} />}
      />
      <Route path={ProfileRoute.WalletManage} element={<WalletManage />} />
      <Route path={ProfileRoute.TransactionsPage} element={<Transactions />} />
      <Route path={ProfileRoute.Support} element={<SupportPage />} />
      <Route path={ProfileRoute.Favorite} element={<FavoriteList />} />
      <Route path="*" element={<NotFounded />} />
    </Routes>
  );
};

export default ProfilePage;
