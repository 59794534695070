import React, { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { useTranslation } from 'react-i18next';

import i18n from 'i18n/i18n';
import { useAppSelector } from 'hooks';
import { useResetPasswordMutation } from 'store/auth/authApi';
import { selectLoginError } from 'store/auth/selectors';
import Button from 'components/shared/buttons/Button/Button';
import FormInputWrapper from 'components/shared/form/FormInput';
import ErrorBlock from 'components/shared/inputs/ErrorBlock/ErrorBlock';
import { telegramSuppotLink } from 'utils/constants';
import { bemCn } from 'utils/bem-cn';
import AppModal from 'components/shared/AppModal/AppModal';

import type { Entries } from 'types/common';
import type { LoginErrors } from 'types/auth-data';

type FormFields = {
  email: string;
};

type Props = {
  isOpen: boolean;
  onClose: () => void;
};

const schema = yup.object({
  email: yup.string()
    .email(`${i18n.t('reset-pass.errors.wrong-email', 'Введите корректный E-mail')}`)
    .required(`${i18n.t('reset-pass.errors.empty-email', 'Введите E-mail')}`),
}).required();

const ResetPassword = (props: Props) => {
  const { isOpen, onClose } = props;
  const { t } = useTranslation();
  const [reset, { isLoading }] = useResetPasswordMutation();
  const loginErrors = useAppSelector(selectLoginError);

  const { handleSubmit, setError, control } = useForm<FormFields>({
    defaultValues: { email: '' },
    mode: 'all',
    resolver: yupResolver(schema),
  });

  useEffect(() => {
    const fieldsErrors: Partial<LoginErrors> = { ...loginErrors };
    delete fieldsErrors.common;

    const formEntries = Object.entries(fieldsErrors) as Entries<FormFields>;
    formEntries.forEach(([key, value]) => {
      if (!value) { return; }

      setError(key, {
        type: 'server',
        message: value,
      });
    });
  }, [loginErrors]);

  const handleLoginClick = async (data: FormFields) => {
    await reset(data.email);
  };

  const b = bemCn('login-modal');

  return (
    <AppModal className={b()} isOpen={isOpen} onClose={onClose} >
      <div className={b('header')}>
        <h2 className={b('title')}>
          {t('reset-pass.title', 'Восстановление пароля')}
        </h2>
        <p className={b('description')}>
          {t('reset-pass.email-description', 'Введите адрес электронной почты  вашего аккаунта, мы отправим вам ссылку для восстановления пароля')}
        </p>
      </div>

      <div className={b('wrapper')}>
        {!!loginErrors.common && (
          <ErrorBlock
            isDisplayed={!!loginErrors.common}
            message={loginErrors.common}
            align="center"
          />
        )}
        <div className={b('fields')}>
          <FormInputWrapper<FormFields>
            name="email"
            type="text"
            control={control}
            disabled={isLoading}
            autoComplete="email"
            placeholder="E-mail"
            showError
          />
        </div>
        <a className={b('remember-password')} href={telegramSuppotLink}>
          {t('reset-pass.forgot-email', 'Не помню e-mail')}
        </a>
        <Button className={b('button')}
          onClick={handleSubmit(handleLoginClick)}
          isLoading={isLoading}
        >
          {t('reset-pass.send-request', 'Отправить')}
        </Button>
      </div>
    </AppModal>
  );
};

export default ResetPassword;
