
import { FC } from 'react';

import Loader from 'components/shared/loader/Loader';
import { bemCn } from 'utils/bem-cn';
import { useGetBasketQuery } from 'store/basket/basketApi';
import { selectIsAuthenticated } from 'store/auth/selectors';
import { useAppSelector } from 'hooks';

import Stats from '../components/EventCardStats/EventCardStats';
import Board from '../components/EventCardBoard/EventCardBoardSimple';
import Tabs from '../components/EvetnCardTabs/EvetnCardTabs';
import BetsGroup from '../components/EventCardBetsGroup/EventCardBetsGroup';
import Info from '../components/EventCardInfo/EventCardInfo';
import Сountdown from '../components/EventCardCountdown/EventCardCountdown';
import ScoreTable from '../components/EventCardScoreTable/EventCardScoreTable';

import type { Bet, FullEventItem, Group, SubGame } from 'types/line-data';

import './EventCardFullMobile.scss';

type Props = {
  tabs: SubGame[];
  event: FullEventItem;
  betsGroups: Group[];
  activeTab: number;
  isBetsLoading: boolean;
  isBetsClosed: boolean;
  onTabChange: (id: number) => void;
  onToggleBetsClose: () => void;
  onBetClick: (bet: Bet, betGroup: string) => void;
  isLive: boolean;
  className?: string;
}

const EventCardFullMobile = (props: Props) => {
  const {
    tabs,
    event,
    isLive,
    betsGroups,
    activeTab,
    isBetsLoading,
    isBetsClosed,
    className,
    onTabChange,
    onToggleBetsClose,
    onBetClick,
  } = props;
  const isAuth = useAppSelector(selectIsAuthenticated);
  const { basketItem } = useGetBasketQuery(undefined, {
    skip: !isAuth,
    selectFromResult: ({ data }) => ({
      basketItem: data?.itemsByGameId[event.id]?.basketId
    })
  });

  const b = bemCn('event-card-full-mobile');

  return (
    <div className={b(null, className)}>
      <div className={b('wrapper')}>
        <div className={b('head')}>
          <Info className={b('info')}
            event={event}
          />
          <Board className={b('board')}
            event={event}
            isLive={isLive}
          />
          <Сountdown className={b('countdown')}
            date={event.eventDate}
            hide={isLive}
          />
        </div>
        {isLive && (
          <ScoreTable className={b('score-table')}
            scorePeriods={event.scorePeriod}
            team1={event.team1}
            team2={event.team2}
            scoreFull={event.scoreFull}
            periodName={event.periodName.split(' ')[1]}
          />
        )}
        <Stats stats={event.statList} />
        <div className={b('bets')}>
          <Tabs className={b('tabs')}
            tabs={tabs}
            activeTab={activeTab}
            onTabChange={onTabChange}
            onToggleClose={onToggleBetsClose}
          />
          <div className={b('bets-groups')}>
            {isBetsLoading
              ? <Loader />
              : betsGroups.map((group) => (
                <BetsGroup
                  key={`${group.name}-${group.bets.length}`}
                  className={b('bets-group')}
                  isAllClosed={isBetsClosed}
                  group={group}
                  basketItemBetId={basketItem}
                  onBetClick={onBetClick}
                />
              ))}
          </div>
        </div>
      </div>
    </div >
  );
};

export default EventCardFullMobile;
