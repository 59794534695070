import { Link } from 'react-router-dom';
import { ClassNameFormatter } from '@bem-react/classname';

import { MenuItem } from './NavMenu';

type Props = {
  item: MenuItem;
  onClick?: () => void;
  onClickLink?: () => void;
  isActive?: boolean;
  isInverse?: boolean;
  b: ClassNameFormatter;
}

const NavMenuItem = ({ item, onClick, onClickLink, isActive, isInverse, b }: Props) => (
  <div className={b('item-wrapper', { active: isActive, inverse: isInverse, })}
    key={item.id}
  >
    {item.link ? (
      <Link className={b('item', b('item-link'))}
        to={item.link}
        onClick={!item.disabled ? onClickLink : undefined}
      >
        {item.title}
      </Link>
    ) : (
      <button className={b('item', b('item-button'))}
        type="button"
        onClick={!item.disabled ? onClick : undefined}
        tabIndex={item.disabled ? -1 : 0}
        disabled={item.disabled}
      >
        {item.title}
      </button>
    )}
  </div>
);

export default NavMenuItem;
