import { bemCn } from 'utils/bem-cn';

import Timer from '../EventCardTimer/EventCardTimer';
import Team from '../EventCardTeam/EventCardTeam';

import type { FullEventItem } from 'types/line-data';

import './EventCardBoard.scss';

type Props = {
  event: FullEventItem;
  className?: string;
  isLive?: boolean;
}

const EventCardBoard = ({ event, className, isLive }: Props) => {
  const isTimerStoped =
    event.finale
    || event.gameDesk === 'Игра завершена'
    || event.gameDesk === 'Game finished'
    || event.gameDesk === 'Перерыв'
    || event.gameDesk === 'Break';

  const b = bemCn('event-card-board');

  return (
    <div className={b({ simple: true }, className)}>
      <div className={b('time')}>
        <p className={b('period-name')}>{event.gameDesk}</p>
      </div>
      <div className={b('teams')}>
        <Team
          name={event.team1}
          logo={event.team1Icon}
        />
        {event.timer > 0 && isLive
          ? (
            <Timer
              timer={event.timer}
              stoped={isTimerStoped}
            />
          )
          : <p className={b('total-score')}>VS</p>}
        <Team
          name={event.team2}
          logo={event.team2Icon}
        />
      </div>
    </div>
  );
};


export default EventCardBoard;
