import React from 'react';

import Loader from 'components/shared/loader/Loader';
import { useResponsive } from 'hooks/useResponsive';
import AuthModalsGroup from 'components/auth/AuthModalsGroup/AuthModalsGroup';
import { useAppDispatch, useAppSelector } from 'hooks';
import { selectAuthActiveModal } from 'store/auth/selectors';
import { changeActiveModal } from 'store/auth/authSlice';

import DesktopHeader from './DesktopHeader/DesktopHeader';
import HeaderNoAuthorized from './HeaderNoAuthorized/HeaderNoAuthorized';
import HeaderAuthorized from './HeaderAuthorized/HeaderAuthorized';
import './Header.scss';

type Props = {
  isAuthenticated: boolean;
  isAccountLoading: boolean;
};

const Header = ({ isAuthenticated, isAccountLoading }: Props) => {
  const { Mobile, Desktop } = useResponsive();
  const dispatch = useAppDispatch();
  const activeModal = useAppSelector(selectAuthActiveModal);

  if (isAccountLoading) {
    return <div className="main-header"><Loader /></div>;
  }

  return (
    <>
      <Mobile>
        {isAuthenticated ? <HeaderAuthorized /> : <HeaderNoAuthorized />}
      </Mobile>
      <Desktop>
        <DesktopHeader isAuthenticated={isAuthenticated} />
      </Desktop>
      <AuthModalsGroup
        activeModal={activeModal}
        closeModal={() => dispatch(changeActiveModal(null))}
      />
    </>
  );
};

export default Header;
