import React, { MouseEventHandler } from 'react';
import { useCopyToClipboard } from 'usehooks-ts';
import { useTranslation } from 'react-i18next';

import i18n from 'i18n/i18n';
import CoefDisplay from 'components/line/components/CoefDisplay/CoefDisplay';
import { ReactComponent as PlacedIcon } from 'assets/bets/inProcess.svg';
import { ReactComponent as WinIcon } from 'assets/bets/win.svg';
import { ReactComponent as LossIcon } from 'assets/bets/defeat.svg';
import { getCurrencySymbol } from 'utils/currency';
import { domainName } from 'utils/constants';
import { getDateTimeSlashFormat } from 'utils/time';
import { bemCn } from 'utils/bem-cn';
import SvgIcon from 'components/shared/SvgIcon/SvgIcon';

import CouponDetailedItem from './CouponDetailedItem/CouponDetailedItem';

import type { Coupon, PlaceType } from 'types/user-data';
import './CouponDetailed.scss';

type Props = {
  coupon: Coupon;
};

const typeTitles: Record<PlaceType, string> = {
  ordinar: i18n.t('bet-history.single', 'Ординар'),
  express: i18n.t('bet-history.express', 'Экспресс'),
};

export const statusTitles = {
  placed: i18n.t('bet-history.not-calculated', 'Не рассчитано'),
  win: i18n.t('bet-history.win', 'Выигрыш'),
  loss: i18n.t('bet-history.loss', 'Проигрыш'),
  refund: i18n.t('bet-history.refund', 'Возврат')
};

export const statusIcons = {
  placed: <PlacedIcon />,
  win: <WinIcon />,
  loss: <LossIcon />,
  refund: <WinIcon />
};

const CouponDetailed = ({ coupon }: Props) => {
  const {
    id,
    type,
    winAmount,
    created,
    status,
    betAmount,
    coef,
    currency,
    expectedWinAmount,
    bets,
    balanceType,
  } = coupon;

  const [copiedValue, copyBarCode] = useCopyToClipboard();
  const { t } = useTranslation();
  const isOrdinar = type === 'ordinar';

  const isWin = status === 'win';
  const isPlaced = status === 'placed';
  const isLoss = status === 'loss';
  const isRefund = status === 'refund';
  const isBonusWallet = balanceType === 'bonus';

  const expectedAmountLayout = expectedWinAmount
    && `${expectedWinAmount.toFixed(2)} ${getCurrencySymbol(currency)}`;

  const handleBarCodeCopyClick: MouseEventHandler = (evt) => {
    evt.preventDefault();
    copyBarCode(`${id}`);
  };

  const b = bemCn('coupon-detailed');
  return (
    <div className={b()}>
      <p className={b('status', { [`${status}`]: Boolean(status) })}>
        {statusTitles[status] ?? 'Проигрыш'}
        {statusIcons[status]}
      </p>
      <div className={b('wrapper', { bonus: isBonusWallet })}>
        <div className={b('content')}>
          <div className={b('header')}>
            <div className={b('row', b('place-type-wrapper'))}>
              <h2 className={b('place-type')}>{typeTitles[type]}</h2>
              {coef && (
                <CoefDisplay
                  value={coef.toFixed(2)}
                  variant="secondary"
                />
              )}
            </div>
            <div className={b('row')}>
              <p className={b('title')}>{getDateTimeSlashFormat(created)}</p>
              <button className={b('bar-code')}
                type='button'
                onClick={handleBarCodeCopyClick}
              >
                № {id} <SvgIcon className={b('bar-code-icon')} name='copy' width={13} height={13} />
              </button>
            </div>
          </div>
          <div className={b('header')}>
            <div className={b('site')}>{domainName}</div>
            <div className={b('row', b('row-bet'))}>
              <p className={b('bet')}>
                {t('bet-history.bet', 'Ставка')}:
              </p>
              <div>
                <h2>{`${betAmount} ${getCurrencySymbol(currency)}`}</h2>
              </div>
            </div>
            <div className={b('row', { [`${status}`]: Boolean(status) })}>
              <h2 className={b('win-amount-title')}>
                {isPlaced
                  ? t('bet-history.possible-prize', 'Возможный выигрыш')
                  : isRefund
                    ? t('bet-history.refund', 'Возврат')
                    : t('bet-history.prize', 'Выигрыш')}
              </h2>
              <p className={b('win-amount')}>
                {isPlaced && expectedAmountLayout}
                {(isWin || isLoss) && `${winAmount ?? 0} ${getCurrencySymbol(currency)}`}
                {isRefund && `${betAmount} ${getCurrencySymbol(currency)}`}
              </p>
            </div>
          </div>
          <div className={b('bets')}>
            {bets?.map((bet) => (
              <CouponDetailedItem
                key={bet.barcode}
                bet={bet}
                isOrdinar={isOrdinar}
              />
            ))}
          </div>
          {isBonusWallet ? (
            <p className={b('bonus-wallet-mark')}>
              {t('wallets.bonus-wallet', 'Бонусный счет')}
            </p>
          ) : null}
        </div>
      </div>
    </div>
  );
};

export default CouponDetailed;
