import { type PayloadAction, createSlice } from '@reduxjs/toolkit';

import { NameSpace } from 'utils/constants';
import { type AppStatus, Status } from 'types/auth-data';

import { appApi } from './appApi';

import type { EventsFilter } from 'types/line-data';

type AppModal = null | 'telegram-subscribe';

type AppData = {
  status: AppStatus;
  activeModal: AppModal;
  eventsFilter: EventsFilter;
}

const initialEventsFilter: EventsFilter = {
  sport: '0',
  country: '0',
  tournament: '0'
};

const initialState: AppData = {
  status: {
    system: Status.Unknown,
    line: Status.Unknown,
    betgames: Status.Unknown,
    tgg: Status.Unknown,
    inbet: Status.Unknown,
    vegas: Status.Unknown,
    mascot: Status.Unknown,
    agt: Status.Unknown,
    withdrawals: Status.Unknown,
    pragmatic: Status.Unknown,
    smartsoft: Status.Unknown,
    tvbet: Status.Unknown,
    aviatrix: Status.Unknown,
    systemMessage: {
      enable: false,
      message: '',
    }
  },
  activeModal: null,
  eventsFilter: initialEventsFilter,
};

export const appData = createSlice({
  name: NameSpace.App,
  initialState,
  reducers: {
    changeActiveAppModal: (state, action: PayloadAction<AppModal>) => {
      state.activeModal = action.payload;
    },
    setLiveEventsFilter: (state, action: PayloadAction<{
      sport?: string;
      country?: string;
      tournament?: string;
    }>) => {
      state.eventsFilter = {
        ...state.eventsFilter,
        ...action.payload
      };
    },
    setFilterSport: (state, { payload: sportId }: PayloadAction<string>) => {
      state.eventsFilter = {
        ...initialEventsFilter,
        sport: sportId === state.eventsFilter.sport ? '0' : sportId,
      };
    },
    setFilterCountry: (state, { payload: countryId }: PayloadAction<string>) => {
      state.eventsFilter.tournament = '0';
      state.eventsFilter.country = countryId === state.eventsFilter.country ? '0' : countryId;
    },
    setFilterTournament: (state, { payload: tournamentId }: PayloadAction<string>) => {
      state.eventsFilter.tournament = tournamentId === state.eventsFilter.tournament ? '0' : tournamentId;
    }
  },
  extraReducers: (builder) => {
    builder
      .addMatcher(
        appApi.endpoints.getAppStatus.matchFulfilled,
        (state, action) => {
          state.status = action.payload;
        }
      )
      .addMatcher(
        appApi.endpoints.getAppStatus.matchRejected,
        (state) => {
          // state.status = {
          //   system: Status.Unknown,
          //   line: Status.Unknown,
          //   betgames: Status.Unknown,
          //   tgg: Status.Unknown,
          //   inbet: Status.Unknown,
          // };
        }
      );
  }
});

export const {
  changeActiveAppModal,
  setLiveEventsFilter,
  setFilterSport,
  setFilterCountry,
  setFilterTournament,
} = appData.actions;
