import React, { useEffect, useState } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { useGetWalletsQuery } from 'store/user/userApi';
import { useAppSelector } from 'hooks';
import { selectAccountData, selectPrimaryWallet } from 'store/user/selectors';
import Button from 'components/shared/buttons/Button/Button';
import SupportIcon from 'components/shared/SupportIcon/SupportIcon';
import Loader from 'components/shared/loader/Loader';
import { bemCn } from 'utils/bem-cn';
import SvgIcon from 'components/shared/SvgIcon/SvgIcon';
import { ReactComponent as MenuIcon } from 'assets/icons/icon-menu.svg';

import './HeaderAuthorized.scss';


const HeaderAuthorized = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { t } = useTranslation();
  const [lastVisitedPage, setRoute] = useState('');
  const [isShifted, setIsShifted] = useState(false);
  const { id: userId } = useAppSelector(selectAccountData);
  const { amount, currency, bonusAmount } = useAppSelector(selectPrimaryWallet);
  const { isLoading: isWalletsLoading } = useGetWalletsQuery();

  const roundedAmount = Math.floor((amount * 100)) / 100;
  const roundedBonusAmount = Math.floor((bonusAmount * 100)) / 100;
  const isProfilePage = /^\/profile/.test(location.pathname);

  useEffect(() => {
    setIsShifted(isProfilePage);
  }, [isProfilePage]);

  const handleMenuClick = () => {
    setIsShifted(isShifted);
    setRoute(location.pathname);
    navigate('/profile');
  };

  const handleCloseClick = () => {
    setIsShifted(!isShifted);
    setRoute('');
  };

  const b = bemCn('main-header-authorized');

  return (
    <header className={b()}>
      <div className={b('container')}>
        <div className={b('slider', { shifted: isShifted })}>
          <div className={b('left-container')}>
            <MenuIcon className={b('menu-icon')} onClick={handleMenuClick} />
          </div>
          <div className={b('right-container')}>
            <div className={b('user-info')}>
              {isWalletsLoading
                ? <Loader />
                : (
                  <>
                    <div className={b('user-balance', { bonus: true })}>
                      {roundedBonusAmount} {currency ?? ''}
                    </div>
                    <div className={b('user-balance')}>
                      {roundedAmount} {currency ?? ''}
                    </div>
                  </>
                )}
            </div>
            <Link to="/profile/topups" className={b('top-up-btn-wrapper')}>
              <Button size="small" animated>
                {t('user-nav.topup', 'Пополнить')}
              </Button>
            </Link>
            <SupportIcon className={b('support-icon')} />
            <Link className={b('close-btn')}
              to={lastVisitedPage}
              onClick={handleCloseClick}
            >
              <SvgIcon className={b('close-icon')}
                name='close'
                width={8}
                height={8}
              />
            </Link>
          </div>
        </div>
      </div>
    </header>
  );
};

export default HeaderAuthorized;
