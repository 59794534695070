import { useEffect } from 'react';
import { useToggle } from 'usehooks-ts';
import { useTranslation } from 'react-i18next';

import { useResponsive } from 'hooks/useResponsive';
import Loader from 'components/shared/loader/Loader';
import { useEventFull } from 'hooks/useEventFull';
import { useAddToBasketMutation } from 'store/basket/basketApi';
import { selectIsAuthenticated } from 'store/auth/selectors';
import { useAppDispatch, useAppSelector } from 'hooks';
import { changeActiveModal } from 'store/auth/authSlice';

import EventCardFullMobile from '../EventCardFullMobile/EventCardFullMobile';
import EventCardFullDesktop from '../EventCardFullDesktop/EventCardFullDesktop';

import type { Bet, LineType } from 'types/line-data';

interface Props {
  lineType: LineType;
  eventId: string | undefined;
}

const EventCardFull = ({ eventId, lineType }: Props) => {
  const { isDesktop } = useResponsive();
  const { i18n } = useTranslation();
  const {
    event,
    betsGroups,
    isLoading,
    tabs,
    activeTab,
    handleTabChange
  } = useEventFull({ eventId: eventId, lineType: lineType });
  const [isBetsClosed, toggleBetsClosed, setBetsClosed] = useToggle(true);
  const [addToBasket] = useAddToBasketMutation();
  const isAuth = useAppSelector(selectIsAuthenticated);
  const dispatch = useAppDispatch();

  useEffect(() => {
    setBetsClosed(true);
  }, [activeTab]);

  const handleBetClick = (bet: Bet, betGroup: string) => {
    if (isAuth) {
      if (eventId && lineType && event) {
        addToBasket({
          dataType: lineType,
          basketId: bet.betId,
          lang: i18n.resolvedLanguage,
        });
      }
    } else {
      dispatch(changeActiveModal('sign-in'));
    }
  };

  if (!event) {
    return <Loader />;
  }

  return isDesktop
    ? (
      <EventCardFullDesktop
        tabs={tabs}
        activeTab={activeTab}
        event={event}
        isLive={lineType === 'live'}
        betsGroups={betsGroups}
        isBetsLoading={isLoading}
        isBetsClosed={isBetsClosed}
        onTabChange={handleTabChange}
        onToggleBetsClose={toggleBetsClosed}
        onBetClick={handleBetClick}
      />
    )
    : (
      <EventCardFullMobile
        tabs={tabs}
        activeTab={activeTab}
        event={event}
        isLive={lineType === 'live'}
        betsGroups={betsGroups}
        isBetsLoading={isLoading}
        isBetsClosed={isBetsClosed}
        onTabChange={handleTabChange}
        onToggleBetsClose={toggleBetsClosed}
        onBetClick={handleBetClick}
      />
    );
};


export default EventCardFull;
