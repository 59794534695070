
import { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { toLocaleDateString, toLocaleTimeString } from 'utils/time';
import { useResponsive } from 'hooks/useResponsive';
import FavoriteBtn from 'components/shared/FavoriteBtn/FavoriteBtn';
import { bemCn } from 'utils/bem-cn';

import Сountdown from '../EventCardCountdown/EventCardCountdown';

import type { FullEventItem } from 'types/line-data';

import './EventCardInfo.scss';

type Props = {
  className?: string;
  event: FullEventItem;
}

const EventCardInfo: FC<Props> = ({ className, event }) => {
  const { isDesktop } = useResponsive();
  const { i18n } = useTranslation();
  const eventDate = new Date(event.eventDate);
  const dateTime = toLocaleTimeString(
    eventDate,
    { hour: '2-digit', minute: '2-digit' },
    i18n.language
  );
  const date = toLocaleDateString(
    eventDate,
    { year: 'numeric', month: 'short', day: 'numeric' },
    i18n.language
  );

  const handleFavoriteClick = () => {
    // ? будет ли вообще реализована возможность добавления событий в избранное
  };

  const b = bemCn('event-card-info');

  return (
    <div className={b(null, className)}>
      <div className={b('top')}>
        <p className={b('date')}>
          <span className={b('date-time')}>{dateTime} </span>
          / {date}
        </p>
        <FavoriteBtn
          className={b('favorite')}
          onClick={handleFavoriteClick}
          disabled
        />
      </div>
      <div className={b('wrapper')}>
        <p className={b('sport')}>{event.sportName}</p>
        <p className={b('tournament')}>{event.tournament}</p>
      </div>
      <Сountdown
        className={b('countdown')}
        date={event.eventDate}
        hide={!isDesktop}
      />
    </div>
  );
};

export default EventCardInfo;
