import BannerMessages from 'components/bannerMessages/BannerMessages';
import DesktopLineWidgets from 'components/line/desktopLineWidgets/DesktopLineWidgets';
import GamesWidgets from 'components/casino/GamesWidgets/GamesWidgets';

import PromoDesktop from '../components/PromoDesktop/PromoDesktop';


const HomeDesktop = () => (
  <main className="home-page">
    <PromoDesktop />
    {/* <BannerMessages /> */}
    <DesktopLineWidgets />

    <GamesWidgets />
  </main>
);

export default HomeDesktop;
