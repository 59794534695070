import { useNavigate } from 'react-router-dom';

import bannerNew1 from 'assets/banners/bannerNew1.jpg';
import bannerNew5 from 'assets/banners/bannerNew5.jpg';
import bannerUA1 from 'assets/banners/bannerUA1.jpg';
import bannerUA2 from 'assets/banners/bannerUA2.jpg';
import bannerKZ1 from 'assets/banners/bannerKZ1.jpg';
import bannerDesktop1 from 'assets/banners/desktop/bannerDesktop1.jpg';
import bannerDesktop2 from 'assets/banners/desktop/bannerDesktop2.jpg';
import bannerDesktopUA1 from 'assets/banners/desktop/bannerDesktopUA1.jpg';
import bannerDesktopUA2 from 'assets/banners/desktop/bannerDesktopUA2.jpg';
import { useAppDispatch, useAppSelector } from 'hooks';
import { selectIsAuthenticated } from 'store/auth/selectors';
import { selectAccountLoading, selectPrimaryWalletCurrency, selectUserGeo } from 'store/user/selectors';
import { useGetWalletsQuery } from 'store/user/userApi';
import { changeActiveModal } from 'store/auth/authSlice';

export type BannerType = {
  img: string;
  webp?: string;
  link?: string;
  href?: string;
}

const Banner: Record<string, BannerType> = {
  bannerNew1: {
    img: bannerNew1,
  },
  bannerNew5: {
    img: bannerNew5,
  },
  bannerUA1: {
    img: bannerUA1,
  },
  bannerUA2: {
    img: bannerUA2,
  },
  bannerKZ1: {
    img: bannerKZ1,
  },

  bannerDesktop1: {
    img: bannerDesktop1,
  },
  bannerDesktop2: {
    img: bannerDesktop2,
  },
  bannerDesktopUA1: {
    img: bannerDesktopUA1,
  },
  bannerDesktopUA2: {
    img: bannerDesktopUA2,
  },
};
const BannersListMobile: Record<string, BannerType[]> = {
  'DEFAULT': [Banner.bannerNew1, Banner.bannerNew5],
  'RU': [Banner.bannerNew1, Banner.bannerNew5],
  'RUB': [Banner.bannerNew1, Banner.bannerNew5],
  'UA': [Banner.bannerUA1, Banner.bannerUA2],
  'UAH': [Banner.bannerUA1, Banner.bannerUA2],
  'KZ': [Banner.bannerKZ1, Banner.bannerNew5],
  'KZT': [Banner.bannerKZ1, Banner.bannerNew5],
};

const BannersListDesktop: Record<string, { big: BannerType[]; small: BannerType[] }> = {
  'DEFAULT': {
    big: [Banner.bannerDesktop1],
    small: [Banner.bannerDesktop2],
  },
  'RU': {
    big: [Banner.bannerDesktop1],
    small: [Banner.bannerDesktop2],
  },
  'RUB': {
    big: [Banner.bannerDesktop1],
    small: [Banner.bannerDesktop2],
  },
  'UA': {
    big: [Banner.bannerDesktopUA1],
    small: [Banner.bannerDesktopUA2],
  },
  'UAH': {
    big: [Banner.bannerDesktopUA1],
    small: [Banner.bannerDesktopUA2],
  },
  // 'KZ': {
  //   big: [],
  //   small: [],
  // },
  // 'KZT': {
  //   big: [],
  //   small: [],
  // },
};

export const useLocalizedBanners = () => {
  const isAuthenticated = useAppSelector(selectIsAuthenticated);
  const isAccaountLoading = useAppSelector(selectAccountLoading);
  const userGeo = useAppSelector(selectUserGeo);
  const userCurrency = useAppSelector(selectPrimaryWalletCurrency);
  const wallets = useGetWalletsQuery(undefined, { skip: !isAuthenticated || isAccaountLoading });
  const navigate = useNavigate();
  const dispatch = useAppDispatch();


  const onSlideClick = (link: string) => {
    if (isAuthenticated) {
      navigate(link);
      return;
    }
    dispatch(changeActiveModal('sign-up'));
  };

  const banersListMobile = BannersListMobile[userCurrency as string]
    || BannersListMobile[userGeo.country as string]
    || BannersListMobile.DEFAULT;

  const banersListDesktop = BannersListDesktop[userCurrency as string]
    || BannersListDesktop[userGeo.country as string]
    || BannersListDesktop.DEFAULT;

  return {
    isLoading: isAccaountLoading || userGeo.isLoading || wallets.isLoading,
    bannersMobile: banersListMobile,
    bannersDesktop: banersListDesktop,
    onSlideClick
  };
};
