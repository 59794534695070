import React from 'react';

import './StaticPages.scss';

type Props = {
  children: React.ReactNode;
};
const StaticPages = ({ children }: Props) => (
  <div className='static-pages__wrapper'>
    {children}
  </div>
);

export default StaticPages;
