import { useState } from 'react';
import { ClassNameFormatter } from '@bem-react/classname';

import iconFlagRu from 'assets/flags/flag-russia.svg';
import iconFlagUS from 'assets/flags/flag-united-states.svg';
import iconFlagUk from 'assets/flags/flag-ukraine.svg';
import iconFlagKZ from 'assets/flags/flag-kazakhstan.svg';

import SiteSettingsLangBtn from '../SiteSettingsLangBtn/SiteSettingsLangBtn';

const langIcons: Record<string, string> = {
  ru: iconFlagRu,
  en: iconFlagUS,
  uk: iconFlagUk,
  kk: iconFlagKZ,
};

type Props = {
  activeLang: string;
  langList: string[];
  onLangChange: (lang: string) => void;
  below?: boolean;
  b: ClassNameFormatter;
}

const SiteSettingsLang = ({ langList, onLangChange, activeLang, below, b }: Props) => {
  const [isOpened, setOpened] = useState(false);

  const handleClick = () => {
    setOpened((prevOpened) => !prevOpened);
  };

  const handleLangChange = (lang: string) => {
    setOpened(false);
    onLangChange(lang);
  };

  return (
    <div className={b('lang', { opened: isOpened, below })}>
      <div className={b('lang-selected')}>
        <SiteSettingsLangBtn
          className={b('lang-selected')}
          onClick={handleClick}
          title={activeLang}
          icon={langIcons[activeLang]}
          b={b}
        />
      </div>
      <div className={b('lang-list')}>
        {langList.map((l) => (
          <SiteSettingsLangBtn
            key={l}
            className={b('lang-item')}
            onClick={() => handleLangChange(l)}
            title={l}
            icon={langIcons[l] ?? ''}
            b={b}
          />
        ))}
      </div>
    </div>
  );
};


export default SiteSettingsLang;
