import i18n from 'i18n/i18n';
import tKickPreview from 'assets/twain-sport/twain-sport-t-kick.jpg';
import tBasketPreview from 'assets/twain-sport/twain-sport-t-basket.jpg';
import tKickPreviewWebp from 'assets/twain-sport/twain-sport-t-kick.webp';
import tBasketPreviewWebp from 'assets/twain-sport/twain-sport-t-basket.webp';
import betGamesPreviews from 'assets/betgames';
import tvBetPreviews from 'assets/tvbet';
import crashGameSkyward from 'assets/crash-games/card-crash-game.jpg';
import crashGameSkywardWebp from 'assets/crash-games/card-crash-game.webp';
import fastsportFootball from 'assets/fastsport/football@500х300.jpg';
import fastsportTennis from 'assets/fastsport/tennis@500х300.jpg';
import fastsportCyberFootball from 'assets/fastsport/efootball@500х300.jpg';

import type { GameCardItemType } from 'components/shared/GamesCardsWidget/GamesCard/GamesCard';

export enum NameSpace {
  BaseApi = 'baseApi',
  App = 'app',
  Auth = 'auth',
  Basket = 'basket',
  Line = 'line',
  Payments = 'payments',
  Slots = 'slots',
  User = 'user',
  Wallets = 'wallets',
  PartnerProgram = 'partnerProgram',
}

export const LINE_TYPES = ['line', 'live'];

export const availableLang = ['ru', 'uk', 'kk'];

export type Social = 'vk' | 'telegram' | 'ok' | 'gmail' | 'yandex' | 'mail' | 'steam';
export type SignUpMethod = Social | 'fast';
export const availableSocial: Social[] = ['vk', 'telegram', 'ok', 'gmail', 'yandex', 'mail', 'steam'];

// export const telegramSuppotLink = 'https://t.me/rysebet_support';
export const telegramSuppotLink = 'https://t.me/rysebet_operator';
export const telegramLink = 'https://t.me/rysebett';
export const domainName = window.location.host === 'rimabet.com'
  ? 'rysebet.com'
  : window.location.host;
export const logoText = 'RYSEBET';
export const TRANSACTIONS_ITEMS_PER_PAGE = 20;
export const BETS_ITEMS_PER_PAGE = 10;
export const FAV_ITEMS_PER_PAGE = 10;
export const COUPON_ITEMS_PER_PAGE = 10;

export const instagramLink = 'https://www.instagram.com/rysebet/';

export const footerNavItems = [
  {
    groupTitle: i18n.t('footer.nav.title.Information', 'Информация'),
    items: [
      {
        name: 'Пользовательское соглашение',
        link: '/terms'
      },
      {
        name: 'Партнерская программа',
        link: '/partners',
      },
      {
        name: 'Политика конфиденциальности',
        link: '/privacy',
      },
      {
        name: 'Общие положения и условия',
        link: '/rules',
      },
      {
        name: i18n.t('footer.nav.item.Bonus and actions', 'Бонусы и Акции'),
        link: '/bonus',
      },
      {
        name: 'support@rysebet.com',
        href: 'mailto:support@rysebet.com',
      },
    ]
  },
  {
    groupTitle: i18n.t('footer.nav.title.Categories', 'Категории'),
    items: [
      {
        name: i18n.t('footer.nav.item.Live', 'Live'),
        link: '/live'
      },
      {
        name: i18n.t('footer.nav.item.Line', 'Линия'),
        link: '/line'
      },
      // {
      //   name: 'Слоты',
      //   link: '/slots'
      // },
    ]
  },
];

export const getFooterItems = () => [
  {
    groupTitle: i18n.t('footer.nav.title.Information', 'Информация'),
    items: [
      {
        name: i18n.t('footer.nav.item.agreement', 'Пользовательское соглашение'),
        link: '/terms'
      },
      {
        name: i18n.t('footer.nav.item.partner-program', 'Партнерская программа'),
        link: '/partners',
      },
      {
        name: i18n.t('footer.nav.item.privacy', 'Политика конфиденциальности'),
        link: '/privacy',
      },
      {
        name: i18n.t('footer.nav.item.rules-terms', 'Общие положения и условия'),
        link: '/rules',
      },
      {
        name: i18n.t('footer.nav.item.Bonus and actions', 'Бонусы и Акции'),
        link: '/bonus',
      },
      {
        name: 'support@rysebet.com',
        href: 'mailto:support@rysebet.com',
      },
    ]
  },
  {
    groupTitle: i18n.t('footer.nav.title.Categories', 'Категории'),
    items: [
      {
        name: i18n.t('footer.nav.item.Live', 'Live'),
        link: '/live'
      },
      {
        name: i18n.t('footer.nav.item.Line', 'Линия'),
        link: '/line'
      },
      {
        name: i18n.t('footer.nav.item.Casino', 'Слоты и Казино'),
        link: '/games'
      },
    ]
  },
];

export const socialLinks = [
  { type: 'telegram', link: telegramLink },
  // { type: 'vkontakte', link: '' },
  { type: 'instagram', link: instagramLink },
];

export const downloadApp = {
  ios: undefined,
  android: '/rysebet.apk'
};

export const topTournamentsID = [
  '88637', // Чемпионат Англии. Премьер-лига
  '12821', // Чемпионат Франции. Первая лига
  '110163', // Чемпионат Италии. Серия А
  '127733', // Чемпионат Испании. Примера
  '96463', // Чемпионат Германии. Бундеслига
  '30619', // NHL
  '1246295', // NHL. Награды
  '1364013', // NHL. Итоги чемпионата
  '1890662', // NHL. Специальные ставки
  '2535190',
  '466371',
  '534531',
  '2549929',
  '1189035',
  '1190421',
  '2402865',
  '2543195',
  '2549933',
  '7456',
  '120925',
  '2549403',
  '59629',
  '2543176',
  '1501475',
  '1503583',
  '2549402'
];

export const topCountriesId = [
  '225', // Мир
  '223', // Европа
  '231', // Англия
  '198', // Франция
  '79', // Италия
  '78', // Испания
  '53', // Германия
  '1', // Россия
  '2', // Украина
];

export const topSportsId = [
  '1', // Футбол
  '2', // Баскетбол
  '3' // Хоккей
];

export const batgamesList: GameCardItemType[] = [
  {
    id: 5,
    name: i18n.t('games.betgames.Bet-On-Poker', 'Покер'),
    preview: betGamesPreviews.poker,
    link: '/games/betgames/5',
  },
  {
    id: 17,
    name: i18n.t('games.betgames.Football-Grid', 'Пенальти'),
    preview: betGamesPreviews.football,
    link: '/games/betgames/17',
  },
  {
    id: 7,
    name: i18n.t('games.betgames.Wheel-Of-Fortune', 'Колесо Фортуны'),
    preview: betGamesPreviews.wheel,
    link: '/games/betgames/7',
  },
  {
    id: 12,
    name: i18n.t('games.betgames.6+-Poker', '6+ Покер'),
    preview: betGamesPreviews.poker6,
    link: '/games/betgames/12',
  },
  {
    id: 13,
    name: i18n.t('games.betgames.Andar-Bahar', 'Андар Бахар'),
    preview: betGamesPreviews.anderBaha,
    link: '/games/betgames/13',
  },
  {
    id: 10,
    name: i18n.t('games.betgames.Dice-Duel', 'Дуэль Костей'),
    preview: betGamesPreviews.diceDuel,
    link: '/games/betgames/10',
  },
  {
    id: 11,
    name: i18n.t('games.betgames.Speedy-7', 'Горячая 7'),
    preview: betGamesPreviews.speedy7,
    link: '/games/betgames/11',
  },
  {
    id: 8,
    name: i18n.t('games.betgames.War-Of-Bets', 'Битва Ставок'),
    preview: betGamesPreviews.warOfBets,
    link: '/games/betgames/8',
  },
  {
    id: 6,
    name: i18n.t('games.betgames.Bet-On-Baccarat', 'Баккара'),
    preview: betGamesPreviews.baccarat,
    link: '/games/betgames/6',
  },
  {
    id: 16,
    name: i18n.t('games.betgames.Classic-Wheel', 'Колесо Фортуны Классическое'),
    preview: betGamesPreviews.wheelRNG,
    link: '/games/betgames/16',
  },
  {
    id: 18,
    name: i18n.t('games.betgames.Satta-Matka', 'Сатта Матка'),
    preview: betGamesPreviews.sattaMatka,
    link: '/games/betgames/18',
  },
  {
    id: 3,
    name: i18n.t('games.betgames.Lucky-5', '5 из 36'),
    preview: betGamesPreviews.lucky5,
    link: '/games/betgames/3',
  },
  {
    id: 9,
    name: i18n.t('games.betgames.Lucky-6', '6 из 60'),
    preview: betGamesPreviews.lucky6,
    link: '/games/betgames/9',
  },
  {
    id: 1,
    name: i18n.t('games.betgames.Lucky-7', '7 из 42'),
    preview: betGamesPreviews.lucky7,
    link: '/games/betgames/1',
  },
  {
    id: 26,
    name: 'Instant Lucky 7',
    preview: betGamesPreviews.instantLucky7,
    link: '/games/betgames/26',
  },
];

export const twainSportList: GameCardItemType[] = [
  {
    id: 24,
    name: i18n.t('games.betgames.T-Kick-24/7', 'Футбол'),
    preview: {
      src: tKickPreview,
      webp: tKickPreviewWebp,
    },
    link: '/games/twain-sport/24',
  },
  {
    id: 25,
    name: i18n.t('games.betgames.T-Basket-24/7', 'Баскетбол'),
    preview: {
      src: tBasketPreview,
      webp: tBasketPreviewWebp,
    },
    link: '/games/twain-sport/25',
  },
];

export const crashGames: GameCardItemType[] = [
  {
    id: 27,
    name: 'JetX',
    preview: `${process.env.PUBLIC_URL}/smartsoft/JetX.jpg`,
    link: '/games/smartsoft/JetX/JetX',
  },
  {
    id: 'aviatrix',
    name: 'Aviatrix',
    preview: `${process.env.PUBLIC_URL}/vegas-club-preview/aviatrix_ax.jpg`,
    link: '/games/aviatrix',
  },
  {
    id: 'aviator_spribe',
    name: 'Aviator',
    preview: `${process.env.PUBLIC_URL}/vegas-club-preview/aviator_spribe.jpg`,
    link: '/games/vegas/aviator_spribe',
  },
  {
    id: 27,
    name: 'Skyward',
    preview: {
      src: crashGameSkyward,
      webp: crashGameSkywardWebp,
    },
    link: '/games/betgames/27',
  },
];

export const tvbetList: GameCardItemType[] = [
  {
    id: 10,
    name: '1Bet',
    preview: tvBetPreviews.onebet,
    link: '/games/tvbet/10'
  },
  {
    id: 12,
    name: 'Lucky6',
    preview: tvBetPreviews.lucky6,
    link: '/games/tvbet/12'
  },
  {
    id: 2,
    name: 'Wheelbet',
    preview: tvBetPreviews.wheel,
    link: '/games/tvbet/2'
  },
  {
    id: 6,
    name: '5Bet',
    preview: tvBetPreviews.bet5,
    link: '/games/tvbet/6'
  },
  {
    id: 7,
    name: '7Bet',
    preview: tvBetPreviews.bet7,
    link: '/games/tvbet/7'
  },
  {
    id: 9,
    name: 'Keno',
    preview: tvBetPreviews.keno,
    link: '/games/tvbet/9'
  },
  {
    id: 23,
    name: 'Fast Keno',
    preview: tvBetPreviews.fastkeno,
    link: '/games/tvbet/23'
  },
  {
    id: 19,
    name: 'BlackJack',
    preview: tvBetPreviews.blackjack,
    link: '/games/tvbet/19'
  },
  {
    id: 3,
    name: 'Poker',
    preview: tvBetPreviews.poker,
    link: '/games/tvbet/3'
  },
  {
    id: 5,
    name: 'War of Elements',
    preview: tvBetPreviews.warofElements,
    link: '/games/tvbet/3'
  },
  {
    id: 22,
    name: 'Roulette',
    preview: tvBetPreviews.roulette,
    link: '/games/tvbet/22'
  },
];

export const fastsportList: GameCardItemType[] = [
  {
    id: 51,
    name: 'Short Football',
    preview: fastsportFootball,
    link: '/games/fastsport/51'
  },
  {
    id: 52,
    name: 'Table tennis',
    preview: fastsportTennis,
    link: '/games/fastsport/52'
  },
  {
    id: 53,
    name: 'Cyberfootball',
    preview: fastsportCyberFootball,
    link: '/games/fastsport/53'
  },
];

export const basketErrors: Record<string, string> = {
  default: i18n.t('backend-errors.basket.default', 'Ошибка постановки ставки'),
  notEnoughMoney: i18n.t('backend-errors.basket.not-enough-money', 'Недостаточно средств'),
  eventBlocked: i18n.t('backend-errors.basket.event-blocked', 'Одно из событий заблокировано или недоступно'),
  coefHasChanged: i18n.t('backend-errors.basket.coef-has-changed', 'Коэффицент одной из ставок изменился'),
  'bet coef has changed': i18n.t('backend-errors.basket.coef-has-changed', 'Коэффицент одной из ставок изменился'),
  'coef has changed': i18n.t('backend-errors.basket.coef-has-changed', 'Коэффицент одной из ставок изменился'),
  'bet is blocked': i18n.t('backend-errors.basket.bet-is-blocked', 'Одна из ставок заблокирована'),
  'event not available': i18n.t('backend-errors.basket.event-not-available', 'Одно из событий не доступно'),
  'Repeated bet': i18n.t('backend-errors.basket.repeated-bet', 'Вы уже делали ставку на данный исход. Повторные ставки запрещены.'),
  'Not enough money': i18n.t('backend-errors.basket.not-enough-money', 'Недостаточно средств'),
  'Event has finished or is not available': i18n.t('backend-errors.bet.event-has-finished-or-is-not-available', 'Событие завершилось или недоступно'),
  'Coef has changed': i18n.t('backend-errors.basket.coef-has-changed', 'Коэффицент одной из ставок изменился'),
  'Basket is empty': 'Корзина пуста',
  'Bet amount limited': 'Сумма ставки ограничена'
};

export const addToBasketErrors: Record<string, string> = {
  default: i18n.t('backend-errors.bet.default', 'Неизвестная ошибка'),
  'Bet outcome is blocked': i18n.t('backend-errors.bet.bet-outcome-is-blocked', 'Ставка заблокирована'),
  'Item already in basket': i18n.t('backend-errors.bet.item-already-in-basket', 'Ставка уже добавлена в корзину'),
  'Multiple bets for same event': i18n.t('backend-errors.bet.multiple-bets-for-same-event', 'Другая ставка на это событие уже в корзине'),
  'Event has finished or is not available': i18n.t('backend-errors.bet.event-has-finished-or-is-not-available', 'Событие завершилось или недоступно'),
  'Repeated bet': i18n.t('backend-errors.bet.repeated-bet', 'Вы уже делали ставку на данный исход. Повторные ставки запрещены.'),
};

export const defaultRuPhoneCode = {
  code: '+7',
  country: 'RU',
};

export const phoneCountryCodes = [
  {
    code: '+1',
    country: 'US',
  },
  {
    code: '+1',
    country: 'CA',
  },
  {
    code: '+1242',
    country: 'BS',
  },
  {
    code: '+1246',
    country: 'BB',
  },
  {
    code: '+1264',
    country: 'AI',
  },
  {
    code: '+1268',
    country: 'AG',
  },
  {
    code: '+1284',
    country: 'VG',
  },
  {
    code: '+1340',
    country: 'VI',
  },
  {
    code: '+1345',
    country: 'KY',
  },
  {
    code: '+1441',
    country: 'BM',
  },
  {
    code: '+1473',
    country: 'GD',
  },
  {
    code: '+1649',
    country: 'TC',
  },
  {
    code: '+1658',
    country: 'JM',
  },
  {
    code: '+1664',
    country: 'MS',
  },
  {
    code: '+1670',
    country: 'MP',
  },
  {
    code: '+1671',
    country: 'GU',
  },
  {
    code: '+1684',
    country: 'AS',
  },
  {
    code: '+1721',
    country: 'SX',
  },
  {
    code: '+1758',
    country: 'LC',
  },
  {
    code: '+1767',
    country: 'DM',
  },
  {
    code: '+1784',
    country: 'VC',
  },
  {
    code: '+1787',
    country: 'PR',
  },
  {
    code: '+1809',
    country: 'DO',
  },
  {
    code: '+1829',
    country: 'DO',
  },
  {
    code: '+1849',
    country: 'DO',
  },
  {
    code: '+1868',
    country: 'TT',
  },
  {
    code: '+1869',
    country: 'KN',
  },
  {
    code: '+1876',
    country: 'JM',
  },
  {
    code: '+1939',
    country: 'PR',
  },
  {
    code: '+20',
    country: 'EG',
  },
  {
    code: '+211',
    country: 'SS',
  },
  {
    code: '+212',
    country: 'MA',
  },
  {
    code: '+212',
    country: 'EH',
  },
  {
    code: '+213',
    country: 'DZ',
  },
  {
    code: '+216',
    country: 'TN',
  },
  {
    code: '+218',
    country: 'LY',
  },
  {
    code: '+220',
    country: 'GM',
  },
  {
    code: '+221',
    country: 'SN',
  },
  {
    code: '+222',
    country: 'MR',
  },
  {
    code: '+223',
    country: 'ML',
  },
  {
    code: '+224',
    country: 'GN',
  },
  {
    code: '+225',
    country: 'CI',
  },
  {
    code: '+226',
    country: 'BF',
  },
  {
    code: '+227',
    country: 'NE',
  },
  {
    code: '+228',
    country: 'TG',
  },
  {
    code: '+229',
    country: 'BJ',
  },
  {
    code: '+230',
    country: 'MU',
  },
  {
    code: '+231',
    country: 'LR',
  },
  {
    code: '+232',
    country: 'SL',
  },
  {
    code: '+233',
    country: 'GH',
  },
  {
    code: '+234',
    country: 'NG',
  },
  {
    code: '+235',
    country: 'TD',
  },
  {
    code: '+236',
    country: 'CF',
  },
  {
    code: '+237',
    country: 'CM',
  },
  {
    code: '+238',
    country: 'CV',
  },
  {
    code: '+239',
    country: 'ST',
  },
  {
    code: '+240',
    country: 'GQ',
  },
  {
    code: '+241',
    country: 'GA',
  },
  {
    code: '+242',
    country: 'CG',
  },
  {
    code: '+243',
    country: 'CD',
  },
  {
    code: '+244',
    country: 'AO',
  },
  {
    code: '+245',
    country: 'GW',
  },
  {
    code: '+246',
    country: 'IO',
  },
  {
    code: '+247',
    country: 'AC', // Не страна
  },
  {
    code: '+248',
    country: 'SC',
  },
  {
    code: '+249',
    country: 'SD',
  },
  {
    code: '+250',
    country: 'RW',
  },
  {
    code: '+251',
    country: 'ET',
  },
  {
    code: '+252',
    country: 'SO',
  },
  {
    code: '+253',
    country: 'DJ',
  },
  {
    code: '+254',
    country: 'KE',
  },
  {
    code: '+255',
    country: 'TZ',
  },
  {
    code: '+256',
    country: 'UG',
  },
  {
    code: '+257',
    country: 'BI',
  },
  {
    code: '+258',
    country: 'MZ',
  },
  {
    code: '+260',
    country: 'ZM',
  },
  {
    code: '+261',
    country: 'MG',
  },
  {
    code: '+262',
    country: 'RE',
  },
  {
    code: '+262',
    country: 'YT',
  },
  {
    code: '+262',
    country: 'TF',
  },
  {
    code: '+263',
    country: 'ZW',
  },
  {
    code: '+264',
    country: 'NA',
  },
  {
    code: '+265',
    country: 'MW',
  },
  {
    code: '+266',
    country: 'LS',
  },
  {
    code: '+267',
    country: 'BW',
  },
  {
    code: '+268',
    country: 'SZ',
  },
  {
    code: '+269',
    country: 'KM',
  },
  {
    code: '+27',
    country: 'ZA',
  },
  {
    code: '+290',
    country: 'SH',
  },
  {
    code: '+290',
    country: 'TA',
  },
  {
    code: '+291',
    country: 'ER',
  },
  {
    code: '+297',
    country: 'AW',
  },
  {
    code: '+298',
    country: 'FO',
  },
  {
    code: '+299',
    country: 'GL',
  },
  {
    code: '+30',
    country: 'GR',
  },
  {
    code: '+31',
    country: 'NL',
  },
  {
    code: '+32',
    country: 'BE',
  },
  {
    code: '+33',
    country: 'FR',
  },
  {
    code: '+34',
    country: 'ES',
  },
  {
    code: '+350',
    country: 'GI',
  },
  {
    code: '+351',
    country: 'PT',
  },
  {
    code: '+352',
    country: 'LU',
  },
  {
    code: '+353',
    country: 'IE',
  },
  {
    code: '+354',
    country: 'IS',
  },
  {
    code: '+355',
    country: 'AL',
  },
  {
    code: '+356',
    country: 'MT',
  },
  {
    code: '+357',
    country: 'CY',
  },
  {
    code: '+358',
    country: 'FI',
  },
  {
    code: '+358',
    country: 'AX',
  },
  {
    code: '+359',
    country: 'BG',
  },
  {
    code: '+36',
    country: 'HU',
  },
  {
    code: '+370',
    country: 'LT',
  },
  {
    code: '+371',
    country: 'LV',
  },
  {
    code: '+372',
    country: 'EE',
  },
  {
    code: '+373',
    country: 'MD',
  },
  {
    code: '+374',
    country: 'AM',
  },
  {
    code: '+375',
    country: 'BY',
  },
  {
    code: '+376',
    country: 'AD',
  },
  {
    code: '+377',
    country: 'MC',
  },
  {
    code: '+378',
    country: 'SM',
  },
  {
    code: '+379',
    country: 'VA',
  },
  {
    code: '+380',
    country: 'UA',
  },
  {
    code: '+381',
    country: 'RS',
  },
  {
    code: '+382',
    country: 'ME',
  },
  {
    code: '+383',
    country: 'XK',
  },
  {
    code: '+385',
    country: 'HR',
  },
  {
    code: '+386',
    country: 'SI',
  }, {
    code: '+387',
    country: 'BA',
  },
  {
    code: '+388',
    country: 'EU', // ! Европейская система телефонной нумерации
  },
  {
    code: '+389',
    country: 'MK',
  },
  {
    code: '+39',
    country: 'IT',
  },
  {
    code: '+39',
    country: 'VA',
  },
  {
    code: '+40',
    country: 'RO',
  }, {
    code: '+41',
    country: 'CH',
  },
  {
    code: '+420',
    country: 'CZ',
  },
  {
    code: '+421',
    country: 'SK',
  }, {
    code: '+423',
    country: 'LI',
  },
  {
    code: '+43',
    country: 'AT',
  },
  {
    code: '+44',
    country: 'UK',
  }, {
    code: '+44',
    country: 'GG',
  },
  {
    code: '+44',
    country: 'IM',
  },
  {
    code: '+44',
    country: 'JE',
  }, {
    code: '+45',
    country: 'DK',
  },
  {
    code: '+46',
    country: 'SE',
  },
  {
    code: '+47',
    country: 'NO',
  }, {
    code: '+47',
    country: 'SJ',
  },
  {
    code: '+47',
    country: 'BV',
  },
  {
    code: '+48',
    country: 'PL',
  }, {
    code: '+49',
    country: 'DE',
  },
  {
    code: '+500',
    country: 'FK',
  },
  {
    code: '+500',
    country: 'GS',
  },
  {
    code: '+501',
    country: 'BZ',
  }, {
    code: '+502',
    country: 'GT',
  },
  {
    code: '+503',
    country: 'SV',
  },
  {
    code: '+504',
    country: 'HN',
  }, {
    code: '+505',
    country: 'NI',
  },
  {
    code: '+506',
    country: 'CR',
  },
  {
    code: '+507',
    country: 'PA',
  }, {
    code: '+508',
    country: 'PM',
  },
  {
    code: '+509',
    country: 'HT',
  },
  {
    code: '+51',
    country: 'PE',
  },
  {
    code: '+52',
    country: 'MX',
  },
  {
    code: '+53',
    country: 'CU',
  },
  {
    code: '+54',
    country: 'AR',
  }, {
    code: '+55',
    country: 'BR',
  },
  {
    code: '+56',
    country: 'CL',
  },
  {
    code: '+57',
    country: 'CO',
  }, {
    code: '+58',
    country: 'VE',
  },
  {
    code: '+590',
    country: 'GP',
  },
  {
    code: '+590',
    country: 'BL',
  }, {
    code: '+590',
    country: 'MF',
  },
  {
    code: '+591',
    country: 'BO',
  },
  {
    code: '+592',
    country: 'GY',
  }, {
    code: '+593',
    country: 'EC',
  },
  {
    code: '+594',
    country: 'GF',
  },
  {
    code: '+595',
    country: 'PY',
  }, {
    code: '+596',
    country: 'MQ',
  },
  {
    code: '+597',
    country: 'SR',
  },
  {
    code: '+598',
    country: 'UY',
  }, {
    code: '+599',
    country: 'BQ',
  },
  {
    code: '+599',
    country: 'CW',
  },
  {
    code: '+60',
    country: 'MY',
  }, {
    code: '+61',
    country: 'AU',
  },
  {
    code: '+61',
    country: 'CX',
  },
  {
    code: '+61',
    country: 'CC',
  }, {
    code: '+62',
    country: 'ID',
  },
  {
    code: '+63',
    country: 'PH',
  },
  {
    code: '+64',
    country: 'NZ',
  }, {
    code: '+64',
    country: 'PN',
  },
  {
    code: '+65',
    country: 'SG',
  },
  {
    code: '+66',
    country: 'TH',
  }, {
    code: '+670',
    country: 'TL',
  },
  {
    code: '+672',
    country: 'AQ',
  },
  {
    code: '+672',
    country: 'NF',
  }, {
    code: '+672',
    country: 'HM',
  },
  {
    code: '+673',
    country: 'BN',
  },
  {
    code: '+674',
    country: 'NR',
  }, {
    code: '+675',
    country: 'PG',
  },
  {
    code: '+676',
    country: 'TO',
  },
  {
    code: '+677',
    country: 'SB',
  }, {
    code: '+678',
    country: 'VU',
  },
  {
    code: '+679',
    country: 'FJ',
  },
  {
    code: '+680',
    country: 'PW',
  }, {
    code: '+681',
    country: 'WF',
  },
  {
    code: '+682',
    country: 'CK',
  },
  {
    code: '+683',
    country: 'NU',
  }, {
    code: '+685',
    country: 'WS',
  },
  {
    code: '+686',
    country: 'KI',
  },
  {
    code: '+687',
    country: 'NC',
  }, {
    code: '+688',
    country: 'TV',
  },
  {
    code: '+689',
    country: 'PF',
  },
  {
    code: '+690',
    country: 'TK',
  }, {
    code: '+691',
    country: 'FM',
  },
  {
    code: '+692',
    country: 'MH',
  },
  {
    code: '+7',
    country: 'RU',
  },
  {
    code: '+7',
    country: 'KZ',
  },
  {
    code: '+81',
    country: 'JP',
  },
  {
    code: '+82',
    country: 'KR',
  },
  {
    code: '+84',
    country: 'VN',
  },
  {
    code: '+850',
    country: 'KP',
  },
  {
    code: '+852',
    country: 'HK',
  },
  {
    code: '+853',
    country: 'MO',
  }, {
    code: '+855',
    country: 'KH',
  },
  {
    code: '+856',
    country: 'LA',
  },
  {
    code: '+86',
    country: 'CH',
  },
  {
    code: '+880',
    country: 'BD',
  },
  {
    code: '+886',
    country: 'TW',
  }, {
    code: '+90',
    country: 'TR',
  },
  {
    code: '+91',
    country: 'IN',
  },
  {
    code: '+92',
    country: 'PK',
  },
  {
    code: '+93',
    country: 'AF',
  }, {
    code: '+94',
    country: 'LK',
  },
  {
    code: '+95',
    country: 'MM',
  },
  {
    code: '+960',
    country: 'MV',
  }, {
    code: '+961',
    country: 'LB',
  },
  {
    code: '+962',
    country: 'JO',
  },
  {
    code: '+963',
    country: 'SY',
  },
  {
    code: '+964',
    country: 'IQ',
  }, {
    code: '+965',
    country: 'KW',
  },
  {
    code: '+966',
    country: 'SA',
  },
  {
    code: '+967',
    country: 'YE',
  }, {
    code: '+968',
    country: 'OM',
  },
  {
    code: '+970',
    country: 'PS',
  },
  {
    code: '+971',
    country: 'AE',
  },
  {
    code: '+972',
    country: 'IL',
  }, {
    code: '+973',
    country: 'BH',
  },
  {
    code: '+974',
    country: 'QA',
  },
  {
    code: '+975',
    country: 'BT',
  }, {
    code: '+976',
    country: 'MN',
  },
  {
    code: '+977',
    country: 'NP',
  },
  {
    code: '+98',
    country: 'IR',
  },
  {
    code: '+992',
    country: 'TJ',
  },
  {
    code: '+993',
    country: 'TM',
  }, {
    code: '+994',
    country: 'AZ',
  },
  {
    code: '+995',
    country: 'GE',
  },
  {
    code: '+996',
    country: 'KG',
  },
  {
    code: '+997',
    country: 'KZ',
  }, {
    code: '+998',
    country: 'UZ',
  },
];
