import React from 'react';
import { useTranslation } from 'react-i18next';

import { bemCn } from 'utils/bem-cn';

import Button from '../buttons/Button/Button';
import AppModal from '../AppModal/AppModal';
import './TelegramModal.scss';

type Props = {
  isOpen: boolean;
  onClose: () => void;
};

const TelegramModal = ({ isOpen, onClose }: Props) => {
  const { t } = useTranslation();
  const b = bemCn('telegram-modal');

  return (
    <AppModal className={b()} isOpen={isOpen} onClose={onClose} >
      <div className={b('icon')} />
      <div className={b('title')}>
        {t('telegram-modal.title')}
      </div>
      <p className={b('description')}>
        {t('telegram-modal.description')}
      </p>
      <a className={b('link')} href='' target='_blank' rel="noreferrer">
        <Button className={b('button')} variant='secondary' >
          {t('telegram-modal.subscribe-btn', 'Подписаться')}
        </Button>
      </a>
    </AppModal>
  );
};

export default TelegramModal;
