import React from 'react';
import { Link } from 'react-router-dom';

import Breadcrumbs from 'components/shared/Breadcrumbs/Breadcrumbs';
import TextContent from 'components/shared/TextContent/TextContent';

import StaticPages from '../StaticPages';

const Bonus = [
  {
    data:
      <>
        <h2>
          Фрибет 1000р
          для новых пользователей букмекерской компании RYSEBET.
        </h2>
        <p><b>Как получить фрибет?</b></p>
        <p>
          1. Пройди регистрацию на сайте <Link to="/" className='static-pages__link'>rysebet.com</Link>
        </p>
        <p>
          2. Сделай депозит. Пополни свой счёт единовременным платежом на
          сумму от 2000рублей и получи на свой основной счет дополнительных 1000р. Для получения, пиши оператору в чат «Хочу фрибет»:
          <a href="https://t.me/rysebet_operator" target="_blank" className='static-pages__link' rel="noreferrer">https://t.me/rysebet_operator</a>
        </p>
        <p><i>* Деньги для фрибета начисляются Вам на основной счет, а не на бонусный и процедура отыграша максимально простая.</i></p>
        <p><b>Для того, чтобы их вывести нужно их отыграть. Процедура отыграша:</b></p>
        <p>1. Нужно проставить сумму, которая у Вас на аккаунте два раза. </p>
        <p>2. Ставки «ординары», «лайв» и «экспрессы»</p>
        <p>3. Коэффициент от 1.8+ </p>
        <p>
          Если пополнили счет на 2000р, то Вы получаете еще 1000р фрибета. На счету 3000р и Вам их нужно проставить два раза. Или полностью, или частично. Можно ставить и по 100-200р, это решаете Вы сами.
          Если сумма пополнения выше 5000р, то отыграть Вам нужно только фрибет, а не всю сумму.
        </p>
        <p>Примечание: </p>
        <p>- Фрибет доступен только новым пользователям.</p>
        <p>- Отыграные деньги становятся моментально доступны для вывода. </p>
        <p>
          - Компания оставляет за собой право в любой момент
          акции провести дополнительную верификацию владельца
          аккаунта или ограничить участие игрока в данной акции.
        </p>
        <p>
          По всем вопросам: <b><a href="https://t.me/rysebet_operator" target="_blank" className='static-pages__link' rel="noreferrer">https://t.me/rysebet_operator</a></b>
        </p>
        <p>
          Наша почта: <b><a href="mailto:support@rysebet.com" className='static-pages__link'>support@rysebet.com</a></b>
        </p>
      </>,
    id: 2
  }
];

const BonusPage = () => (
  <div>
    <Breadcrumbs title="На главную" link="/" />
    <StaticPages>
      <h1 className='static-pages__title'>Бонусы и Акции</h1>
      {Bonus.map((item) => (
        <TextContent key={item.id} className='static-pages__bonus-item'>
          {item.data}
        </TextContent>
      ))}
    </StaticPages>
  </div>
);

export default BonusPage;
