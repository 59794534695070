import React from 'react';
import { useTranslation } from 'react-i18next';
import { useToggle } from 'usehooks-ts';

import Button from 'components/shared/buttons/Button/Button';
import { getCurrencySymbol } from 'utils/currency';
import { getDateTimeSlashFormat } from 'utils/time';
import { domainName } from 'utils/constants';
import { ReactComponent as WinIcon } from 'assets/bets/win.svg';
import { ReactComponent as LossIcon } from 'assets/bets/defeat.svg';
import { ReactComponent as PlacedIcon } from 'assets/bets/inProcess.svg';
import { bemCn } from 'utils/bem-cn';
import i18n from 'i18n/i18n';

import type { PlaceType, BetStatus, Coupon, } from 'types/user-data';

import './CouponCardDesktop.scss';

const b = bemCn('coupon-card-desktop');

const getBetStatusIcon = (betStatus: string) => {
  switch (betStatus) {
    case 'win':
      return <WinIcon className={b('bet-status-icon')} />;
    case 'loss':
      return <LossIcon className={b('bet-status-icon')} />;
    case 'no_result':
    case 'placed':
      return <PlacedIcon className={b('bet-status-icon')} />;
  }
  return null;
};

const typeTitles: Record<PlaceType, string> = {
  ordinar: i18n.t('bet-history.single', 'Ординар'),
  express: i18n.t('bet-history.express', 'Экспресс'),
};

const statusTitles: Record<BetStatus, string> = {
  win: i18n.t('bet-history.win', 'Выигрыш'),
  placed: i18n.t('bet-history.placed', 'Ожидание'),
  loss: i18n.t('bet-history.loss', 'Проигрыш'),
  refund: i18n.t('bet-history.refund', 'Возврат')
};

const statusItemTitles: Record<string, string> = {
  'no_result': i18n.t('bet-history.no_result', 'Ожидание'),
  'placed': i18n.t('bet-history.no_result', 'Ожидание'),
  'loss': i18n.t('bet-history.loss', 'Проигрыш'),
  'win': i18n.t('bet-history.win', 'Выигрыш'),
  'refund': i18n.t('bet-history.refund', 'Возврат')
};

type Props = {
  coupon: Coupon;
};

const CouponCardDesktop = ({ coupon }: Props) => {
  const { t } = useTranslation();

  const {
    id,
    type,
    winAmount,
    created,
    betAmount,
    currency,
    status,
    bets,
    coef: totalCoef,
    expectedWinAmount,
    balanceType,
  } = coupon;

  const [isOpenBets, toggleOpenBets] = useToggle(false);
  const isWin = status === 'win';
  const isPlaced = status === 'placed';
  const isLoss = status === 'loss';
  const isRefund = status === 'refund';
  const isBonusWallet = balanceType === 'bonus';


  return (
    <div className={b({ bonus: isBonusWallet })}>
      <div className={b('wrapper')} role="button" onClick={toggleOpenBets}>
        <p className={b('type')}>{typeTitles[type]}</p>
        <Button className={b('total-coef')} variant="secondary">
          {totalCoef?.toFixed(2)}
        </Button>
        <p className={b('date')}>{getDateTimeSlashFormat(created)}</p>
        <p className={b('id')}>{`№ ${id}`}</p>
        <p className={b('bet-amount')}>
          {`${betAmount} ${getCurrencySymbol(currency)}`}
        </p>
        {isBonusWallet ? (
          <p className={b('bonus-wallet-mark')}>
            {t('wallets.bonus-wallet', 'Бонусный счет')}
          </p>
        ) : null}
        <p className={b('status', { [`${status}`]: true })}>
          {statusTitles[status]}
        </p>
      </div>
      {isOpenBets && (
        <div className={b('list')}>
          <div className={b('site')}>{domainName}</div>
          {bets?.map((item) => (
            <div key={item.barcode} className={b('bet')}>
              <div className={b('item-info')}>
                <p className={b('item-sport')}>
                  {item.sportName && `${item.sportName} • `}
                  {item.tournament}
                </p>
                <p className={b('item-teams')}>{item.team1} - {item.team2}</p>
              </div>
              <p className={b('item-date')}>
                {getDateTimeSlashFormat(item.gameDatetime)}
              </p>
              <div className={b('item-main')}>
                <p className={b('item-name-game')}>{item.subGameName}</p>
                <p className={b('item-name-game')}>{item.outcomeName}</p>
                <p className={b('item-name-game')}>
                  {getBetStatusIcon(item.status)}
                  {item.coef}
                </p>
                {item.status === 'no_result' || item.status === 'placed'
                  ? (
                    <p className={b('item-result', { [`${item.status}`]: true })}>
                      {statusItemTitles[item.status]}
                    </p>
                  ) : (
                    <div className={b('item-score', { [`${item.status}`]: true })}>
                      <p className={b('item-score-total', { [`${item.status}`]: true })}>
                        {item.gameScore.length > 0 && item.gameScore !== '-:-' && item.gameScore.split('(')[0]}
                      </p>
                      <p className={b('item-score-periods')}>
                        {item.gameScore.length > 0 && item.gameScore !== '-:-' && `(${item.gameScore.split('(')[1]}`}
                      </p>
                    </div>
                  )}
              </div>
            </div>
          ))}
          <div className={b('totals')}>
            <span className={b('totals-status', { [`${status}`]: Boolean(status) })}>
              {isPlaced && `${t('bet-history.possible-prize', 'Возможный выигрыш')}:`}
              {(isWin || isLoss) && `${t('bet-history.prize', 'Выигрыш')}:`}
              {isRefund && `${t('bet-history.refund', 'Возврат')}:`}
            </span>
            <span>
              {isPlaced && `${expectedWinAmount ?? 0} ${getCurrencySymbol(currency)}`}
              {(isWin || isLoss) && `${winAmount ?? 0} ${getCurrencySymbol(currency)}`}
              {isRefund && `${betAmount} ${getCurrencySymbol(currency)}`}
            </span>
            <span>{totalCoef?.toFixed(2)}</span>
          </div>
        </div>
      )}
    </div>
  );
};

export default CouponCardDesktop;
