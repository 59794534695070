import React from 'react';

import Breadcrumbs from 'components/shared/Breadcrumbs/Breadcrumbs';
import TextContent from 'components/shared/TextContent/TextContent';
import { bemCn } from 'utils/bem-cn';
import './TermsPage.scss';

const b = bemCn('terms-page');
const TermsPage = () => (
  <div className={b()}>
    <Breadcrumbs title="На главную" link="/" />
    <TextContent className={b('content')}>
      <h1>Пользовательское соглашение</h1>
      <p>
        <b>ВНИМАТЕЛЬНО ПРОЧИТАЙТЕ ЭТО СОГЛАШЕНИЕ ПЕРЕД ТЕМ КАК ПРИНЯТЬ ЕГО.</b>
      </p>
      <p>
        <b>Пользуясь Услугами RYSEBET Вы подтверждаете следующее:</b>
      </p>
      <ul>
        <li>
          <p>
            Вы вступаете в юридически обязывающее соглашение с нами и принимаете данные Условия и
            Положения;
          </p>
        </li>
        <li>
          <p>
            Вы соглашаетесь со всеми прочими условиями, политиками и правилами применимыми к вам,
            опубликованными на нашем Веб-сайте.
          </p>
        </li>
      </ul>
      <p>
        <b>Пользуясь игровыми услугами Вы также соглашаетесь и подтверждаете следующее:</b>
      </p>
      <ul>
        <li>
          <p>
            Вы достигли минимального возраста, позволяющего пользоваться нашими Игровыми Услугами,
            в соответствии с законодательством штата, провинции или страны вашего проживания;
          </p>
        </li>
        <li>
          <p>Вы не находитесь на Территориях с Ограниченным Доступом;</p>
        </li>
        <li>
          <p>Ваш возраст 18 лет или старше;</p>
        </li>
        <li>
          <p>Вы играете для себя и на свои собственные деньги;</p>
        </li>
        <li>
          <p>Это ваша первая/единственная регистрация на rysebet.com;</p>
        </li>
        <li>
          <p>
            Вы не прибегали к процедуре самоисключения ни на одном из игровых веб-сайтов в течение
            последних 12 месяцев;
          </p>
        </li>
        <li>
          <p>Вы прочитали и принимаете все условия и положения;</p>
        </li>
        <li>
          <p>
            Вы подтверждаете, что вся информация, предоставленная вами компании rysebet.com верная
            и может быть проверена, и Вы понимаете, что предоставление неправдивой информации
            приведет к аннулированию всех выигрышей на rysebet.com;
          </p>
        </li>
        <li>
          <p>
            Вы осведомлены и подтверждаете, что rysebet.com устанавливает определенные ограничения на
            максимальный размер выигрыша, который может быть получен за день/неделю/месяц и/или
            может быть выведен за день/неделю/месяц.
          </p>
        </li>
      </ul>
      <p>
        <b>
          Мы ожидаем, что каждый из наших пользователей будет проявлять ответственность во время
          игры. Однако, мы обращаем ваше внимание на то, что решение пользоваться Услугами целиком
          лежит на Вас, и Вы делаете это исключительно по собственному выбору, усмотрению и на свой
          собственный риск. Пользуясь Игровыми Услугами, Вы подтверждаете, что в полной мере
          осознаете, что существует риск потери денежных средств в процессе игры и Вы несете полную
          ответственность за любые такие потери. Пользуясь Игровыми Услугами, Вы подтверждаете, что
          у Вас нет и не будет каких-либо претензий к нам или к любому из наших партнеров,
          директоров, должностных лиц или сотрудников относительно Вашей игры и проигрышей. Посещая
          любую часть Веб-сайта или открывая игровую учетную запись, Вы соглашаетесь со всеми
          пунктами Пользовательского Соглашения (далее именуемого Соглашением), Политикой
          конфиденциальности, условиями рекламной деятельности, правилами ставок, бонусов и
          специальных предложений доступными на Веб-сайте. Перед принятием условий Соглашения
          необходимо внимательно ознакомиться со всеми его пунктами. Если Вы не согласны с данным
          Соглашением, Вы должны прекратить пользоваться Веб-сайтом. Любое последующее использование
          Веб-сайта будет рассматриваться как Ваше согласие со всеми пунктами данного Соглашения.
        </b>
      </p>
    </TextContent>
  </div>
);

export default TermsPage;
