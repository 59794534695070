import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { useForm } from 'react-hook-form';
import { Link } from 'react-router-dom';

import FormInputWrapper from 'components/shared/form/FormInput';
import FormSelect from 'components/shared/form/FormSelect';
import Button from 'components/shared/buttons/Button/Button';
import FormCheckbox from 'components/shared/form/FormCheckbox';
import ErrorBlock from 'components/shared/inputs/ErrorBlock/ErrorBlock';
import { useEmailRegisterMutation } from 'store/auth/authApi';
import { changeActiveModal } from 'store/auth/authSlice';
import i18n from 'i18n/i18n';
import { useAppDispatch, useAppSelector } from 'hooks';
import { selectRegisterEmailError } from 'store/auth/selectors';
import { getClickId, getRefCode, getWebId } from 'utils/localStorage';

import AddPromoCode from '../AddPromocode/AddPromoCode';

import type { Currency } from 'types/wallets-data';
import type { RegisterEmailErrors } from 'types/auth-data';
import type { Entries } from 'types/common';

export type FormFields = {
  name: string;
  email: string;
  currency: Currency | null;
  password: string;
  checked: boolean;
  promocode: string | undefined;
};

const schema = yup.object({
  email: yup.string()
    .email(`${i18n.t('reg.modal.errors.wrong-email', 'Введите корректный E-mail')}`)
    .required(`${i18n.t('reg.modal.errors.empty-email', 'Введите E-mail')}`),
  name: yup.string()
    .min(3, `${i18n.t('reg.modal.errors.short-user-name', 'Недостаточно символов')}`)
    .required(`${i18n.t('reg.modal.errors.empty-user-name', 'Введите ФИО')}`),
  password: yup.string()
    .min(3, `${i18n.t('reg.modal.errors.short-pass', 'Слишком короткий пароль')}`)
    .required(`${i18n.t('reg.modal.errors.empty-pass', 'Введите пароль')}`),
  checked: yup.bool()
    .oneOf([true], `${i18n.t('reg.modal.errors.check-agreement', 'Подтвердите согласие')}`),
  // TODO: Проверить typeError, зачем он тут нужен и как использовать
  currency: yup.string()
    .required(`${i18n.t('reg.modal.errors.empty-currency', 'Выберите валюту')}`)
    .typeError('Выберите валюту'),
  promocode: yup.string(),
}).required();

type Props = {
  id: number;
  availableCurrencies: Currency[];
  isCurrenciesLoading: boolean;
  userCurrency: string | null;
};

const EmailTab = (props: Props) => {
  const {
    availableCurrencies,
    id,
    userCurrency,
    isCurrenciesLoading
  } = props;
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const [register, { isLoading }] = useEmailRegisterMutation();
  const registerErrors = useAppSelector(selectRegisterEmailError);

  const {
    handleSubmit,
    setError,
    formState: { touchedFields, errors },
    control
  } = useForm<FormFields>({
    defaultValues: { email: '', password: '', currency: userCurrency, checked: false, name: '', promocode: '' },
    mode: 'all',
    resolver: yupResolver(schema),
  });

  useEffect(() => {
    const fieldsErrors: Partial<RegisterEmailErrors> = { ...registerErrors };
    delete fieldsErrors.common;

    const formEntries = Object.entries(fieldsErrors) as Entries<Omit<RegisterEmailErrors, 'common'>>;

    formEntries.forEach(([key, value]) => {
      if (!value) { return; }

      setError(key, {
        type: 'server',
        message: value,
      });
    });
  }, [registerErrors]);

  const onRegSubmit = async (data: FormFields) => {
    const { currency, email, name, password, checked, promocode } = data;
    if (currency && checked) {
      await register({
        currency,
        email,
        name,
        password,
        'ref_code': getRefCode(),
        'click_id': getClickId(),
        'web_id': getWebId(),
        promocode: promocode ? promocode : undefined,
      });

      // console.log({
      //   currency,
      //   email,
      //   name,
      //   password,
      //   'ref_code': getRefCode(),
      //   'click_id': getClickId(),
      //   'web_id': getWebId(),
      //   promocode: promocode ? promocode : undefined,
      // });
    }
  };

  return (
    <form className="registration-modal__form" key={id} onSubmit={handleSubmit(onRegSubmit)}>
      <ErrorBlock isDisplayed={!!registerErrors.common} message={registerErrors.common} />
      <FormSelect<FormFields, Currency>
        name="currency"
        type="currency"
        control={control}
        disabled={isLoading}
        options={availableCurrencies}
        isLoading={isCurrenciesLoading}
        showError
      />
      <FormInputWrapper<FormFields>
        name="email"
        type="text"
        control={control}
        disabled={isLoading}
        placeholder={`${t('reg.modal.email', 'E-mail')}`}
        showError
      />
      <FormInputWrapper<FormFields>
        name="name"
        type="text"
        control={control}
        disabled={isLoading}
        placeholder={`${t('reg.modal.user-name', 'ФИО')}`}
        showError
      />
      <FormInputWrapper<FormFields>
        name="password"
        type="password"
        control={control}
        disabled={isLoading}
        autoComplete="new-password"
        placeholder={`${t('reg.modal.password', 'Пароль')}`}
        showError
      />
      <AddPromoCode<FormFields>
        name="promocode"
        control={control}
        disabled={isLoading}
      />
      <FormCheckbox<FormFields>
        className="registration-modal__accept-rules"
        name="checked"
        control={control}
        disabled={isLoading}
        showError
      >
        {t('reg.modal.agreement')}{' '}
        <Link to="/privacy" className="registration-modal__privacy-policy-link">
          {t('reg.modal.agreement-privacy')}
        </Link>
      </FormCheckbox>

      <Button className='registration-modal__btn'
        type='submit'
        isLoading={isLoading}
      >
        {t('reg.modal.submit-btn', 'Зарегистрироваться')}
      </Button>
      <Button className='registration-modal__btn'
        onClick={() => dispatch(changeActiveModal('sign-in'))}
        variant="outline-primary-inverted"
      >
        {t('reg.modal.sign-in', 'Войти')}
      </Button>
    </form>
  );
};

export default EmailTab;
