import React from 'react';
import 'swiper/css';
import 'swiper/css/pagination';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { changeActiveBasketModal } from 'store/basket/basketSlice';
import { useAppDispatch } from 'hooks';
import BasketItem from 'components/basket/Basket/BasketItem/BasketItem';
import TotalCoef from 'components/line/components/TotalCoef/TotalCoef';
import { bemCn } from 'utils/bem-cn';
import SvgIcon from 'components/shared/SvgIcon/SvgIcon';
import CustomCheckbox from 'components/shared/inputs/CustomCheckbox/CustomCheckbox';

import BetTypeButton from '../components/BetTypeButton/BetTypeButton';
import BasketForm from './BasketForm/BasketForm';
import { useBasket } from './useBasket';

import './Basket.scss';

const defaultCurrency = 'USD';

const Basket = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const {
    currency,
    basketList,
    totalCoef,
    isEmpty,
    isDisabled,
    isOrdinar,
    isExpress,
    betLimits,
    balance,
    bonusBalance,
    handlePlaceBet,
    isPlacing,
    isBonusWallet,
    handleChangeBonusWallet,
  } = useBasket();

  const b = bemCn('basket');
  return (
    <div className={b()}>
      <div className={b('content')}>
        <div className={b('header')}>
          <h2 className={b('title')}>
            {t('cart.title', 'Корзина')}
          </h2>
          {currency && (
            <Link
              to="/bet-history"
              onClick={() => dispatch(changeActiveBasketModal(null))}
              className={b('header-history')}
            >
              {t('cart.bet-history', 'История ставок')}
              <SvgIcon className={b('history-icon')} name='time-machine' width={15} height={15} />
            </Link>
          )}
        </div>
        <div className={b('bet-type')}>
          <BetTypeButton isActive={isOrdinar}>
            {t('cart.single', 'Ординар')}
          </BetTypeButton>
          <BetTypeButton isActive={isExpress}>
            {t('cart.express', 'Экспресс')}
          </BetTypeButton>
        </div>
        {isEmpty
          ? <TotalCoef coef={totalCoef} />
          : (
            <div className={b('items-list')}>
              {basketList.map((bet) => (
                <BasketItem
                  key={bet.basketId}
                  item={bet}
                  deleteBlock={isPlacing}
                />
              ))}
              <TotalCoef coef={totalCoef} isActive />
            </div>
          )}
        {bonusBalance > 0 ? (
          <CustomCheckbox
            className={b('bonus-wallet')}
            checked={isBonusWallet}
            onChange={handleChangeBonusWallet}
            inverted
          >
            Поставить с бонусного баланса
          </CustomCheckbox>
        ) : null}
        <BasketForm
          totalCoef={totalCoef}
          balance={isBonusWallet ? bonusBalance : balance}
          currency={currency ? currency : defaultCurrency}
          onPlaceBetClick={handlePlaceBet}
          isLoading={isPlacing}
          disabled={isDisabled}
          betLimits={betLimits}
        />
      </div>
    </div>
  );
};

export default Basket;
