import React from 'react';

import NavMenu from '../NavMenu/NavMenu';

import type { ReactElement } from 'react';

export type TabType = {
  title: string;
  id: number;
  disabled?: boolean;
};

type Props = {
  tabs: TabType[];
  activeTabId: number;
  setActiveTabId(id: number): void;
  children: ReactElement<{ id: number }> | ReactElement<{ id: number }>[];
};

const Tabs = ({ tabs, activeTabId, setActiveTabId, children }: Props) => {
  const tabsArray: ReactElement<{ id: number }>[] = children instanceof Array ? children : [children];
  const activeTab = tabsArray.find((tab) => tab.props.id === activeTabId);

  const navMenuItems = tabs.map((tab) => ({
    title: tab.title,
    id: tab.id,
    link: '',
    disabled: tab.disabled,
  }));

  return (
    <div className="tab-item">
      <NavMenu menuItems={navMenuItems} activeItemId={activeTabId} onClick={setActiveTabId} colorScheme="inverse" alignment="center" />
      {activeTab}
    </div>
  );
};

export default Tabs;
