import React from 'react';

import { selectRestoreError } from 'store/auth/selectors';
import { useAppSelector } from 'hooks';
import DetailingModal from 'components/profile/DetailingModal/DetailinModal';
import SettingsModal from 'components/profile/SettingsModal/SettingsModal';
import PromocodeActivateDesktop from 'components/profile/Promocodes/PromocodeActivateDesktop/PromocodeActivateDesktop';
import TopUpsDesktop from 'components/profile/TopUpsDesktop/TopUpsDesktop';
import WithdrawalsDesktop from 'components/profile/WithdrawalsDesktop/WithdrawalsDesktop';
import WalletManageModal from 'components/profile/WalletManage/WalletManageModal/WalletManageModal';
import { selectIsWithdrawalsActive } from 'store/app/selectors';
import TransferModal from 'components/profile/TransferModal/TransferModal';

import ResetPassword from '../ResetPassword/ResetPassword';
import Confirmation from '../Confirmation/Confirmation';
import SignUp from '../SignUp/SignUp';
import SignIn from '../SignIn/SignIn';
import ChangePassword from '../ChangePassword/ChangePassword';
import ConfirmTelegram from '../ConfirmTelegram/ConfirmTelegram';

import type { AuthModalType } from 'types/auth-data';

type Props = {
  activeModal: AuthModalType | null;
  closeModal: () => void;
};

const AuthModalsGroup = ({ activeModal, closeModal }: Props) => {
  const restoreError = useAppSelector(selectRestoreError);
  const isWithdrawalsActive = useAppSelector(selectIsWithdrawalsActive);

  return (
    <>
      <Confirmation
        isOpen={activeModal === 'confirm-email'}
        closeModal={closeModal}
        type="confirmation"
      />
      <Confirmation
        isOpen={activeModal === 'success'}
        closeModal={closeModal}
        type="success"
      />
      <Confirmation
        isOpen={activeModal === 'error'}
        closeModal={closeModal}
        type="error"
      />
      <Confirmation
        isOpen={activeModal === 'reset-success'}
        closeModal={closeModal}
        type="success"
      />
      <Confirmation
        isOpen={activeModal === 'reset-error'}
        closeModal={closeModal}
        type="reset-error"
        error={restoreError}
      />
      <Confirmation
        isOpen={activeModal === 'success-send-reset-link'}
        closeModal={closeModal}
        type="success-send-reset-link"
      />
      <ConfirmTelegram
        isOpen={activeModal === 'confirm-telegram'}
        onClose={closeModal}
        status="success"
      />
      <SignUp
        isOpen={activeModal === 'sign-up'}
        onClose={closeModal}
      />
      <SignIn
        isOpen={activeModal === 'sign-in'}
        onClose={closeModal}
      />
      <ResetPassword
        isOpen={activeModal === 'reset-password'}
        onClose={closeModal}
      />
      <ChangePassword
        isOpen={activeModal === 'change-password'}
        onClose={closeModal}
      />
      <DetailingModal
        isOpen={activeModal === 'detailing'}
        onClose={closeModal}
      />
      <SettingsModal
        isOpen={activeModal === 'settings'}
        onClose={closeModal}
      />
      <PromocodeActivateDesktop
        isOpen={activeModal === 'promocodes'}
        onClose={closeModal}
      />
      <TopUpsDesktop
        isOpen={activeModal === 'topups'}
        onClose={closeModal}
      />
      <WithdrawalsDesktop
        isOpen={activeModal === 'withdrawals-modal' && isWithdrawalsActive}
        onClose={closeModal}
      />
      <WalletManageModal
        isOpen={activeModal === 'wallet-manage'}
        onClose={closeModal}
      />
      <TransferModal
        isOpen={activeModal === 'transfer-to-user'}
        onClose={closeModal}
      />
    </>
  );
};

export default AuthModalsGroup;
