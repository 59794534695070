import React from 'react';
import cn from 'classnames';

import './Discount.scss';

type Props = {
  value: string | number;
  variant?: 'primary';
};

const Discount = ({ value, variant = 'primary' }: Props) => (
  <div className={cn('discount', `discount--${variant}`)}>
    {value}
  </div>
);

export default Discount;
