import React from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { useTranslation } from 'react-i18next';

import i18n from 'i18n/i18n';
import { useAppSelector } from 'hooks';
import { getCurrencySymbol } from 'utils/currency';
import Button from 'components/shared/buttons/Button/Button';
import FormInputWrapper from 'components/shared/form/FormInput';
import ErrorBlock from 'components/shared/inputs/ErrorBlock/ErrorBlock';
import { selectInitError } from 'store/payment/selectors';
import SumSuggestButton from 'components/shared/buttons/SumSuggestButton/SumSuggestButton';
import { useInitTopUpMutation } from 'store/payment/paymentsApi';
import { suggestValues } from 'store/payment/constants';
import { bemCn } from 'utils/bem-cn';
import PaymentMethod from 'components/profile/PaymentSystem/PaymentMethod';

import type { Currency } from 'types/wallets-data';
import type { TTopupMethodsItem } from 'types/payments-data';
import './TopupForm.scss';

const getSuggestions = (currency: Currency): number[] => suggestValues[currency] ?? suggestValues.USD;

type FormFields = {
  amount: number | '';
};

type Props = {
  paymentMethod: TTopupMethodsItem;
};

const schema = (minAmount: number, maxAmount: number) => yup.object({
  amount: yup
    .number()
    .min(minAmount, `${i18n.t('topups.modal.errors.min-amount')} - ${minAmount}`)
    .max(maxAmount, `${i18n.t('topups.modal.errors.max-amount')} - ${maxAmount}`)
    .required(i18n.t('topups.modal.errors.empty-amount') ?? '')
    .typeError(i18n.t('topups.modal.errors.empty-amount') ?? ''),
}).required();

const TopupForm = ({ paymentMethod }: Props) => {
  const { currency, minAmount, maxAmount } = paymentMethod;
  const { t } = useTranslation();
  const [init, { isLoading }] = useInitTopUpMutation();
  const initError = useAppSelector(selectInitError);

  const { handleSubmit, setValue, control } = useForm<FormFields>({
    defaultValues: { amount: '' },
    mode: 'all',
    resolver: yupResolver(schema(minAmount, maxAmount)),
  });

  const handleFormSubmit = async ({ amount }: FormFields) => {
    if (amount) {
      await init({
        amount,
        system: paymentMethod.system,
        method: paymentMethod.name,
      });
    }
  };

  const handleSuggestClick = (amount: number) => {
    if (!isLoading) {
      setValue('amount', amount, { shouldValidate: true, shouldTouch: true });
    }
  };


  const b = bemCn('topup-form');

  return (
    <form className={b()} onSubmit={handleSubmit(handleFormSubmit)}>
      <ErrorBlock
        className={b('error-message')}
        isDisplayed
        message={initError}
        align="center"
      />
      <PaymentMethod
        className={b('method')}
        id={paymentMethod.name}
        title={paymentMethod.title}
        active
      />
      <FormInputWrapper<FormFields>
        name="amount"
        type="money"
        control={control}
        disabled={isLoading}
        placeholder={`${t('topups.modal.amount', 'Сумма')}`}
        currency={currency}
        showError
      />
      <div className={b('input-info')}>
        <p>{t('topups.modal.limit-description', 'Сумма одного депозита')}</p>
        <p>
          {t('from', 'from')} {minAmount} {getCurrencySymbol(currency)} {t('to', 'to')} {maxAmount} {getCurrencySymbol(currency)}
        </p>
      </div>

      <div className={b('suggestions')}>
        {getSuggestions(currency)
          .filter((suggest) => suggest >= minAmount && suggest <= maxAmount)
          .map((suggestAmount) => (
            <SumSuggestButton
              key={suggestAmount}
              onClick={handleSuggestClick}
              amount={suggestAmount}
              currency={currency}
            />
          ))}
      </div>

      <Button className={b('button')}
        isLoading={isLoading}
        type='submit'
      >
        {t('topups.modal.submit-btn', 'Пополнить')}
      </Button>

      {/* <p className={b('additional-methods-info')}>
        {t('topups.modal.choose-method-description', 'Воспользуйтесь запасным способом оплаты, если у Вас не получилось пополнить счет основным способом.')}
      </p> */}
    </form>
  );
};

export default TopupForm;
