import React, { MouseEventHandler } from 'react';
import { useNavigate } from 'react-router-dom';

import FavoriteBtn from 'components/shared/FavoriteBtn/FavoriteBtn';
import { FavoriteTournament } from 'types/user-data';
import { bemCn } from 'utils/bem-cn';
import './FavoriteTournamentCard.scss';
import { useDeleteFavoriteTournamentMutation } from 'store/user/userApi';
import { useResponsive } from 'hooks/useResponsive';
import { useAppDispatch } from 'hooks';
import { setFilterSport, setFilterTournament } from 'store/app/appSlice';

type Props = {
  tournament: FavoriteTournament;
}

const b = bemCn('favorite-tournament-card');
function FavoriteTournamentCard({ tournament }: Props) {
  const dispatch = useAppDispatch();
  const [deleteFavorite] = useDeleteFavoriteTournamentMutation();
  const { isMobile } = useResponsive();
  const navigate = useNavigate();

  const handleFavClick: MouseEventHandler = (e) => {
    e.preventDefault();
	e.stopPropagation();
    deleteFavorite(tournament.id);
  };

  const handleClick = () => {
    dispatch(setFilterSport(tournament.sportId));
    dispatch(setFilterTournament(tournament.tournamentId));
    navigate('/live');
  };

  return (
    <div className={b()} onClick={handleClick}>
      <p>{tournament.sportName}</p>
      <p>{tournament.countryName}</p>
      <p><b>{tournament.tournamentName}</b></p>
      <FavoriteBtn size={isMobile ? 14 : 18} onClick={handleFavClick} className={b('fav-btn')} active />
    </div>
  );
}

export default FavoriteTournamentCard;
