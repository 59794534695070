import { MouseEventHandler } from 'react';
import { useCopyToClipboard } from 'usehooks-ts';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import CoefDisplay from 'components/line/components/CoefDisplay/CoefDisplay';
import SvgIcon from 'components/shared/SvgIcon/SvgIcon';
import { bemCn } from 'utils/bem-cn';
import i18n from 'i18n/i18n';
import { getDateTimeSlashFormat } from 'utils/time';
import { getCurrencySymbol } from 'utils/currency';
import Loader from 'components/shared/loader/Loader';
import { domainName } from 'utils/constants';

import CouponCardBet from './CouponCardBet';

import type { Coupon, PlaceType } from 'types/user-data';
import './CouponCardMobile.scss';

const typeTitles: Record<PlaceType, string> = {
  ordinar: i18n.t('bet-history.single', 'Ординар'),
  express: i18n.t('bet-history.express', 'Экспресс'),
};

export const statusTitles = {
  placed: `${i18n.t('bet-history.possible-prize', 'Возможный выигрыш')}`,
  win: `${i18n.t('bet-history.prize', 'Выигрыш')}`,
  loss: `${i18n.t('bet-history.prize', 'Выигрыш')}`,
  refund: `${i18n.t('bet-history.refund', 'Возврат')}`,
};


type Props = {
  coupon: Coupon;
  className?: string;
}

const CouponCardMobile = ({ coupon, className }: Props) => {
  const {
    id,
    type,
    winAmount,
    created,
    betAmount,
    currency,
    status,
    bets,
    coef,
    expectedWinAmount,
    balanceType,
  } = coupon;

  const { t } = useTranslation();
  const [, copyBarCode] = useCopyToClipboard();
  const isOrdinar = type === 'ordinar';
  const isPlaced = status === 'placed';
  const isRefund = status === 'refund';
  const isBonusWallet = balanceType === 'bonus';

  const rewardAmount = isPlaced
    ? expectedWinAmount
    : isRefund
      ? betAmount
      : winAmount ?? 0;

  const handleCopyClick: MouseEventHandler = (evt) => {
    evt.preventDefault();
    copyBarCode(`${id}`);
  };

  const b = bemCn('coupon-card-mobile');
  return (
    <div className={b({ bonus: isBonusWallet }, className)}>
      <Link className={b('wrapper')} to={`/bet-history/${id}`}>
        <div className={b('header')}>
          <div className={b('header-wrap')}>
            <p className={b('type')}>{typeTitles[type]}</p>
            {coef && (
              <CoefDisplay className={b('ratio')}
                value={coef.toFixed(2)}
                variant="secondary"
              />
            )}
            <button className={b('number')}
              type="button"
              onClick={handleCopyClick}
            >
              №: {id}
              <SvgIcon className={b('number-copy-icon')}
                name='copy'
                width={11} height={11}
              />
            </button>
          </div>
          <div className={b('header-wrap')}>
            <p className={b('date')}>{getDateTimeSlashFormat(created)}</p>
            <p className={b('bet-amount')}>{betAmount} {getCurrencySymbol(currency)}</p>
          </div>
          <p className={b('label')}>{domainName}</p>
        </div>
        <div className={b('bets')}>
          {bets.map((bet) => (
            <CouponCardBet
              key={bet.barcode}
              bet={bet}
              isOrdinar={isOrdinar}
              b={b}
            />
          ))}
        </div>
        <div className={b('reward-info')}>
          <p className={b('reward-amount', { status })}>
            <span>
              {statusTitles[status]}:
            </span>
            <span className={b('reward-amount-value')}>
              {rewardAmount} {getCurrencySymbol(currency)}
            </span>
          </p>
        </div>
        {isBonusWallet ? (
          <p className={b('bonus-wallet-mark')}>
            {t('wallets.bonus-wallet', 'Бонусный счет')}
          </p>
        ) : null}
      </Link>
    </div>
  );
};

export default CouponCardMobile;
