import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { useAppSelector } from 'hooks';
import { seletIsLineActive } from 'store/app/selectors';

import { useFilteredEvent } from './useFilteredEvent';

import type { FullEventItem, Group, LineType, SubGame } from 'types/line-data';


const getMainPollingInterval = (activeTab: number, lineType: LineType | undefined) => {
  if (lineType !== 'live') {
    return 10000;
  }
  if (activeTab === 0) {
    return 2000;
  }
  return 5000;
};

type Props = {
  eventId: string | undefined;
  lineType: LineType | undefined;
}

const useEventFull = ({ eventId, lineType }: Props) => {
  const {i18n } = useTranslation();
  const [event, setEvent] = useState<FullEventItem>();
  const [tabs, setTabs] = useState<SubGame[]>([]);
  const [betsGroups, setBetsGroups] = useState<Group[]>([]);
  const [activeTab, setActiveTab] = useState(0);
  const [isLoading, setLoading] = useState(true);
  const isLineActive = useAppSelector(seletIsLineActive);
  const isMainGameSkip = eventId === undefined || lineType === undefined;
  const isSubgameSkip =
    activeTab === 0 || eventId === undefined || lineType === undefined;

  const mainGame = useFilteredEvent(
    {
      gameId: eventId || '',
      dataType: lineType || 'live',
      dataLang: i18n.resolvedLanguage,
    },
    { skip: isMainGameSkip || !isLineActive, pollingInterval: getMainPollingInterval(activeTab, lineType) }
  );

  const subGame = useFilteredEvent(
    {
      gameId: activeTab,
      dataType: lineType || 'live',
      dataLang: i18n.resolvedLanguage,
    },
    { skip: isSubgameSkip || !isLineActive, pollingInterval: 2000 }
  );

  useEffect(() => {
    if (mainGame.data) {
      setTabs(mainGame.data.subGames ?? []);
      setEvent(mainGame.data);
    }
    if (activeTab !== 0) {
      setBetsGroups(subGame.data?.groups ?? []);
    } else {
      setBetsGroups(mainGame.data?.groups ?? []);
    }
    if (subGame.isError) {
      setActiveTab(0);
    }
    if (!mainGame.isFetching && !subGame.isFetching) {
      setLoading(false);
    }
  }, [subGame.data, mainGame.data, subGame.isError, mainGame.isFetching, subGame.isFetching]);

  // useEffect(() => {
  //   if (mainGame.data) {
  //     setTabs(mainGame.data.subGames ?? []);
  //     setEvent(mainGame.data);
  //   }
  // }, [mainGame.data]);

  // useEffect(() => {
  //   if (subGame.isError) {
  //     setActiveTab(0);
  //   }
  // }, [subGame.isError]);

  // useEffect(() => {
  //   if (!mainGame.isFetching && !subGame.isFetching) {
  //     setLoading(false);
  //   }
  // }, [mainGame.isFetching, subGame.isFetching]);

  const handleTabChange = (newTab: number) => {
    setActiveTab(newTab);
    setLoading(true);
  };

  return {
    event,
    tabs,
    activeTab,
    betsGroups,
    isLoading,
    handleTabChange
  };
};

export { useEventFull };
