import { createSelector } from '@reduxjs/toolkit';

import { NameSpace } from 'utils/constants';
import { Status } from 'types/auth-data';

import type { RootState } from 'types/state';

export const selectAppStatus = (state: RootState) =>
  state[NameSpace.App].status;

export const selectSystemStatus = (state: RootState) =>
  selectAppStatus(state).system;

export const selectIsSystemActive = (state: RootState) =>
  selectSystemStatus(state) === Status.Active;

export const selectLineStatus = (state: RootState) =>
  selectAppStatus(state).line;

export const seletIsLineActive = (state: RootState) =>
  selectLineStatus(state) === Status.Active;

export const selectBetgamesStatus = (state: RootState) =>
  selectAppStatus(state).betgames;

export const seletIsBetgameActive = (state: RootState) =>
  selectBetgamesStatus(state) === Status.Active;

export const selectInbetStatus = (state: RootState) =>
  selectAppStatus(state).inbet;

export const selectIsInbetActive = (state: RootState) =>
  selectInbetStatus(state) === Status.Active;

export const selectVegasStatus = (state: RootState) =>
  selectAppStatus(state).vegas;

export const selectIsVegasActive = (state: RootState) =>
  selectVegasStatus(state) === Status.Active;

export const selectMascotStatus = (state: RootState) =>
  selectAppStatus(state).mascot;

export const selectIsMascotActive = (state: RootState) =>
  selectMascotStatus(state) === Status.Active;

export const selectAviatrixStatus = (state: RootState) =>
  selectAppStatus(state).aviatrix;

export const selectIsAviatrixActive = (state: RootState) =>
  selectAviatrixStatus(state) === Status.Active;

export const selectAgtStatus = (state: RootState) =>
  selectAppStatus(state).agt;

export const selectIsAgtActive = (state: RootState) =>
  selectAgtStatus(state) === Status.Active;

export const selectPragmaticStatus = (state: RootState) =>
  selectAppStatus(state).pragmatic;

export const selectIsPragmaticActive = (state: RootState) =>
  selectPragmaticStatus(state) === Status.Active;

export const selectSmartsoftStatus = (state: RootState) =>
  selectAppStatus(state).smartsoft;

export const selectIsSmartsoftActive = (state: RootState) =>
  selectSmartsoftStatus(state) === Status.Active;

export const selectTggStatus = (state: RootState) =>
  selectAppStatus(state).tgg;

export const seletIsTggActive = (state: RootState) =>
  selectTggStatus(state) === Status.Active;

export const selectTvbetStatus = (state: RootState) =>
  selectAppStatus(state).tvbet;

export const selectIsTvbetActive = (state: RootState) =>
  selectTvbetStatus(state) === Status.Active;

export const selectWithdrawalsStatus = (state: RootState) =>
  selectAppStatus(state).withdrawals;

export const selectIsWithdrawalsActive = (state: RootState) =>
  selectWithdrawalsStatus(state) === Status.Active;

export const selectSystemMessage = (state: RootState) =>
  selectAppStatus(state).systemMessage;

export const selectAppModal = (state: RootState) => state[NameSpace.App].activeModal;

export const selectEventsFilter = (state: RootState) =>
  state[NameSpace.App].eventsFilter;

export const selectFilterSport = createSelector(selectEventsFilter,
  ({ sport }) => sport
);

export const selectFilterCountry = createSelector(selectEventsFilter,
  ({ country }) => country
);

export const selectFilterTournament = createSelector(selectEventsFilter,
  ({ tournament }) => tournament
);
