import React from 'react';
import { useTranslation } from 'react-i18next';

import Button from 'components/shared/buttons/Button/Button';
import { useAppDispatch, useAppSelector } from 'hooks';
import { selectAccountData } from 'store/user/selectors';
import { changeActiveModal } from 'store/auth/authSlice';
import { bemCn } from 'utils/bem-cn';

import UserNavDesktop from './UserNavDesktop/UserNavDesktop';
import UserBalanceDesktop from './UserBalanceDesktop/UserBalanceDesktop';

import './UserblockDesktop.scss';

type Props = {
  isAuthenticated: boolean;
  className?: string;
};

const UserblockDesktop = ({ isAuthenticated, className }: Props) => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const { id: userId } = useAppSelector(selectAccountData);

  const handleTopupClick = () => dispatch(changeActiveModal('topups'));
  const handleLoginClick = () => dispatch(changeActiveModal('sign-in'));
  const handleRegClick = () => dispatch(changeActiveModal('sign-up'));

  const b = bemCn('userblock-desktop');

  if (isAuthenticated) {
    return (
      <div className={b(null, className)}>
        <p className={b('id')}>
          <span className={b('id-title')}>ID:</span>
          <span className={b('id-value')}>{userId}</span>
        </p>
        <UserBalanceDesktop />
        <Button className={b('top-up')} onClick={handleTopupClick} >
          {t('user-nav.topup', 'Пополнить')}
        </Button>
        <UserNavDesktop />
      </div>
    );
  }
  return (
    <div className={b(null, className)}>
      <Button className={b('login-btn')}
        variant="shaded"
        onClick={handleLoginClick}
      >
        {t('user-nav.login', 'Вход')}
      </Button>
      <Button className={b('reg-btn')} onClick={handleRegClick} animated >
        {t('user-nav.registration', 'Регистрация')}
      </Button>
    </div>
  );
};

export default UserblockDesktop;
