import Loader from 'components/shared/loader/Loader';
import { bemCn } from 'utils/bem-cn';

import WalletItem from './WalletItem/WalletItem';

import type { Wallet } from 'types/wallets-data';
import './AdditionalWallets.scss';

type Props = {
  variant?: 'short' | 'primary';
  wallets: Wallet[] | null;
};

const AdditionalWallets = ({ variant = 'primary', wallets }: Props) => {

  if (!wallets) {
    return <Loader />;
  }

  const b = bemCn('additional-wallets');

  return (
    <div className={b()}>
      <div className={b('list')}>
        {wallets.map((wallet) => (
          <WalletItem
            key={wallet.currency}
            wallet={wallet}
            variant={variant}
          />
        ))}
      </div>
      {wallets.length > 0 && <hr className={b('separator')} />}
    </div>
  );
};

export default AdditionalWallets;
