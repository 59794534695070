import React, { useEffect } from 'react';
import { useToggle } from 'usehooks-ts';

import SvgIcon from 'components/shared/SvgIcon/SvgIcon';
import EventCardMobile from 'components/line/EventCard/EventCardMobile/EventCardMobile';
import { bemCn } from 'utils/bem-cn';
import { useGetFavoriteMatchesQuery } from 'store/user/userApi';
import { sortByTopEntries } from 'utils/common';
import { useGetBasketQuery } from 'store/basket/basketApi';
import { selectIsAuthenticated } from 'store/auth/selectors';
import { useAppSelector } from 'hooks';

import type { ITournament } from 'types/line-data';
import './TournamentCardMobile.scss';

type Props = {
  className?: string;
  tournament: ITournament;
  closed: boolean;
  onClose: () => void;
}

const b = bemCn('tournament-card-mobile');
const TournamentCardMobile = (props: Props) => {
  const {
    className,
    closed,
    onClose,
    tournament,
  } = props;
  const { sportName, name, events } = tournament;
  const [isClosed, toggleClosed, setClosed] = useToggle(closed);
  const isAuth = useAppSelector(selectIsAuthenticated);
  const { data: favoriteEvents = [] } = useGetFavoriteMatchesQuery(undefined, { skip: !isAuth });
  const favoriteEventsIds = favoriteEvents.map((event) => event.matchId);
  const { basketByGameId } = useGetBasketQuery(undefined, {
    skip: !isAuth,
    selectFromResult: ({ data }) => ({
      basketByGameId: data?.itemsByGameId
    })
  });
  useEffect(() => {
    if (closed !== isClosed) {
      setClosed(closed);
    }
  }, [closed]);

  const handleClosedToggle = () => {
    toggleClosed();
    onClose();
  };

  const sortedEvents = sortByTopEntries(events, favoriteEventsIds, ({ id }) => id);

  return (
    <div className={b({ 'closed': isClosed }, className)}>
      <button className={b('head')}
        type="button"
        onClick={handleClosedToggle}
      >
        <span className={b('sport')}>{sportName}</span>
        <span className={b('title')}>{name}</span>
        <SvgIcon className={b('close-icon')}
          name="chevron-down"
          width={10} height={10}
        />
      </button>
      {!isClosed && (
        <div className={b('events')}>
          {sortedEvents.map((event) => {
            const favoriteId = favoriteEvents.find(({ matchId }) => matchId === event.id)?.id;
            return (
              <EventCardMobile className={b('event')}
                key={event.id}
                event={event}
                basketItem={basketByGameId?.[event.id]?.basketId}
                isFavorite={favoriteId !== undefined}
                favoriteId={favoriteId ?? 'notFavoried'}
                simple
              />
            );
          })}
        </div>
      )}
    </div>
  );
};

export default React.memo(TournamentCardMobile);
