import React, { useState, useEffect, useMemo } from 'react';

import { useGetUserSettingsQuery, useUpdateUserSettingsMutation } from 'store/user/userApi';

import ThemeContext, { initialThemeState, ThemeContextInterface } from './ThemeContext';

type Props = {
  children: React.ReactNode;
};

const ThemeProvider = ({ children }: Props) => {
  const [theme, setTheme] = useState(initialThemeState.theme);
  const themeValue = useMemo<ThemeContextInterface>(() => ({ theme, setTheme }), [theme, setTheme]);
  const { localStorage } = window;

  const {
    data: userSettings,
  } = useGetUserSettingsQuery();
  const [updateUserSettings] = useUpdateUserSettingsMutation();

  useEffect(() => {
    if (userSettings?.colorScheme && theme !== userSettings?.colorScheme) {
      setTheme(userSettings?.colorScheme);
    }
  }, [userSettings?.colorScheme]);

  // * инициализация первого сохранения темы на сервере
  useEffect(() => {
    if (userSettings && userSettings?.colorScheme === null) {
      updateUserSettings({
        ...userSettings,
        colorScheme: theme,
      });
    }
  }, [userSettings]);

  useEffect(() => {
    localStorage.setItem('globalTheme', theme);
    if (userSettings && userSettings.colorScheme !== theme) {
      updateUserSettings({
        ...userSettings,
        colorScheme: theme,
      });
    }
  }, [theme]);

  return (
    <ThemeContext.Provider value={themeValue}>
      <div className={`theme--${theme}`}>{children}</div>
    </ThemeContext.Provider>
  );
};

export default ThemeProvider;
