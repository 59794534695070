import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { useForm } from 'react-hook-form';
import { useEffect } from 'react';

import i18n from 'i18n/i18n';
import { changeActiveModal } from 'store/auth/authSlice';
import { useAppDispatch, useAppSelector } from 'hooks';
import Button from 'components/shared/buttons/Button/Button';
import FormSelect from 'components/shared/form/FormSelect';
import FormCheckbox from 'components/shared/form/FormCheckbox';
import ErrorBlock from 'components/shared/inputs/ErrorBlock/ErrorBlock';
import { useRegistrationTelegramMutation } from 'store/auth/authApi';
import FormInputWrapper from 'components/shared/form/FormInput';
import { selectRegisterTgError, selectTGVereficationULR } from 'store/auth/selectors';
import { getClickId, getRefCode, getWebId } from 'utils/localStorage';

import AddPromoCode from '../AddPromocode/AddPromoCode';

import type { Currency } from 'types/wallets-data';
import type { RegisterTelegramErrors } from 'types/auth-data';
import type { Entries } from 'types/common';

export type FormFields = {
  name: string;
  password: string;
  currency: string | null;
  checked: boolean;
  telegram_nickname: string;
  promocode: string | undefined;
}

const schema = yup.object({
  'telegram_nickname': yup.string()
    .min(5, `${i18n.t('reg.modal.errors.short-user-name', 'Недостаточно символов')}`)
    .trim()
    .matches(/^[A-Za-z\d_]{5,32}$/, 'Не верный формат')
    .required('Введите никнейм'),
  name: yup.string()
    .min(3, `${i18n.t('reg.modal.errors.short-user-name', 'Недостаточно символов')}`)
    .required(`${i18n.t('reg.modal.errors.empty-user-name', 'Введите ФИО')}`),
  password: yup.string()
    .min(3, `${i18n.t('reg.modal.errors.short-pass', 'Слишком короткий пароль')}`)
    .required(`${i18n.t('reg.modal.errors.empty-pass', 'Введите пароль')}`),
  checked: yup.bool()
    .oneOf([true], `${i18n.t('reg.modal.errors.check-agreement', 'Подтвердите согласие')}`),
  currency: yup.string()
    .required(`${i18n.t('reg.modal.errors.empty-currency', 'Выберите валюту')}`)
    .typeError('Выберите валюту'),
  promocode: yup.string(),
}).required();

const defaultFormValue = {
  name: '',
  password: '',
  currency: null,
  checked: false,
  'telegram_nickname': '',
  promocode: '',
};

type Props = {
  id: number;
  userCurrency: string | null;
  availableCurrencies: Currency[];
  isCurrenciesLoading: boolean;
}

const TelegramTab = ({ id, userCurrency, availableCurrencies, isCurrenciesLoading }: Props) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const [registration, { isLoading, isSuccess }] = useRegistrationTelegramMutation();
  const vereficationUrl = useAppSelector(selectTGVereficationULR);
  const registerErrors = useAppSelector(selectRegisterTgError);

  const {
    handleSubmit,
    setError,
    formState: { touchedFields, errors },
    control
  } = useForm<FormFields>({
    defaultValues: { ...defaultFormValue, currency: userCurrency },
    mode: 'all',
    resolver: yupResolver(schema),
  });

  useEffect(() => {
    if (vereficationUrl && isSuccess) {
      window.open(vereficationUrl, '_blank');
      dispatch(changeActiveModal('confirm-telegram'));
    }
  }, [vereficationUrl]);

  useEffect(() => {
    const fieldsErrors: Partial<RegisterTelegramErrors> = { ...registerErrors };
    delete fieldsErrors.common;

    const formEntries = Object.entries(fieldsErrors) as Entries<Omit<RegisterTelegramErrors, 'common'>>;

    formEntries.forEach(([key, value]) => {
      if (!value) { return; }

      setError(key, {
        type: 'server',
        message: value,
      });
    });
  }, [registerErrors]);

  const onSubmit = handleSubmit((formData: FormFields) => {
    const { name, password, currency, checked, promocode } = formData;

    if (currency && checked) {
      registration({
        name,
        password,
        currency,
        'telegram_nickname': formData.telegram_nickname,
        'ref_code': getRefCode(),
        'click_id': getClickId(),
        'web_id': getWebId(),
        promocode: promocode ? promocode : undefined,
      });
      // console.log({
      //   name,
      //   password,
      //   currency,
      //   'telegram_nickname': formData.telegram_nickname,
      //   'ref_code': getRefCode(),
      //   'click_id': getClickId(),
      //   'web_id': getWebId(),
      //   promocode: promocode ? promocode : undefined,
      // });
    }

  });

  return (
    <form className="registration-modal__form" key={id} onSubmit={onSubmit}>
      <ErrorBlock isDisplayed={!!registerErrors.common} message={registerErrors.common} />
      <FormSelect<FormFields, Currency>
        name="currency"
        type="currency"
        control={control}
        disabled={isLoading}
        options={availableCurrencies}
        isLoading={isCurrenciesLoading}
        showError
      />
      <FormInputWrapper<FormFields>
        name="telegram_nickname"
        type="text"
        control={control}
        disabled={isLoading}
        placeholder={t('reg.modal.telegram-nickname') ?? ''}
        showError
      />
      <FormInputWrapper<FormFields>
        name="name"
        type="text"
        control={control}
        disabled={isLoading}
        placeholder={`${t('reg.modal.user-name', 'ФИО')}`}
        showError
      />
      <FormInputWrapper<FormFields>
        name="password"
        type="password"
        control={control}
        disabled={isLoading}
        autoComplete="new-password"
        placeholder={`${t('reg.modal.password', 'Пароль')}`}
        showError
      />
      <AddPromoCode<FormFields>
        name="promocode"
        control={control}
        disabled={isLoading}
      />
      <FormCheckbox<FormFields>
        className="registration-modal__accept-rules"
        name="checked"
        control={control}
        disabled={isLoading}
        showError
      >
        {t('reg.modal.agreement')}{' '}
        <Link to="/privacy" className="registration-modal__privacy-policy-link">
          {t('reg.modal.agreement-privacy')}
        </Link>
      </FormCheckbox>
      <Button className='registration-modal__btn'
        type='submit'
        isLoading={isLoading}
      >
        {t('reg.modal.submit-btn', 'Зарегистрироваться')}
      </Button>
      <Button className='registration-modal__btn'
        onClick={() => dispatch(changeActiveModal('sign-in'))}
        variant="outline-primary-inverted"
      >
        {t('reg.modal.sign-in', 'Войти')}
      </Button>
    </form>
  );
};

export default TelegramTab;
