import { ChangeEventHandler, useEffect, useRef, useState } from 'react';
import { useController, UseControllerProps, FieldValues } from 'react-hook-form';
import { useOnClickOutside } from 'usehooks-ts';

import { bemCn } from 'utils/bem-cn';
import Flag from 'components/shared/Flag/Flag';
import { defaultRuPhoneCode, phoneCountryCodes } from 'utils/constants';
import { useAppSelector } from 'hooks';
import { selectUserGeo } from 'store/user/selectors';

import ErrorBlock from '../ErrorBlock/ErrorBlock';
import './PhoneSelector.scss';


const getUserPhoneCode = (
  userGet: {
    country: string | null;
    countryPhoneCode: string | null;
  }
): Option => {
  const compatibleOptions = phoneCountryCodes
    .filter((item) => item.country === userGet.country);

  if (compatibleOptions.length === 1) {
    return compatibleOptions[0];
  }

  const same = compatibleOptions.find((item) =>
    item.country === userGet.country
    && item.code === userGet.countryPhoneCode
  );

  return same ?? compatibleOptions[0] ?? defaultRuPhoneCode;
};

type Option = {
  code: string;
  country: string;
}

const availableInput = /^\d{0,20}$/;

type Props<TFormValues extends FieldValues, T> = {
  className?: string;
  disabled?: boolean;
  placeholder?: string;
  variant?: 'inverted';
} & UseControllerProps<TFormValues>

const PhoneSelector = <TFormValues extends Record<string, unknown>, T>(props: Props<TFormValues, T>) => {
  const {
    className,
    disabled,
    placeholder,
    variant,
  } = props;

  const userGeo = useAppSelector(selectUserGeo);
  const { field, fieldState } = useController(props);
  const [selectedOption, setSelectedOption] = useState<Option>(getUserPhoneCode(userGeo));
  const [isOpened, setOpened] = useState(false);
  const [inputValue, setInputValue] = useState<string>('');
  const selectorRef = useRef<HTMLDivElement>(null);
  const selectedOptionRef = useRef<HTMLLIElement>(null);
  const optionsListRef = useRef<HTMLUListElement>(null);


  useEffect(() => {
    setSelectedOption(getUserPhoneCode(userGeo));
  }, [userGeo.country, userGeo.countryPhoneCode]);

  useEffect(() => {
    const value = field.value as string;
    const valueInput = value.replace(selectedOption.code, '');
    if (valueInput !== inputValue) {
      setInputValue(valueInput);
    }
  }, [field.value]);

  useEffect(() => {
    field.onChange(selectedOption.code + inputValue);
  }, [selectedOption.code]);

  useOnClickOutside(selectorRef, () => {
    if (isOpened) {
      setOpened(false);
    }
  });

  useEffect(() => {
    if (isOpened && optionsListRef.current && selectedOptionRef.current) {
      const topOffset = selectedOptionRef.current?.offsetTop;
      optionsListRef.current.scrollTop = topOffset;
    }
  }, [isOpened]);

  const handleOptionClick = (option: Option) => {
    setSelectedOption(option);
    setOpened(false);
  };

  const handleSelectedClick = () => {
    setOpened(!isOpened);
  };

  const handleInput: ChangeEventHandler<HTMLInputElement> = (evt) => {
    if (availableInput.test(evt.target.value)) {
      field.onChange(selectedOption.code + evt.target.value);
    }
  };


  const invalid = !!fieldState.error;
  const valid = !fieldState.error;
  const isTouched = fieldState.isTouched;

  const b = bemCn('phone-selector');
  return (
    <div className={b({
      invalid: isTouched && invalid,
      valid: isTouched && valid,
      inverted: variant === 'inverted'
    }, className)}
    >
      <ErrorBlock isDisplayed={invalid && isTouched} message={fieldState.error?.message} />
      <div className={b('wrapper')}>
        <div className={b('selector-wrapper')} ref={selectorRef}>
          <button className={b('selected-option')} type='button' onClick={handleSelectedClick} disabled={disabled}>
            <Flag className={b('country-icon')} countryCode={selectedOption.country} />
            {selectedOption.code}
          </button>

          <ul className={b('optios-list', { opened: isOpened })} ref={optionsListRef}>
            {phoneCountryCodes.map((option) => {
              const isSelected = selectedOption.code === option.code && selectedOption.country === option.country;
              return (
                <li className={b('option', { selected: isSelected })}
                  key={`${option.country}-${option.code}`}
                  ref={isSelected ? selectedOptionRef : null}
                  onClick={() => handleOptionClick(option)}
                >
                  <Flag className={b('country-icon')} countryCode={option.country} />
                  {option.code}
                </li>
              );
            }
            )}
          </ul>

        </div>
        <div className={b('input-wrapper')}>
          <input className={b('input')}
            type="tel"
            placeholder={placeholder ?? '9999999999'}
            value={inputValue}
            onChange={handleInput}
            onBlur={field.onBlur}
            disabled={disabled}
          />
        </div>
      </div>

    </div>
  );
};

export default PhoneSelector;
