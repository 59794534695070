
import { getCurrencySymbol, getCurrencyTitle } from 'utils/currency';
import AddButton from 'components/shared/buttons/AddButton/AddButton';
import { bemCn } from 'utils/bem-cn';

import type { Wallet } from 'types/wallets-data';
import './WalletItem.scss';

type Props = {
  wallet: Wallet;
  variant?: 'short' | 'primary';
}

const WalletItem = ({ wallet, variant = 'primary' }: Props) => {
  const b = bemCn('wallet-item');
  // TODO: Была сделана какая то чушь. два варианта, при том что стили только для short??
  if (variant === 'short') {
    return (
      <div className={b({ 'short': true })}>
        <div className={b('cell', { 'left': true })}>
          <div className={b('currency-icon')}>
            <p>{getCurrencySymbol(wallet.currency)}</p>
          </div>
          <p className={b('title')}>
            {getCurrencyTitle(wallet.currency)}
          </p>
        </div>
        <div className={b('cell', { 'right': true })}>
          <div className={b('balance')}>{wallet.amount}</div>
          <AddButton onClick={() => undefined} />
        </div>
      </div>
    );
  }
  return (
    <div className={b()}>
      <div className={b('currency-icon')}>{wallet.currency}</div>
      <p className={b('title')}>{wallet.currency}</p>
      <p className={b('balance')}>{wallet.amount}</p>
      <div className={b('top-up')}>+</div>
    </div>
  );
};

export default WalletItem;
