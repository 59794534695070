import GamesCardsWidgetMobile from 'components/shared/GamesCardsWidget/GamesCardsWidgetMobile/GamesCardsWidgetMobile';
import { useAppSelector } from 'hooks';
import { seletIsBetgameActive } from 'store/app/selectors';
import { crashGames } from 'utils/constants';
import Adaptive from 'components/Layout/Adaptive/Adaptive';
import GamesCardsWidgetDesktop from 'components/shared/GamesCardsWidget/GamesCardsWidgetDesktop/GamesCardsWidgetDesktop';


const CrashgamesWidget = () => {
  const isBetgameActive = useAppSelector(seletIsBetgameActive);

  if (!isBetgameActive) {
    return null;
  }

  const link = '/games?filter=Crash+Games';

  return (
    <Adaptive
      mobile={(
        <GamesCardsWidgetMobile
          title="Crash Games"
          iconColor="dark-blue"
          iconName='star'
          items={crashGames}
          linkToAll={link}
          isAvailable={isBetgameActive}
        />
      )}
      desktop={(
        <GamesCardsWidgetDesktop
          title="Crash Games"
          items={crashGames}
          linkToAll={link}
          isAvailable={isBetgameActive}
        />
      )}
    />
  );
};

export default CrashgamesWidget;
