import { useState, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';

import i18n from 'i18n/i18n';
import { useActivatePromocodeMutation } from 'store/user/userApi';
import { BackendError } from 'store/auth/utils';

import type { FetchBaseQueryError } from '@reduxjs/toolkit/dist/query';
import type { SerializedError } from '@reduxjs/toolkit';

export type FormFields = {
  promocode: string;
};

export const schema = yup.object({
  promocode: yup.string()
    .required(`${i18n.t('promocodes.enter-promocode', 'Введите промокод')}`),
}).required();

export const handleResponseError = (error?: FetchBaseQueryError | SerializedError) => {
  if (!!error && 'status' in error && error.data) {
    const { detail } = error.data as BackendError;

    if (typeof detail === 'string') {
      switch (detail) {
        case 'Invalid promocode':
        case 'Promocode not linked with any script':
          return `${i18n.t('result-modal.promocode.error.not-found', 'Неверный промокод')}`;
        case 'Promocode already used':
          return `${i18n.t('result-modal.promocode.error.already-used', 'Промокод уже использовался')}`;
        case 'Promocode expired':
          return `${i18n.t('result-modal.promocode.error.expired', 'Действие промокода истекло')}`;
        case 'User not allowed to use this promocode':
          return `${i18n.t('result-modal.promocode.error.wrong-user', 'Промкод принадлежит другому пользователю')}`;
        default:
          return detail;
      }
    }

    return detail[0].msg;
  }

  return error && `${i18n.t('reg.modal.errors.smth-wrong', 'Что-то пошло не так!')}`;
};

export function usePromocode() {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [activatePromocode, {
    isLoading: isActivatePromocodeLoading,
    isSuccess: isActivatePromocodeSuccess,
    error: activatePromocodeError,
  }] = useActivatePromocodeMutation();

  const { handleSubmit, setError, formState, control, reset } = useForm<FormFields>({
    defaultValues: { promocode: '' },
    mode: 'all',
    reValidateMode: 'onChange',
    resolver: yupResolver(schema),
  });

  const onSubmit = handleSubmit(async (formData: FormFields) => {
    await activatePromocode(formData.promocode);
  });


  const handleModalClose = () => {
    setIsModalOpen(false);
  };

  useEffect(() => {
    if (isActivatePromocodeSuccess) {
      setIsModalOpen(true);
      reset();
    } else {
      const responseErrorMessage = handleResponseError(activatePromocodeError);

      setError('promocode', {
        type: 'server',
        message: responseErrorMessage,
      });
    }
  }, [isActivatePromocodeSuccess, activatePromocodeError]);

  return {
    handleSubmit,
    setError,
    control,
    reset,
    isActivatePromocodeSuccess,
    isActivatePromocodeLoading,
    activatePromocodeError,
    onSubmit,
    isModalOpen,
    setIsModalOpen,
    handleModalClose,
  };
}
