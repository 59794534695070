import React from 'react';
import cn from 'classnames';

import iconsSprite from 'assets/icons/sprite.svg';

export type AvailableIcon =
  string
  | 'allert'
  | 'android'
  | 'apple'
  | 'arrow-right'
  | 'badminton'
  | 'baseball'
  | 'basketball'
  | 'boxing'
  | 'calendar'
  | 'chess'
  | 'check'
  | 'chevron-down'
  | 'chevron-right'
  | 'clock'
  | 'close'
  | 'coin-flat'
  | 'coin'
  | 'collapse-all'
  | 'copy'
  | 'coupon'
  | 'cricket'
  | 'cup'
  | 'desktop'
  | 'dota-2'
  | 'dots-menu'
  | 'edit'
  | 'exit'
  | 'fire'
  | 'football'
  | 'futsal'
  | 'games'
  | 'gmail'
  | 'handball'
  | 'hide'
  | 'hockey'
  | 'home'
  | 'instagram'
  | 'lock'
  | 'live'
  | 'mailru'
  | 'mma'
  | 'odnoklassniki'
  | 'phone'
  | 'pie-chart'
  | 'plus'
  | 'pp-stats'
  | 'print'
  | 'rugby'
  | 'search'
  | 'settings'
  | 'show'
  | 'star'
  | 'stats'
  | 'steam'
  | 'support'
  | 'support-tg'
  | 'switch'
  | 'table-tennis'
  | 'telegram'
  | 'tennis'
  | 'time-machine'
  | 'trash'
  | 'vkontakte'
  | 'volleyball'
  | 'warning'
  | 'yandex'
  | 'new'
  | 'new-star';


type Props = {
  name: AvailableIcon;
  fill?: string;
  width?: number;
  height?: number;
  className?: string;
}

const SvgIcon = (props: Props) => {
  const {
    name,
    fill = 'currentColor',
    width = 32,
    height = 32,
    className
  } = props;

  return (
    <svg className={cn('svg-icon', className)} fill={fill} width={width} height={height}>
      <use xlinkHref={`${iconsSprite}#icon-${name}`}></use>
    </svg>
  );
};

export default React.memo(SvgIcon);
