import { ClassNameFormatter } from '@bem-react/classname';

// import logoBookmakerRatings from '../assets/logo-bookmaker-ratings.svg';
import logoCasinoAnalyzer from '../assets/logo-casinos-analyzer.svg';

type Props = {
  b: ClassNameFormatter;
}

const PageFooterRatingSites = ({ b }: Props) => (
  <ul className={b('rating-sites')}>
    <li className={b('rating-sites-item')}>
      <img className={b('rating-sites-image')} src={logoCasinoAnalyzer} width="60" alt="Casino Analyzer" />
    </li>
    {/* <li className={b('rating-sites-item')}>
      <img className={b('rating-sites-image')} src={logoBookmakerRatings} width="75" height="13" alt="Рейтонг Букмекеров" />
    </li>
    <li className={b('rating-sites-item', { platilka: true })}>
      <a href="https://platilka.com/" target="_blank" rel="noreferrer">
        <img className={b('rating-sites-image')} src="https://platilka.com/static/img/platilka6.svg" height="31px" alt="platilka.com" />
      </a>
    </li> */}
  </ul>
);

export default PageFooterRatingSites;
