import { useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react';

import { bemCn } from 'utils/bem-cn';
import { availableLang } from 'utils/constants';
import { useGetUserSettingsQuery, useUpdateUserSettingsMutation } from 'store/user/userApi';
// import SvgIcon from 'components/shared/SvgIcon/SvgIcon';

import SiteSettingsLang from './SiteSettingsLang/SiteSettingsLang';

import './SiteSettings.scss';

type Props = {
  className?: string;
  below?: boolean;
}

const SiteSettings = (props: Props) => {
  const { i18n } = useTranslation();
  const [currentLang, setCurrentLang] = useState('ru');
  const { data: userSettings } = useGetUserSettingsQuery();
  const [updateUserSettings] = useUpdateUserSettingsMutation();

  useEffect(() => {
    if (!!i18n.resolvedLanguage && availableLang.includes(i18n.resolvedLanguage) && i18n.resolvedLanguage !== currentLang) {
      document.documentElement.lang = i18n.resolvedLanguage;
      setCurrentLang(i18n.resolvedLanguage);
    }
  }, [i18n.resolvedLanguage]);

  useEffect(() => {
    if (!!userSettings && !!i18n.resolvedLanguage && userSettings?.lang === null) {
      updateUserSettings({
        ...userSettings,
        lang: currentLang,
      });
    }
  }, [userSettings?.lang]);

  const handleLangChange = (lang: string) => {
    if (lang !== i18n.resolvedLanguage) {
      i18n.changeLanguage(lang);
      if (userSettings) {
        updateUserSettings({
          ...userSettings,
          lang,
        });
      }
    }
  };

  const b = bemCn('site-settings');
  return (
    <div className={b(null, props.className)}>
      {/* <button className={b('button', { mobile: true })} type="button" disabled>
        <span className="visually-hidden">Переключить версию сайта</span>
        <SvgIcon name='mobile' className={b('button-icon')} />
      </button>
      <button className={b('button', { desktop: true })} type="button" disabled>
        <span className="visually-hidden">Переключить версию сайта</span>
        <SvgIcon name='desktop' className={b('button-icon')} />
      </button> */}
      <SiteSettingsLang
        activeLang={currentLang}
        langList={availableLang}
        below={props.below}
        onLangChange={handleLangChange}
        b={b}
      />
    </div>
  );
};

export default SiteSettings;
