import GamesCardsWidgetMobile from 'components/shared/GamesCardsWidget/GamesCardsWidgetMobile/GamesCardsWidgetMobile';
import { useAppSelector } from 'hooks';
import { selectIsInbetActive } from 'store/app/selectors';
import { useGetInbetGamesQuery } from 'store/slots/slotsApi';
import Adaptive from 'components/Layout/Adaptive/Adaptive';
import GamesCardsWidgetDesktop from 'components/shared/GamesCardsWidget/GamesCardsWidgetDesktop/GamesCardsWidgetDesktop';

const InbetGamesWidget = () => {
  const isInbetActive = useAppSelector(selectIsInbetActive);
  const inbetGames = useGetInbetGamesQuery({ gameType: 'slot' }, { skip: !isInbetActive });

  if (!isInbetActive) {
    return null;
  }

  const link = '/games?filter=Inbet+Games+Slots';
  const gamesList = inbetGames.data?.slice(0, 20) ?? [];

  return (
    <Adaptive
      mobile={(
        <GamesCardsWidgetMobile
          title="Inbet Games"
          iconColor="pink"
          iconName='coin-flat'
          itemsCounter={inbetGames.data?.length}
          items={gamesList}
          linkToAll={link}
          isLoading={inbetGames.isLoading || !inbetGames.isSuccess}
          isAvailable={isInbetActive}
        />
      )}
      desktop={(
        <GamesCardsWidgetDesktop
          title="Inbet Games"
          items={gamesList}
          linkToAll={link}
          isAvailable={isInbetActive}
        />
      )}
    />
  );
};

export default InbetGamesWidget;
