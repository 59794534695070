import React from 'react';
import cn from 'classnames';

import SvgIcon from '../SvgIcon/SvgIcon';

import './Loader.scss';

type Props = {
  wrapperClassName?: string;
  className?: string;
};

const Loader = ({ className, wrapperClassName }: Props) => (
  <div className={cn('loader__wrapper', wrapperClassName)}>
    <SvgIcon className={cn('loader', className)} width={18} height={18} name='football' />
  </div>
);

export default Loader;
