import React, { Dispatch, SetStateAction } from 'react';

export interface ThemeContextInterface {
  theme: string;
  setTheme: Dispatch<SetStateAction<string>>;
}

export const AVAILABLE_THEMES = [
  // 'dark',
  // 'light',
  'light-blue',
  // 'dark-blue',
  // 'yellow'
];
const DEFAULT_THEME = 'light-blue';

const getInitialTheme = () => {
  const savedThemeLocal = localStorage.getItem('globalTheme');
  if (savedThemeLocal && AVAILABLE_THEMES.includes(savedThemeLocal)) {
    return savedThemeLocal;
  }

  localStorage.setItem('globalTheme', DEFAULT_THEME);
  return DEFAULT_THEME;
};

export const initialThemeState = {
  theme: getInitialTheme(),
  setTheme: () => null,
};

const ThemeContext = React.createContext<ThemeContextInterface>(initialThemeState);
export default ThemeContext;
