import React from 'react';
import { useTranslation } from 'react-i18next';

import Breadcrumbs from 'components/shared/Breadcrumbs/Breadcrumbs';
import { useAppSelector } from 'hooks';
import { selectPrimaryWallet } from 'store/user/selectors';
import { useGetWalletsQuery } from 'store/user/userApi';

import AddWalletForm from './components/AddWalletForm/AddWalletForm';
import AddedWalletItem from './components/AddedWalletItem/AddedWalletItem';
import './WalletManage.scss';
import MainWalletInfoMobile from '../Profile/MainWalletInfoMobile/MainWalletInfoMobile';

const WalletManage = () => {
  const { t } = useTranslation();
  const { data: allWallets, isLoading: isWalletsLoading } = useGetWalletsQuery();
  const primaryWallet = useAppSelector(selectPrimaryWallet);

  const notPrimaryWallet = allWallets?.filter((item) => !item.isPrimary);

  return (
    <>
      <Breadcrumbs link="/profile" title={t('main.nav.Profile', 'Личный кабинет')} />
      <div className='wallet-manage'>
        <div className='profile__section'>
          <div className='profile__account-info'>
            <MainWalletInfoMobile
              wallet={primaryWallet}
              isLoading={isWalletsLoading}
            />
          </div>
        </div>
        <hr className='profile__separator' />
        <div className='wallet-manage__added'>
          {notPrimaryWallet?.map((item) => (
            <AddedWalletItem key={item.currency} wallet={item} />
          ))}
        </div>
        <div className='profile__section'>
          <AddWalletForm />
        </div>
      </div>
    </>
  );
};

export default WalletManage;
