import React from 'react';

import Breadcrumbs from 'components/shared/Breadcrumbs/Breadcrumbs';
import TextContent from 'components/shared/TextContent/TextContent';
import { bemCn } from 'utils/bem-cn';

import StaticPages from '../StaticPages';

import './SupportPage.scss';

const b = bemCn('support-page');

const SupportPage = () => (
  <div className={b()}>
    <Breadcrumbs title="Личный кабинет" link="/profile" />
    <TextContent className={b('content')}>
      <h1>Если у вас возникли вопросы, Вы всегда можете обратиться в нашу службу поддержки.</h1>
      <p>
        Наша почта: <b><a href="mailto:support@rysebet.com">support@rysebet.com</a></b>
      </p>
      <p>
        Наш телеграм: <b><a href="https://t.me/rysebett" target="_bkank">https://t.me/rysebett</a></b>
      </p>
      <p >
        Аккаунт оператора, который доступен 24/7: <b><a href="https://t.me/rysebet_operator" target="_blank" rel="noreferrer">https://t.me/rysebet_operator</a></b>
      </p>
    </TextContent>
  </div>
);

export default SupportPage;
