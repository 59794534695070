import React from 'react';
import { useLocation } from 'react-router-dom';

import i18n from 'i18n/i18n';
import NavMenu from 'components/shared/NavMenu/NavMenu';
import { routes } from 'utils/routes';
import { ReactComponent as Jetxlogo } from 'assets/jetx-logo.svg';
import Aviatrixlogo from 'assets/aviatrix-logo-white.png';
import './MainNav.scss';
import { bemCn } from 'utils/bem-cn';

const b = bemCn('main-nav');

const menuItems = [
  {
    link: '/home',
    title: i18n.t('main.nav.Home', 'Главная'),
    id: 1,
  },
  {
    link: routes.live.path,
    title: i18n.t('main.nav.Live', routes.live.title),
    id: 2,
  },
  {
    link: routes.line.path,
    title: i18n.t('main.nav.Line', routes.line.title),
    id: 3,
  },
  {
    link: '/games',
    title: i18n.t('main.nav.Casino', 'Казино'),
    id: 4,
  },
  // {
  //   link: routes.slots.path,
  //   title: routes.slots.title,
  //   id: 4,
  // },
  // {
  //   link: '/results',
  //   title: 'Результаты',
  //   id: 5,
  // },
];

const getMenuItems = () => [
  {
    link: '/home',
    title: i18n.t('main.nav.Home', 'Главная'),
    id: 1,
  },
  {
    link: routes.live.path,
    title: i18n.t('main.nav.Live', routes.live.title),
    id: 2,
  },
  {
    link: routes.line.path,
    title: i18n.t('main.nav.Line', routes.line.title),
    id: 3,
  },
  {
    link: '/games',
    title: i18n.t('main.nav.Casino', 'Казино'),
    id: 4,
  },
  // {
  //   link: routes.slots.path,
  //   title: routes.slots.title,
  //   id: 4,
  // },
  // {
  //   link: '/results',
  //   title: 'Результаты',
  //   id: 5,
  // },
  {
    link: '/games/smartsoft/JetX/JetX',
    // title: <Jetxlogo className={b('icon')} fill='currentColor' />,
    title: <Jetxlogo className={b('icon')} fill='white' />,
    id: 6,
  },
  {
    link: '/games/aviatrix',
    title: <img src={Aviatrixlogo} alt="" className={b('aviatrix-icon')} />,
    id: 7,
  },
];

const MainNav = () => {
  const location = useLocation();
  const items = getMenuItems();
  const activeMenuItem = items.find((item) => item.link === location.pathname);

  return (
    <div className={b()}>
      {/* <div className="main-nav__mask" /> */}
      <NavMenu
        className={b('nav-menu')}
        menuItems={items}
        activeItemId={activeMenuItem ? activeMenuItem.id : 0}
      />
    </div>
  );
};

export default MainNav;
