import { useTranslation } from 'react-i18next';

import BannerMessages from 'components/bannerMessages/BannerMessages';
import TopEventsWidgetMobile from 'components/line/TopEventsWidget/TopEventsWidgetMobile/TopEventsWidgetMobile';
import { useFilteredEventsList } from 'hooks/useFilteredEventsList';
import { useAppSelector } from 'hooks';
import {
  seletIsLineActive,
} from 'store/app/selectors';
import GamesWidgets from 'components/casino/GamesWidgets/GamesWidgets';

import MainNav from '../components/MainNav/MainNav';
import BannerSlider from '../components/BannerSlider/BannerSlider';


const HomeMobile = () => {
  const { t, i18n } = useTranslation();

  const isLineActive = useAppSelector(seletIsLineActive);

  const liveEvents = useFilteredEventsList(
    { dataType: 'live', pageLength: 99, dataLang: i18n.resolvedLanguage },
    { skip: !isLineActive }
  );
  const lineEvents = useFilteredEventsList(
    { dataType: 'line', pageLength: 99, dataLang: i18n.resolvedLanguage },
    { skip: !isLineActive }
  );

  return (
    <main className="home-page">
      <MainNav />
      <BannerSlider />
      {/* <BannerMessages /> */}
      <TopEventsWidgetMobile
        title={t('top-events-widget.top-live', 'Топ live матч')}
        iconName='live'
        iconColor='purple'
        allLink="/live"
        isLoading={liveEvents.isLoading || !liveEvents.isSuccess}
        events={liveEvents.data || []}
        isAvailable={isLineActive}
        notAvailableMessage={`${t('maintenance.eventsNotAvailable', 'Спортивные события временно недоступны')}`}
      />
      <TopEventsWidgetMobile
        title={t('top-events-widget.top-line', 'Топ линия матч')}
        iconName='fire'
        iconColor='orange'
        allLink="/line"
        isLoading={lineEvents.isLoading || !lineEvents.isSuccess}
        events={lineEvents.data || []}
        isAvailable={isLineActive}
        notAvailableMessage={`${t('maintenance.eventsNotAvailable', 'Спортивные события временно недоступны')}`}
      />

      <GamesWidgets />

    </main>
  );
};

export default HomeMobile;
